import styled from "styled-components";
import { SocialType } from "./getIconType";
import FbIcon from '../../assets/images/fb-icon.png';
import InstaIcon from '../../assets/images/insta-icon.png';
import DefaultIcon from '../../assets/images/default-social-icon.svg';
import AddIcon from '../../assets/images/profile-nav/add-icon.svg'


export const List = styled.div`
    display:flex;
    gap:6px;
`;

export const Item = styled.a<{
    $type: SocialType
    $active?: boolean
}>`
    display: flex;
    flex-shrink:0;
    width: 20px;
    height: 20px;
    border-radius: 6px;
    background-image: url(${({$type}) => {
        switch($type){
            case 'facebook':
                return FbIcon;
            case 'instagram':
                return InstaIcon;
            default:
                return DefaultIcon;
        }
    }});
    background-repeat: no-repeat;
    background-size:100% 100%;
    border:1px solid ${({$active}) => $active ? '#FFC221' : 'transparent'};
`;

export const ItemEdit = styled.div`
    display: flex;
    flex-shrink:0;
    width: 20px;
    height: 20px;
    border-radius: 6px;
    border:1px solid #3A3A3A;
    background:url(${AddIcon}) no-repeat center;
    background-size:50%;
    background-color:#202020;
    cursor:pointer;
`;

export const Close = styled.div`
    cursor:pointer;
    position:relative;
    display: flex;
    flex-shrink:0;
    width: 100%;
    height: 100%;
`;

export const CloseIcon = styled.div`
    cursor:pointer;
    position:absolute;
    top: -5px;
    right: -5px;
    display: flex;
    justify-content:center;
    align-items:center;
    content: "x";
    font-family: Main Bold;
    color: #fff;
    font-size:6px;
    flex-shrink:0;
    width: 10px;
    height: 10px;
    border-radius: 2px;
    background-color:#FF4747;
`;

export const ModalContainer = styled.div`
    width:100%;
    display:flex;
    flex-direction:column;
    gap:15px;
    align-items:flex-end;
    &> button{
        width:fit-content;
    }
`;
import { FC, useState, MouseEvent } from "react"
import { Close, CloseIcon, Item, ItemEdit, List, ModalContainer } from "./SocialList.styles"
import { getIconType } from "./getIconType"
import { Show } from "../Show/Show"
import PopupWrapper, { StyledPopupHeader } from "../../Views/Admin/Popup.wrapper"
import { Input } from "../../Pages/Profile/components/input/input"
import { Button } from "../Button/Button.component"
import { FormikProps, FormikValues } from "formik"

export type SocialListProps = {
    list: string[],
    reviewList: string[],
    editMode?: boolean,
    formik: FormikProps<FormikValues>;
}

export const SocialList:FC<SocialListProps> = ({list, reviewList, editMode, formik}) => {
    const mergedList = reviewList.length > 0 ? reviewList : list; 
    const [ socialLink, setSocialLink ] = useState("");
    const [ openLink, setOpenLink ] = useState(false);

    const modalClose = () => {
        setOpenLink(false);
        setSocialLink("");
    }

    const modalOpen = () => setOpenLink(true);

    const deleteLink = (index: number) => {
        const newLinks = formik.values.social_links.filter((_:string, i:number) => i !== index);
        formik.setFieldValue("social_links", newLinks);
    }

    const addLink = (e:MouseEvent) => {
        e.preventDefault();
        formik.setFieldValue('social_links', [...(formik?.values?.social_links || []), socialLink])
        modalClose();
    }
    
    return(
        <List>
            {
                mergedList.map((url, index) => {
                    const isActive = reviewList?.includes(url);
                    return (
                        <Item
                            key={index}
                            $type={getIconType(url)}
                            $active={isActive}
                            href={url}
                            onClick={(e) => {if(editMode) e.preventDefault()}}
                            rel="noreferrer"
                            target="_blank"
                        >
                             <Show condition={!!editMode}>
                                <Close onClick={deleteLink.bind(this,index)}>
                                    <CloseIcon>x</CloseIcon>
                                </Close>
                            </Show>
                        </Item>
                    )
                })
            }
            <Show condition={!!editMode}>
                <ItemEdit onClick={modalOpen}/>
            </Show>
            <PopupWrapper isopen={openLink}>
                <StyledPopupHeader>
                    <div className="popup-title">Add Social Link</div>
                    <div className="close" onClick={modalClose}></div>
                </StyledPopupHeader>
                <ModalContainer>
                    <Input
                        label="Link"
                        name="socialLink"
                        value={socialLink}
                        onChange={(e) => setSocialLink(e.target.value)}
                    />
                    <Button 
                        onClick={addLink as any} 
                        variant="red" 
                        className="sm"
                        disabled={!socialLink}
                    >
                        Add
                    </Button>
                </ModalContainer>
            </PopupWrapper>
        </List>
    )
}
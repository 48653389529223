import { Action, Content, AnimatedIcon, Info, Title, Warning, NoteDesc } from "./user-warning.styles"
import { ReactComponent as WarningIcon } from "../../assets/images/profile-nav/warning-icon.svg";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/UserContext";
import axios from "axios";
import PopupWrapper, { StyledPopupHeader } from "../../Views/Admin/Popup.wrapper";

export const UserNote = () => {
    const person = useContext(UserContext);
    const [ showUnread, setShowUnread ] = useState<any>([]);
    const [ showModal, setShowModal ] = useState(false);
    
    const getMessage = async () => {
        await axios.get(`/member-messages/${person?.user?.id}?isRead=false`).then((res:any)=>{
            setShowUnread(res?.data ?? []);
        })
    }

    const postRead = async () => {
        if(!showUnread.length) return;
        await axios.post(`/member-messages/read/${showUnread?.[0]?.id}`)
    }

    const handleClose = async () => {
        await postRead();
        setShowModal(false);
        await getMessage();
    }

    useEffect(()=>{
        getMessage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[person?.user?.id])

    if(!showUnread.length) return null;
    
    return(<>
        <Warning>
            <AnimatedIcon>
                <WarningIcon />
            </AnimatedIcon>
            <Content>
                <Info>
                    <Title>You received a note from the administrator</Title>
                    {/* <Desc>{person?.infoChange?.admin_comment}</Desc> */}
                </Info>
                <Action>
                    <div onClick={()=> setShowModal(true)}>
                        See Note
                    </div>
                </Action>
            </Content>
        </Warning>
        <PopupWrapper isopen={showModal}>
            <StyledPopupHeader>
                <div className="popup-title">Note:</div>
                <div className="close" onClick={handleClose}></div>
            </StyledPopupHeader>
            <NoteDesc>{showUnread?.[0]?.message ?? ""}</NoteDesc>
        </PopupWrapper>
    </>)
}
import DashboardIcon from "../../../assets/images/profile-nav/dashboard-icon";
import ProfileIcon from "../../../assets/images/profile-nav/profile-icon";
import LogoutIcon from "../../../assets/images/profile-nav/logout-icon";
import FriendsIcon from "../../../assets/images/profile-nav/friends-icon";

export const navData = [
    {
        title: "Dashboard",
        path: "/user/dashboard",
        icon: DashboardIcon
    },
    {
        title: "Friends",
        path: '/user/friends',
        icon: FriendsIcon,
    },
    {
        title: "Profile",
        path: '/user/profile',
        icon: ProfileIcon,
    },
    {
        title: "Logout",
        path: 'logout',
        icon: LogoutIcon,
    }
];
import styled from "styled-components";

export const TabGroup = styled.div`
    width:100%;
    height:72px;
    display:flex;
    @media (max-width: 1000px) {
        height: 67px;
        border-top:1px solid #292929;
    }
`

export const Tab = styled.div<{$isActive?:boolean}>`
    cursor:pointer;
    display:flex;
    padding:0 24px;
    border-right:1px solid #292929;
    display:flex;
    align-items:center;
    justify-content:center;
    font-size:18px;
    font-family: 'Main Regular';
    color:${({$isActive}) => $isActive ? 'rgba(255,255,255,0.82)' : 'rgba(255,255,255,0.37)'};
    border-bottom: 1px solid ${({$isActive}) => $isActive ? '#FF4747' : '#292929'};
    @media (max-width: 1000px) {
        text-align:center;
        font-size:14px;
        width:50%;
    }
`
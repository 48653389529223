import { FC } from "react"
import { Desc, Header, HeaderEdit, Stack, Title } from "./edit-header.styles";

type EditHeaderProps = {
    title: string;
    desc: string;
    mini?: boolean;
    onClick?: () => void;
    onContainerClick?: () => void;
    disabled?: boolean;
    editQuote?: string;
}
export const EditHeader:FC<EditHeaderProps> = ({
    title,
    desc,
    onClick,
    mini,
    onContainerClick,
    disabled,
    editQuote
}) => {
    return(
        <Header $mini={mini} onClick={onContainerClick}>
            <Stack>
                <Title>{title}</Title>
                <Desc>{desc}</Desc>
            </Stack>
            <HeaderEdit 
                onClick={onClick}
                $disabled={disabled}
                title={editQuote}
            />
        </Header>
    )
}
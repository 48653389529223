import styled from 'styled-components';

export const Split = styled.div`
    width:100%;
    display:grid;
    grid-template-columns: 314px 1fr;
    align-items:stretch;
    border-radius:8px;
    border:1px solid #272727;
    @media (max-width: 1000px){
        display:flex;
        flex-direction:column;
        border: none;
    }
`;

export const SplitSide = styled.div`
    padding:16px;
    height:100%;
    border-right:1px solid #292929;
    @media (max-width: 1000px){
        border: none;
        padding:0;
        height:initial;
        width:100%;
    }
    `;

export const SplitContent = styled.div`
    height:100%; 
    @media (max-width: 1000px){
        width:calc(100% + 32px);
        margin: 0 -16px;
    }
`;
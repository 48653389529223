import { FC } from "react"
import { Avatar, AvatarEdit, AvatarReview } from "./member-avatar.styles"
import { EUserStatus } from "../../../../../utils/interfaces/user-status.enum";
import defaultImage from '../../../../../assets/images/registered-default-image.png';

type MemberAvatarProps = {
    url: string;
    status: EUserStatus;
    reviewUrl?: string;
    rest?:any;
    editMode?: boolean;
    localUrl?: string;
    isReview?: boolean;
}

export const MemberAvatar:FC<MemberAvatarProps> = ({
    url,
    status,
    reviewUrl,
    editMode,
    localUrl,
    isReview,
    ...rest
}) => {
    const imagePath = `${process.env.REACT_APP_HOST}/public/users/${url}`;
    const reviewPath = `${process.env.REACT_APP_HOST}/public/users/${reviewUrl}`;
    const checkedImage = url ? imagePath : defaultImage;
    
    if(editMode) return(
        <AvatarEdit {...rest} data-status={"Upload image"} >
            <img src={localUrl ? localUrl : imagePath} alt="profile avatar" />
        </AvatarEdit>
    )

    if(isReview) return(
        <AvatarReview $isActive={!!reviewUrl}>
            <img src={reviewUrl ? reviewPath : (localUrl || checkedImage)} alt="profile avatar new" />
            { reviewUrl && <img src={(localUrl || checkedImage)} alt="profile avatar" />}
        </AvatarReview>
    )

    return(
        <Avatar $status={status} data-status={status}>
            <img src={reviewUrl ? reviewPath : (localUrl || checkedImage)} alt="profile avatar" />
        </Avatar>
    )
}
export const shortenFilename = (filename:string):string => {
    const parts = filename.split("-");
    if (parts.length < 2) return filename; 
  
    const nameWithExt = parts.slice(1).join("-"); 
    const lastDotIndex = nameWithExt.lastIndexOf(".");
    
    if (lastDotIndex === -1) return nameWithExt;
  
    const ext = nameWithExt.slice(lastDotIndex);
    const baseName = nameWithExt.slice(0, lastDotIndex);
  
    return `${baseName.slice(0, 4)}...${baseName.slice(-4)}${ext}`;
}
import styled from "styled-components";
import { profileStatus } from "../../../../constants/profile-statuses";
import { EUserStatus } from "../../../../utils/interfaces/user-status.enum";

export const Stack = styled.div`
    border-radius:12px;
    background:#0C0C0C;
    width:100%;
    padding:16px 0 24px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction:column;
    @media (max-width: 1000px){
        align-items: flex-start;
        padding: 24px;
    }
`;

export const ProfileName = styled.h3`
    font-size:18px;
    color:#fff;
    margin:12px 0 4px 0;
    text-align:center;
    font-family: 'Main Bold';
    @media (max-width: 1000px){
        align-items: flex-start;
        margin:16px 0;
        font-family: 'Main Regular';
        font-weight: 600;
    }
`;

export const ProfileStatus = styled.p<{$status?: EUserStatus}>`
    color:${({$status}) => $status ? profileStatus[$status].color : '#fff'};
    font-size:12px;
    line-height:18px;
    font-family: 'Main Bold';
`;

import { useDropzone } from "react-dropzone";
import { InputContainer } from "../../../Components/Input/Input.styles"
import PopupWrapper, { StyledPopupHeader } from "../../../Views/Admin/Popup.wrapper"
import { StyledButton } from "../../../Components/Button/Button.styles";
import * as Yup from 'yup';
import { FC, useState } from "react";
import { useFormik } from "formik";
import axios, { AxiosError } from "axios";
import LoadingWrapper from "../../../Views/Admin/Loading.wrapper";

interface IUploadModal {
    onClose: () => void,
    open: boolean,
}

const validationSchema = Yup.object({
    file: Yup.mixed().required('Image is required'),
});


export const UploadModal:FC<IUploadModal> = ({
    onClose,
    open,
}) => {
    const [ exel, setExel ] = useState<any>(null);
    const [ success, setSuccess ] = useState<boolean>(false);
    const [ error, setError ] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const onSubmit = async (values: any) => {
        const valuesCopy = {...values};
        
        const formData = new FormData();

        console.log(valuesCopy);
       
        for (const [key, value] of Object.entries(valuesCopy)) {
            formData.append(key, value as Blob);
        }
        
        setIsLoading(true);

        try {
            await axios.post(`ra-invites/upload`, formData)
                .then(() => {
                    setSuccess(true);
                    formik.resetForm();
                    setExel(null);
                })
                .catch((err) => {
                    if(err.response?.data?.message?.length > 0) {
                        setError(err.response?.data?.message.join(' '));
                    }
                    else {
                        setError(err.response?.data?.message);
                    }
                    alert('h,,')
                });
        } catch (err) {            
            if (err && err instanceof AxiosError) {
                if(err.response?.data?.message === 'INVALID_CREDENTIALS') {
                    setError(false);
                }
                else {
                    setError(err.response?.data?.message);
                }
            }
            else if (err && err instanceof Error) {
                setError(false);
            }
        }
        setIsLoading(false);
        console.log('SUBMITED')
    };

    const formik = useFormik({
        initialValues: {
            file: '',
        },
        validationSchema,
        onSubmit,
    });
    
    const {getRootProps, getInputProps} = useDropzone({
            accept: {
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx", ".xls", ".csv"],
                "application/vnd.ms-excel": [".xlsx", ".xls", ".csv"],
            },
            maxFiles: 1,
            onDrop: (acceptedFiles) => {
                const file: any = acceptedFiles[0];
                formik.setFieldValue('file', file);
    
                setExel(file?.name);
            }
    });
    
    return(
        <PopupWrapper isopen={open}>
            <StyledPopupHeader>
                <div className="popup-title">Add RA Codes</div>
                <div className="close" onClick={()=>{
                    onClose();
                    setError(false);
                    setSuccess(false);
                }}></div>
            </StyledPopupHeader>
            <LoadingWrapper loading={isLoading}>
                {
                    success ? <SuccessBox /> : error ? <ErrorBox /> : 
                    <form className="ra-codes__form-body" onSubmit={formik.handleSubmit}>   
                        <InputContainer>
                            <label htmlFor='country'>Upload file<span className="required-field">*</span></label>
                            
                            <div className="dropPresent dropPresent--event" {...getRootProps()}>
                                <input {...getInputProps()} />
                                {
                                    exel ?
                                    <p>{exel}</p> : 
                                    <p>Drag 'n' drop some file here, or click to select files</p>
                                }
                            </div>
                        </InputContainer>
                        <StyledButton type='submit' variant='red' className='sm ra-codes__form-button' disabled={!(formik.isValid && formik.dirty)}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6 12H12M12 12H18M12 12V18M12 12V6" stroke="#F2F3F4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            Upload
                        </StyledButton>
                </form>
                }
            </LoadingWrapper>
        </PopupWrapper>
    )
}


export const SuccessBox = () => {
    return <div className="ra-codes__status">
        <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" width="48" height="48" rx="24" fill="#8FFF68"/>
            <path d="M21.6262 31.1051C21.2501 31.1082 20.8888 30.9475 20.6253 30.6599L15.9039 25.6002C15.3684 25.0136 15.3649 24.077 15.8961 23.4858C16.4273 22.8947 17.3008 22.8631 17.868 23.4144L21.6451 27.4622L31.0878 17.3429C31.3521 17.0561 31.7126 16.8947 32.0887 16.8947C32.4648 16.8947 32.8253 17.0561 33.0896 17.3429C33.6368 17.9378 33.6368 18.8933 33.0896 19.4882L22.6271 30.6599C22.3648 30.9495 22.0028 31.1105 21.6262 31.1051Z" fill="#181818"/>
        </svg>
        <div className="ra-codes__status-msg">
            User successfully invited
        </div>
    </div>
}

export const ErrorBox = () => {
    return <div className="ra-codes__status">
        <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" width="48" height="48" rx="24" fill="#FF4747"/>
            <path d="M19 30L30 18M30 30L19 18" stroke="#181818" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
        <div className="ra-codes__status-msg">
            Something went wrong
        </div>
    </div>
}
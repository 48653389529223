import './Events.scss';

import Title from "../../../Components/Title/Title.component";
import Layout from "../../../Views/Admin/Layout"
import { useEffect, useState } from 'react';
import axios, {AxiosResponse} from 'axios';
import { NavLink, useSearchParams } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Loader } from '../../../Components/Loader/Loader.component';
import { useDebounce } from 'use-debounce';
import EndMessage from '../../../Components/EndMessage/EndMessage.component';
import { IEvent } from '../../../utils/interfaces/events.interface';
import EventBlockComponent from '../../../Components/EventBlock/EventBlock.component';
import { StyledButton } from '../../../Components/Button/Button.styles';
import { toast } from 'react-toastify';
import { PermissionsEnum } from '../../../utils/interfaces/permissions.enum';
import { PermissionGuard } from '../../../Components/PermissionGuard/PermissionGuard.component';
import { usePermissionGuard } from '../../../hooks/usePermissionsGuard';
import { toastOptions } from '../../../utils/helpers/toast.options';

const EventsPage: React.FC = () => {
    const [events, setEvents] = useState<IEvent[]>([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const [status, setStatus] = useState<string | null>(searchParams.get("status"))
    const [onlyScheduled, setOnlyScheduled] = useState<boolean>(searchParams.get('onlyScheduled') === 'true' ? true : false)
    const [page, setPage] = useState<number>(1);
    const [searchText, setSearchText] = useState<string | null>(null);
    const [hasMore, setHasMore] = useState<boolean>(true);
    const [value] = useDebounce(searchText, 300);
    const canSeeDetails = usePermissionGuard(PermissionsEnum.EVENTS_DETAIL_VIEW)

    const getEvents = async (action?: 'next' | null) => {
        let url: string = `events?limit=10&page=${page}`;

        value && (url += `&search=${value}`)
        
        status && status !== 'ALL' && (url += `&status=${status}`)

        onlyScheduled && (url += `&onlyScheduled=true`)
        
        await axios.get(url)
            .then((res: AxiosResponse) => {
                const { data } = res;
                
                data.length < 10 && setHasMore(false)
                action === 'next' ? setEvents([...events, ...data]) : setEvents(data);

                setPage(page + 1)
            })
    }

    const changeState = (status: string) => (): void => {
        if(status === 'onlyScheduled')  {
            searchParams.delete('status')
            searchParams.delete('search')
            setOnlyScheduled(true)
            setPage(1)
            setStatus(null)
            setEvents([])
            setHasMore(true)
            setSearchParams({onlyScheduled: 'true'})
        }
        else {
            const currentStatus = searchParams.get("status")
            const isSameStatus = currentStatus === status;
    
            if(isSameStatus) return;
    
            setStatus(status)
            setOnlyScheduled(false)
            setPage(1)
            setEvents([])
            setHasMore(true)
            setSearchParams({status})
        }
        
    }

    const searching = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setPage(1)
        setSearchText(e.target.value)
    };

    useEffect(() => {
        getEvents();

        if(searchParams.get('action') === 'delete') {
            toast.error(`Event deleted 🥹`, toastOptions)

            setSearchParams({action: ''})
        }
        
        // eslint-disable-next-line
    }, [value, searchParams])

    return (
        <Layout>
            <PermissionGuard role={PermissionsEnum.EVENTS_PAGE_VIEW}>
                <>
                    <Title title={'Events'}>
                        <PermissionGuard role={PermissionsEnum.ADD_EVENT}>
                            <NavLink to='/admin/events/create'>
                                <StyledButton variant="red" className="sm">Add event</StyledButton>
                            </NavLink>
                        </PermissionGuard>
                    </Title>

                    <div className="navigation">
                        <div className='navigation__container'>
                            <div onClick={changeState('ALL')} className={`navigation__item ${status === 'ALL' && 'active'}`}>All Events</div>
                            <div onClick={changeState('published')} className={`navigation__item ${status === 'published' && 'active'}`}>Public</div>
                            <div onClick={changeState('draft')} className={`navigation__item ${status === 'draft' && 'active'}`}>Draft</div>
                            <div onClick={changeState('archived')} className={`navigation__item ${status === 'archived' && 'active'}`}>Past</div>
                            <div onClick={changeState('onlyScheduled')} className={`navigation__item ${searchParams.get('onlyScheduled') && 'active'}`}>Scheduled</div>
                        </div>
                        <input type='text' onChange={searching} value={searchText ? searchText : ''} placeholder='Search events...' className="navigation__search" />
                    </div>

                    {
                            <InfiniteScroll
                                dataLength={events.length} 
                                next={() => getEvents('next')}
                                hasMore={hasMore}
                                loader={<Loader />}
                                endMessage={<EndMessage />}
                            >
                                {
                                    events.map((event: IEvent) => (
                                        <EventBlockComponent
                                            key={event.id}
                                            id={event.id}
                                            date={event.start_date}
                                            name={event?.name || ''}
                                            cover={event.cover_url}
                                            canNavigate={canSeeDetails}
                                        />
                                    ))
                                }
                        </InfiniteScroll>
                    }
                </>
            </PermissionGuard>
        </Layout>
    )
}

export default EventsPage;

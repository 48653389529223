import { FormikValues, useFormik } from "formik";
import PopupWrapper, { StyledPopupHeader } from "../../../../../Views/Admin/Popup.wrapper"
import * as Yup from 'yup';
import { Button } from "../../../../../Components/Button/Button.component";
import { Form } from "./decline-modal.style";
import Textarea from "../../../../../Components/Textarea/Textarea.component";
import { toastOptions } from "../../../../../utils/helpers/toast.options";
import { toast } from "react-toastify";
import { Dispatch, FC, SetStateAction } from "react";
import { IUser } from "../../../../../utils/interfaces/user.interface";
import axios from "axios";

const validationSchema = Yup.object({
    admin_comment: Yup.string().required('Comment is required'),
});

export type DeclineModalProps = {
    reviewData: IUser | null;
    show: boolean;
    setShow: Dispatch<SetStateAction<boolean>>;
    clearReviewData: () => void;
}

export const DeclineModal:FC<DeclineModalProps> = ({reviewData, show, setShow, clearReviewData}) => {
    
    const sentDecline = async (value:FormikValues) => {
        if(!reviewData) return;
        axios.post(`/info-change/action/${reviewData.id}`, { 
            "status": "cancelled",
            ...value

        }).then(()=>{
            toast.success(`Member was declined 🦄`, toastOptions)
            clearReviewData();
            formik.resetForm();
            setShow(false);
        }).catch(()=>{
            toast.error(`something went wrong`, toastOptions)
            formik.resetForm();
            setShow(false);
        })
    }
    const onSubmit = (values:FormikValues) => {
        sentDecline(values);
    }

    const formik = useFormik({
        initialValues: {
            admin_comment: '',
        },
        validationSchema,
        onSubmit,
    });
    
    return(
        <PopupWrapper isopen={show}>
            <StyledPopupHeader>
                <div className="popup-title">Cancel Note</div>
                <div className="close" onClick={() => setShow(false)}></div>
            </StyledPopupHeader>
            <Form onClick={formik.handleSubmit}>
                <Textarea
                    placeholder='Describe what user should change'
                    id="admin_comment"
                    className='lnf__input loginForm__input--noRounded'
                    name='admin_comment'
                    error={formik.touched.admin_comment && formik.errors.admin_comment}
                    value={formik.values.admin_comment}
                    onChange={formik.handleChange}
                />
                <Button className='sm' variant="red" onClick={formik.handleSubmit}>
                    Send
                </Button>
            </Form>
        </PopupWrapper>
    )
}
import { EUserStatus } from "../utils/interfaces/user-status.enum";
import VerifyIcon from '../assets/images/verify-icon.svg';
import PendingIcon from '../assets/images/status-pending.svg';
import BlockedIcon from '../assets/images/status-blocked.svg';
import UnverifiedIcon from '../assets/images/status-unverified-icon.svg';
import RegisteredIcon from '../assets/images/alam-gray.svg';   
import { EInvitesStatus } from "../utils/interfaces/invite-status.enum";

export const profileStatus = {
    [EUserStatus.VERIFIED]: {
        color: '#8FFF68',
        icon: VerifyIcon, 
    },
    [EUserStatus.PENDING]: {
        color: '#9a8f31',
        icon: PendingIcon, 
    },
    [EUserStatus.BLOCKED]: {
        color: '#c72d2d',
        icon: BlockedIcon, 
    },
    [EUserStatus.DISABLED]: {
        color: '#9e9e9e',
        icon: 'lose-invited', 
    },
    [EUserStatus.UNVERIFIED]: {
        color: '#828282',
        icon:  UnverifiedIcon, 
    },
    [EUserStatus.REGISTERED]: {
        color: '#676767',
        icon: RegisteredIcon, 
    },
}

export const inviteStatus = {
    [EInvitesStatus.INVITED]:{
        color: '#828282',
        icon:  UnverifiedIcon, 
    },
    [EInvitesStatus.REGISTERED]:{
        color: '#676767',
        icon: RegisteredIcon, 
    },
    [EInvitesStatus.ACCEPTED]:{
        color: '#8FFF68',
        icon: VerifyIcon, 
    },
    [EInvitesStatus.REJECTED]:{
        color: '#c72d2d',
        icon: BlockedIcon, 
    },
}

export const logStatus = {
    "completed": {
        color: '#8FFF68',
        icon: VerifyIcon, 
    },
    "cancelled": {
        color: '#c72d2d',
        icon: BlockedIcon, 
    },
    "pending": {
        color: '#9a8f31',
        icon: PendingIcon, 
    },
    "read": {
        color: '#8FFF68',
        icon: VerifyIcon, 
    },
    "unread": {
        color: '#676767',
        icon: RegisteredIcon, 
    },
}
import styled from "styled-components";

export const Warning = styled.div`
    width:100%;
    background:#301A0F;
    border: 1px solid #6D480D;
    border-radius:12px;
    min-height: 78px;
    padding:16px;
    display:flex;
    justify-content:space-between;
    gap:16px;
    
    @media (max-width: 600px){
        padding:16px;
    }
    
`;

export const Content = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    @media (max-width: 600px){
        flex-direction:column;
        align-items:flex-start;
        gap: 12px;
    }
`;

export const Action = styled.div`
    font-size:14px;
    font-family: Inter semi;
    color:#fff;
    text-decoration: underline;
    text-underline-offset: 4px;
    white-space:nowrap;
    cursor:pointer;
    display:flex;
    align-items:center;
    a{
        color:inherit;
    }
    @media (max-width: 600px){
        font-size:12px;
    }
`;

export const Icon = styled.div`
    width:36px;
    height:36px;
    border-radius: 100%;
    background:#0C0C0C;
    border:4px solid #382B0B;
    box-shadow: 0px 0px 0px 4px #52350E;
    color:#DC6803;
    display:flex;
    justify-content:center;
    align-items:center;
    flex-shrink:0;
    @media (max-width: 600px){
        width:30px;
        height:30px;
    }
`;

export const AnimatedIcon = styled(Icon)`
    animation: wave 1.4s infinite ease-in-out;

    @keyframes wave{
        0%{
            box-shadow:0px 0px 0px 4px #52350E;
        }
        50%{
            box-shadow:0px 0px 0px 8px #52350E;
        }
        100%{
            box-shadow:0px 0px 0px 4px #52350E;
        }
    }
`;

export const Info = styled.div`
    width: 100%;
`;

export const Title = styled.div`
    font-size:14px;
    line-height: 120%;
    color:#fff;
    font-family: Inter semi;
    @media (max-width: 600px){
        font-size:12px;
    }
    `;

export const Desc = styled.div`
    margin-top: 8px;
    font-size:14px;
    line-height: 120%;
    font-family: Inter;
    color:#B54708;
    @media (max-width: 600px){
        font-size:12px;
    }
`;

export const NoteDesc = styled.div`
    font-size:14px;
    line-height: 120%;
    font-family: Inter;
    color:#fff;
    @media (max-width: 600px){
        font-size:12px;
    }
`;
import axios, { AxiosResponse } from 'axios';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ImageCard } from '../../../Components/ImageCard/ImageCard';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Podcasts.scss';
import { LangsContext } from '../../../context/LangsContext';
import { IPodcast } from '../../../utils/interfaces/podcasts.interface';

export const Podcasts = () => {
    const navigate = useNavigate();
    const pageLimit = 9;
    const [ podcasts, setPodcasts ] = useState<IPodcast[]>([]);
    const [ page, setPage ] = useState(1);
    const [ hasMore, setHasMore ] = useState(true);

    const content = useContext(LangsContext);
    
    useEffect(()=>{
        getPodcasts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const getPodcasts = async (action?: 'next' | null) => {
        await axios.get(`/podcasts/public/?limit=${pageLimit}&page=${page}`)
            .then((res: AxiosResponse)=>{
                const { data } = res;
                data.length < pageLimit && setHasMore(false)
                action === 'next' ? setPodcasts([...podcasts, ...data?.data]) : setPodcasts(data?.data);

                setPage(page + 1)
            })
    }
    
    return(
        <div className="public-podcasts">
            <h1 className="public-podcasts__header">
                {content?.OUR_RESIDENTS || 'Podcasts'}
            </h1>
            <InfiniteScroll 
                className='public-podcasts__content'
                next={() => getPodcasts('next')} 
                hasMore={hasMore} 
                loader={null} 
                dataLength={podcasts.length}
            >
                {
                    podcasts?.map((podcast:IPodcast) => {
                        return <div className='public-podcasts__card' key={podcast.id}>
                            <div className="public-podcasts__card-cover">
                                <ImageCard
                                    onClick={()=>navigate(`/podcasts/${podcast.id}`)}
                                    url={`${process.env.REACT_APP_HOST}/public/podcasts/${podcast.image}`}
                                    alt={podcast.title}
                                />
                            </div>
                            <p className="public-podcasts__card-title">{podcast.title}</p>
                        </div>
                        
                    })
                }
            </InfiniteScroll>
        </div>
    )
}
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react"
import { ActionItem, Actions, Form, Header, HiddenOnMD, LogActionBy, LogBold, LogIcon, LogInfo, LogItem, LogP, LogStatus, LogTime, LogView, WriteButton } from "./member-header.styles";
import {ReactComponent as MemberLogIcon} from '../../../../../assets/images/profile-nav/member-logs.svg';
import {ReactComponent as MemberEditIcon} from '../../../../../assets/images/profile-nav/member-edit.svg';
import {ReactComponent as MemberCloseIcon} from '../../../../../assets/images/profile-nav/member-close.svg';
import { Show } from "../../../../../Components/Show/Show";
import PopupWrapper, { StyledPopupHeader } from "../../../../../Views/Admin/Popup.wrapper";
import axios from "axios";
import { IUser } from "../../../../../utils/interfaces/user.interface";
import { convertDate } from "../../../../../utils/helpers/convertDate";
import { shortenFilename } from "./shortenFilename";
import { ReactComponent as WriteNoteIcon } from '../../../../../assets/images/profile-nav/write-note.svg';
import Textarea from "../../../../../Components/Textarea/Textarea.component";
import { Button } from "../../../../../Components/Button/Button.component";
import { FormikValues, useFormik } from "formik";
import * as Yup from 'yup';
import { toast } from "react-toastify";
import { toastOptions } from "../../../../../utils/helpers/toast.options";
import moment from 'moment';

const validationSchema = Yup.object({
    message: Yup.string().required('Note is required'),
});

export type MemberHeaderProps = {
    title: string;
    editMode?: boolean;
    setEditMode: Dispatch<SetStateAction<boolean>>;
    member_id: string;
    member: IUser;
    allowActions: boolean;
    reviewData: IUser | null;
};

export const MemberHeader:FC<MemberHeaderProps> = ({
    title,
    editMode,
    setEditMode,
    member_id,
    member,
    allowActions,
    reviewData
}) => {
    const [ openLog, setOpenLog ] = useState(false);
    const [ noteModal, setNoteModal ] = useState(false);
    const [ infoChanges, setInfoChanges ] = useState<any[]>([]);

    const sentNote = async (value:FormikValues) => {
        axios.post(`/member-messages`, { 
            "user": member_id,
            ...value

        }).then(()=>{
            toast.success(`Note was send 🦄`, toastOptions)
            formik.resetForm();
            setNoteModal(false);
            getData();
        }).catch(()=>{
            toast.error(`something went wrong`, toastOptions)
            setNoteModal(false);
            formik.resetForm();
        })
    }
        
    const onSubmit = (values:FormikValues) => sentNote(values)
    
    const formik = useFormik({
        initialValues: {
            message: '',
        },
        validationSchema,
        onSubmit,
    });
   

    const getMessageData = () => axios.get(`/member-messages/${member_id}`)
    const getHistoryData = () => axios.get(`info-change/list?userId=${member_id}`)

    const getData = async () => {
        await Promise.all([
            getMessageData(),
            getHistoryData()
        ]).then(([messages,history]) => {
            const infoHistory = formatInfoChange(history?.data ?? []);
            const infoNotes = messages?.data ?? [];

            const unified = [
                ...infoHistory,
                ...infoNotes,
            ].sort(sortByCreate)

            setInfoChanges(unified);
        })
    }

    const sortByCreate = (a:any, b:any) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
    
    const formatInfoChange = (data:any) =>  data.map((item: any) => {
        console.log(data);
        let changes:any[] = [];
        if(item.by) changes.push({key: 'by', old: parseItem(item.phone), new: parseItem(item.phone,true)});
        if(item.phone) changes.push({key: 'Phone', old: parseItem(item.phone), new: parseItem(item.phone,true)});
        if(item.full_name) changes.push({key: 'Full Name', old: parseItem(item.full_name), new: parseItem(item.full_name,true)});
        if(item.dob) changes.push({key: 'Birthdate', old: moment(parseItem(item.dob)).format('MMMM D, Y'), new: convertDate(item.dob)});
        if(item.profile_photo) changes.push({key: 'Profile Photo', old: shortenFilename(parseItem(item.profile_photo)), new: shortenFilename(parseItem(item.profile_photo,true))});
        if(item.id_number) changes.push({key: 'ID Number', old: parseItem(item.id_number), new: parseItem(item.id_number,true)});
        if(item.country) changes.push({key: 'Country', old: parseItem(item.country), new: parseItem(item.country,true)});
        if(item.state) changes.push({key: 'State', old: parseItem(item.state), new: parseItem(item.state,true)});
        if(item.passport_or_driver_license) changes.push({key: 'Passport or Driver License', old: parseItem(item.passport_or_driver_license), new: parseItem(item.passport_or_driver_license,true)});
        if(item.ra_link) changes.push({key: 'RA Link', old: parseItem(item.ra_link), new: parseItem(item.ra_link,true)});
        
        if(item.social_links) changes.push({key: 'Social Links', old: parseSocialItem(item?.social_links as string), new: parseSocialItem(item?.social_links as string,true)});
        
        item.changes = changes;
        return item;
    });

    const parseItem = (item:string,isNew?:boolean) => {
        const showNew = isNew ? 0 : 1;
        
        return item.split("@")?.[showNew] ?? '';
    }

    const parseSocialItem = (item:string,isNew?:boolean) => {
        const showNew = isNew ? 0 : 1;
        const parsedArray = JSON.parse(item[showNew]);
        return parsedArray.join(',');
    }
    
    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [member_id]);
 
    return(
        <Header>
            {title}
            <Actions>
                <Show condition={allowActions && Boolean(!editMode) && !reviewData}>
                    <>
                        <ActionItem onClick={() => setOpenLog(true)}>
                            <MemberLogIcon />
                            <HiddenOnMD>
                                Write note & History
                            </HiddenOnMD>
                        </ActionItem>

                        <ActionItem onClick={()=>setEditMode(true)}>
                            <MemberEditIcon />
                            <HiddenOnMD>
                                Edit Profile
                            </HiddenOnMD>
                        </ActionItem>
                    </>
                </Show>
                <Show condition={Boolean(editMode) && !reviewData}>
                    <ActionItem onClick={()=>setEditMode(false)}>
                        <MemberCloseIcon />
                    </ActionItem>
                </Show>
            </Actions>
            <PopupWrapper isopen={openLog}>
                <StyledPopupHeader>
                    <div className="popup-title">Change Log</div>
                    <div className="close" onClick={() => setOpenLog(false)}></div>
                </StyledPopupHeader>
                <div className="statusChange">
                    <WriteButton onClick={() => setNoteModal(true)}>
                        <WriteNoteIcon />
                        Write note
                    </WriteButton>
                    {
                        infoChanges.map((item: any, index:number) => {
                            if (Object.prototype.hasOwnProperty.call(item, "isRead")) {
                                const status = item.isRead ? "read" : "unread";

                                return <LogView key={`logview_element_${index}`}>
                                    <LogItem>
                                        <LogIcon $status={status}/>
                                        <LogInfo>
                                            <LogTime>{convertDate(item.created_at)}</LogTime>
                                            <LogP>
                                                <LogBold>Note:</LogBold> {item.message}
                                            </LogP>
                                        </LogInfo>

                                        <LogStatus $status={status}>{status.toUpperCase()}</LogStatus>
                                    </LogItem>
                                </LogView>
                            }
                           
                            return (
                                <LogView key={`logview_element_${index}`}>
                                    <LogItem>
                                        <LogIcon $status={item.status}/>
                                        <LogInfo>
                                            <LogTime>{convertDate(item.created_at)}</LogTime>
                                            {!item.changes?.length ? "No Data" : null}
                                            {item.changes.map((change: any,j:number) => (
                                                <LogP key={`logLG_element_${j}`}>
                                                    <LogBold>{change.key}</LogBold> {change.old} to <LogBold>{change.new}</LogBold>
                                                    <br />
                                                </LogP>
                                            ))}
                                            <Show condition={item?.created_by?.full_name}>
                                                <LogActionBy>
                                                    by
                                                    <LogBold> {item.created_by.full_name}</LogBold>
                                                </LogActionBy>
                                            </Show>
                                        </LogInfo>
    
                                        <LogStatus $status={item.status}>{item.status.toUpperCase()}</LogStatus>
                                    </LogItem>
                                </LogView>
                            )
                        })
                    }
                </div>
            </PopupWrapper>

            <PopupWrapper isopen={noteModal}>
                <StyledPopupHeader>
                    <div className="popup-title">Send note</div>
                    <div className="close" onClick={() => setNoteModal(false)}></div>
                </StyledPopupHeader>
                <div className="statusChange">
                    <Form onClick={formik.handleSubmit}>
                        <Textarea
                            placeholder='Describe what user should change'
                            id="message"
                            className='lnf__input loginForm__input--noRounded'
                            name='message'
                            error={formik.touched.message && formik.errors.message}
                            value={formik.values.message}
                            onChange={formik.handleChange}
                        />
                        <Button className='sm' variant="red" type="submit">
                            Send
                        </Button>
                    </Form>
                </div>
            </PopupWrapper>
        </Header>
    )
}




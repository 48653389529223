import styled from "styled-components";
import { EUserStatus } from "../../../../../utils/interfaces/user-status.enum";
import { profileStatus } from "../../../../../constants/profile-statuses";
import UploadIcon from '../../../../../assets/images/profile-nav/upload-icon-yellow.svg'

export const RawAvatar = styled.div`
    width: 280px;
    height: 350px;
    border-radius: 10px;
    overflow: hidden;
    border-width: 1.6px;
    border-style: solid;
    position: relative;
    flex-shrink:0;
    &:before{
        position:absolute;
        top:0;
        left:0;
        content:'';
        width:100%;
        height:100%;
        display:block;
        z-index:1;
    }
    &:after{
        content:attr(data-status);
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
        font-family: 'Main Bold';
        font-size:16px;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        padding-bottom: 25px;
        box-sizing:border-box;
        background-position: center calc(100% - 32px - 25px);
        background-size: 32px 32px;
        background-repeat: no-repeat;
        z-index:1;
    }

    img{
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
        object-fit:cover;
        z-index:0;
    }

    @media (max-width: 468px) {
      width:100%;
      height:100%:
      max-height: 500px;

    }
`;

export const Avatar = styled(RawAvatar)<{$status: EUserStatus}>`
    border:1.6px solid ${({$status}) => profileStatus[$status].color};
    &:before{
        background-image: linear-gradient(180deg, transparent 5%, ${({$status}) => profileStatus[$status].color} 300%);
    }

    &:after{
        color: ${({$status}) => profileStatus[$status].color};
        background-image: url(${({$status}) => profileStatus[$status].icon});
    }
`;

export const AvatarEdit = styled(RawAvatar)`
    cursor:pointer;
    border:1.6px solid #ffc42e;
    &:before{
        background-image: linear-gradient(180deg, transparent 5%, #ffc42e 300%);
    }

    &:after{
        color: #ffc42e;
        background-image: url(${UploadIcon});
    }
`;

export const AvatarReview = styled(RawAvatar)<{$isActive?:boolean}>`
    border:1.6px solid ${({$isActive}) => $isActive ? '#ffc42e' : '#676767'}; 
    &:before{
        background-image: linear-gradient(180deg, transparent 5%, ${({$isActive}) => $isActive ? '#ffc42e' : '#676767'} 300%);
    }

    &:after{
        color: ${({$isActive}) => $isActive ? '#ffc42e' : '#676767'};
        background-image: url(${UploadIcon});
    }

    img:nth-child(2){
        opacity:0;
        transition:0.3s all;
        position:absolute;
        top:0;
        left:0;
        z-index:1;
    }

    &:hover{
        img:nth-child(2){
            opacity:1;
        }
    }
`;
import { useDropzone } from "react-dropzone";
import PopupWrapper, { StyledPopupHeader } from "../../Views/Admin/Popup.wrapper"
import { InputContainer } from "../Input/Input.styles"
import { FC, useEffect, useState } from "react";
import { useFormik } from "formik";
import Input from "../Input/Input.component";
import MDEditor from "@uiw/react-md-editor";
import './AlbumModalUpdate.scss';
import { StyledButton } from "../Button/Button.styles";
import * as Yup from 'yup';
import LoadingWrapper from "../../Views/Admin/Loading.wrapper";
import axios, { AxiosError, AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { toastOptions } from "../../utils/helpers/toast.options";
import { PermissionGuard } from "../PermissionGuard/PermissionGuard.component";
import { PermissionsEnum } from "../../utils/interfaces/permissions.enum";
import ConfirmPopup from "../ConfirmPopup/ConfirmPopup";

const albumSchema = Yup.object({
    title: Yup.string(),
    artistName: Yup.string(),
    description: Yup.string(),
    url: Yup.string(),
    image: Yup.mixed(),
});

interface IAlbumModalUpdate{
    isOpen: boolean;
    id: string;
    onClose: () => void;
    refetch: () => void;
}
export const AlbumModalUpdate:FC<IAlbumModalUpdate> = ({
    isOpen,
    onClose,
    refetch,
    id,
}) => {
    const imagePath = 'https://basementny.mtebi.com/public/podcasts/';
    const [image, setImage] = useState<any>(null)
    const [ confirm, setConfirm ] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    
    const onSubmit = async (values: any) => {
        const valuesCopy = Object.fromEntries(
            Object.entries(values).filter(([_, value]) => value)
        )
        
        const formData = new FormData();

        for (const [key, value] of Object.entries(valuesCopy)) {
            formData.append(key, value as Blob);
        }
        
        setIsLoading(true);
        try {
            await axios.put(`podcasts/album/${id}`, formData)
                .then(() => {
                    toast.success(`Album updated 🦄`, toastOptions)
                    refetch();
                    onClose();
                    formik.resetForm();
                    setImage(null);
                })
                .catch((err) => {
                    if(err.response?.data?.message?.length > 0) {
                        setError(err.response?.data?.message.join(' '));
                    }
                    else {
                        setError(err.response?.data?.message);
                    }
                });
        } catch (err) {            
            if (err && err instanceof AxiosError) {
                if(err.response?.data?.message === 'INVALID_CREDENTIALS') {
                    setError('Incorrect email or password.');
                }
                else {
                    setError(err.response?.data?.message);
                }
            }
            else if (err && err instanceof Error) {
                setError('Something went wrong. Please try again later.');
            }
        }

        setIsLoading(false);
    };

    const formik = useFormik({
        initialValues: {
            title: '',
            artistName: '',
            description: '',
            image: '',
            url: '',
        },
        validationSchema: albumSchema,
        onSubmit,
    });

    const getAlbum = () =>{
        if(id) axios.get(`podcasts/album/${id}`)
            .then((res: AxiosResponse) => {
                const { data } = res;
                formik.setValues(data);
                setImage(imagePath + data.image);
                setIsLoading(false);
            })
    }

    useEffect(()=>{
        getAlbum();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[id]);

    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        accept: {
            "image/*": [".png", '.jpg'],
        },
        maxFiles: 1,
        onDrop: (acceptedFiles) => {
            const file: any = acceptedFiles[0];
            formik.setFieldValue('image', file);
            setImage(URL.createObjectURL(file));
        }
    });

    const handleClose = () => {
        const isEmpty = Object.values(formik.values).every(x => (x === null || x === ''));
        if(isEmpty) {
            onClose();
        }else{
            setConfirm(true);
        }
    }

    return (
        <>
            <PopupWrapper isopen={isOpen}>
                <StyledPopupHeader>
                        <div className="popup-title">Update album</div>
                        <div className="close" onClick={handleClose}></div>
                </StyledPopupHeader>
                <LoadingWrapper loading={isLoading}>
                    <form className="album-modal-update" onSubmit={formik.handleSubmit}>
                        <Input 
                            placeholder='Type album name'
                            className='loginForm__input loginForm__input--noRounded'
                            label='Album name'
                            name='title'
                            error={formik.touched.title && formik.errors.title}
                            value={formik.values.title}
                            onChange={formik.handleChange}
                            showRequired
                        />

                        <Input 
                            placeholder='Type artist name'
                            className='loginForm__input loginForm__input--noRounded'
                            label='Artist name'
                            name='artistName'
                            error={formik.touched.artistName && formik.errors.artistName}
                            value={formik.values.artistName}
                            onChange={formik.handleChange}
                            showRequired
                        />

                        <InputContainer>
                            <label htmlFor='country'>Album image <span className="required-field"> *</span></label>
                            
                            <div className="dropPresent dropPresent--event" {...getRootProps()}>
                                <input {...getInputProps()} />
                                {
                                    isDragActive ?
                                    <p>Drop the file here ...</p> :
                                    <p>Drag 'n' drop some file here, or click to select files</p>
                                }
                                {
                                    image &&  <img src={image} alt="preview" className="dropPresent__image dropPresent__image--event" />
                                }
                            </div>
                        </InputContainer>

                        <InputContainer>
                            <label htmlFor={"end_date"}>Album Description <span className="required-field"> *</span></label>
                            <div data-color-mode="dark">
                                <MDEditor 
                                    height={350} 
                                    style={{
                                        border: '0px solid #272727',
                                        borderRadius: '6px',
                                        backgroundColor: '#181818',
                                    }}
                                    value={formik.values.description}
                                    onChange={(value: string | undefined) => formik.setFieldValue('description', value)}
                                />
                            </div>
                        </InputContainer>

                        <Input 
                            type='text'
                            placeholder='Paste the link here'
                            className='loginForm__input loginForm__input--noRounded'
                            label='Link to album'
                            name='url'
                            error={formik.touched.url && formik.errors.url}
                            value={formik.values.url}
                            onChange={formik.handleChange}
                            showRequired
                        />
                        { error && <div className='error-message'>{error}</div> }
                        <PermissionGuard role={PermissionsEnum.CAN_UPDATE_PODCAST}>
                            <div className="album-modal-update__group">
                                <StyledButton type={'submit'} variant='red' className='sm album-modal-update__button'>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6 12H12M12 12H18M12 12V18M12 12V6" stroke="#F2F3F4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                    Update Album
                                </StyledButton>
                            </div>
                        </PermissionGuard>
                    </form>
                </LoadingWrapper>

            </PopupWrapper>
            <ConfirmPopup
                label={'Are you sure you want to cancel'}
                text={'Album information will be lost'}
                onAction={(status)=>{
                    if(status) {
                        formik.resetForm();
                        setImage(null);
                        onClose();
                    }
                    setConfirm(false);
                }}
                isopen={confirm}
            />
        </>
    )
}
import React from 'react';
import styledComponent, { css } from 'styled-components';

export interface IInput extends React.InputHTMLAttributes<HTMLInputElement> {
  variant?: 'primary';
  error?: any;
  label? : string;
  tooltip?: string | null;
  showRequired?: boolean;
  showHideToggle?: boolean;
  icon?: React.ReactNode;
}

const StyledShowPassword = styledComponent.div`
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    img {
        width: 20px;
    }
`;

const StyledInput = styledComponent.input<IInput>`
    background: #000;
    border: 1px solid #272727;
    height: 68px;
    color: #fff;
    border-radius: 50px;
    padding: 0px 27px;
    outline: none;
    width: 100%;

    &::placeholder {
        color: #626262;
    }
    ${(props) =>
        props.variant === 'primary' &&
        css`
            
        `
    }

    @media (max-width: 980px){
        height:48px;
        padding:0 15px;
    }
`;

const InputContainer = styledComponent.div`
    display: flex;
    flex-direction: column;
    /* background-color: #000; */
    margin-bottom: 30px;
    .input-error {
        margin-top: 10px;
        color: #FF4747;
        font-size: 9px;
        padding-left: 15px;
    }
    label {
        padding-left: 10px;
        margin-bottom: 13px;
        font-size: 14px;
        color: #fff;
        font-family: "Main Bold";
        display:flex;
        align-items:center;
        gap:15px;
        span {
            font-size: 10px;
        }
        svg{
            width: 20px;
            height:20px;
        }
    }
    .add-link {
        color: #fff;
        font-size: 12px;
        cursor: pointer;
    }
`;

export { InputContainer, StyledShowPassword };

export default StyledInput;
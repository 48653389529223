import styled from "styled-components";
import { inviteStatus } from "../../../../constants/profile-statuses";
import LetterIcon from '../../../../assets/images/letter-icon.svg';
import { EInvitesStatus } from "../../../../utils/interfaces/invite-status.enum";

export const Stack = styled.div`
    width:100%;
    display:flex;
    gap:16px;
    flex-direction: column;
`

export const Block = styled.div<{$status: EInvitesStatus}>`
    width:100%;
    border-radius:8px;
    background:#181818;
    padding:0 16px;
    height: 60px;
    display:flex;
    justify-content:space-between;
    align-items:center;
    position: relative;
    @media (max-width: 600px){
        &:hover{
            &:after{
            position:absolute;
            bottom:45px;
            right:16px;
            background:#262626;
            border-radius:4px;
            content: attr(data-status);
            font-family: Main Regular;
            font-size:12px;
            line-height:16px;
            padding: 4px 8px;
            color:${({$status}) => inviteStatus[$status].color};
        }
        }
    }
`

export const BlockCol = styled.div`
    width:60%;
`;

export const Actions = styled.div`
    display:flex;
    align-items:center;
    gap:20px;
    flex-shrink: 0;
    @media (max-width: 600px){
        gap:6px;
    }
`

export const LetterButton = styled.div`
    cursor: pointer;
    color: #6c42c9;
    margin-right: 10px;
    background: url(${LetterIcon}) no-repeat left center;
    background-size: 22px 22px;
    padding-left: 25px;
    font-size:12px;
    font-family: Main Bold;
    text-transform: capitalize;
    height:22px;
    display:flex;
    align-items:center;
    @media (max-width: 600px){
        font-size:10px;
        background-size: 16px 16px;
        line-height:12px;
        padding-left:18px;
        margin:0;
    }
`

export const BlockLabel = styled.div`
    color:rgba(255,255,255,0.52);
    font-size:10px;
    font-family: Main Regular;
    margin-bottom:4px;
`

export const BlockTitle = styled.div`
    color:#fff;
    font-size:14px;
    line-height:18px;
    font-family: Main Bold;
    display:inline-block;
    white-space: nowrap;
    overflow:hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    @media (max-width: 600px){
        font-size:12px;
        line-height:16px;
    }
`

export const BlockStatus = styled.div<{$status: EInvitesStatus}>`
    height:22px;
    font-size:12px;
    font-family: Main Bold;
    text-transform: capitalize;
    padding-left:26px;
    color:${({$status}) => inviteStatus[$status].color};
    background: url(${({$status}) => inviteStatus[$status].icon}) no-repeat;
    background-position: center left;
    background-size: 22px 22px;
    display:flex;
    align-items:center; 
    @media (max-width: 600px){
        font-size:10px;
        background-size: 16px 16px;
        line-height:12px;
        padding-left:18px;
        span {
            display:none;
        }
    }
`;

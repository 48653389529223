import * as React from "react";

const DashboardIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="25"
    viewBox="0 0 24 25"
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="2"
      d="M17 10.548h2q3 0 3-3v-2q0-3-3-3h-2q-3 0-3 3v2q0 3 3 3m-12 12h2q3 0 3-3v-2q0-3-3-3H5q-3 0-3 3v2q0 3 3 3m1-12a4 4 0 1 0 0-8 4 4 0 0 0 0 8m12 12a4 4 0 1 0 0-8 4 4 0 0 0 0 8"
    ></path>
  </svg>
);

export default DashboardIcon;

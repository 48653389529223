import { useContext } from "react";
import { UserWarning } from "../../../Components/user-warning/user-warning"
import { Section, SectionBody, SectionTitle, Stack, Title } from "../components/info/dashboard.styles"
import { UserContext } from "../../../context/UserContext";
import { FriendList } from "../components/friend-block/friend-list";
import { Show } from "../../../Components/Show/Show";
import { UserNote } from "../../../Components/user-warning/user-note";

export const ProfileDashboard = () => {
    const profile = useContext(UserContext); 

    return(
        <Stack>
            <Show condition={!!profile?.user?.full_name}>
                <Title>
                    Hello, <br/>
                    {profile?.user?.full_name}
                </Title>
            </Show>

            <UserWarning />
            <UserNote />

            <Section>
                <SectionTitle>Friends</SectionTitle>
                <SectionBody>
                    <FriendList
                        list={profile?.user?.sent_invites}
                        user={profile?.user}
                    />
                </SectionBody>
            </Section>
        </Stack>
    )
}
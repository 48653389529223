import { FC } from "react";
import { ProfileSide } from "../../../Views/ProfileSide/ProfileSide";
import { ProfileName, ProfileStatus, Stack } from "../components/avatar-stack/avatar-stack.styles";
import { IUser } from "../../../utils/interfaces/user.interface";
import { Navigation } from "../components/navigation/navigation";

export type SidebarProps = {
    user?: IUser;
}

export const Sidebar:FC<SidebarProps> = ({user}) => {
    return <>
        <Stack>
            <ProfileSide user={user}/>

            <ProfileName> {user?.full_name} </ProfileName>

            <ProfileStatus $status={user?.status}> ID {user?.status} </ProfileStatus>
        </Stack>

        <Navigation />
    </>
}
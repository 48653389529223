import styled from "styled-components";

export const InputForm = styled.div`
    width: 100%;
    word-break: break-all;
    display:flex;
    flex-direction: column;
`;

export const InputLabel = styled.label<{$isNew?:boolean}>`
    font-size: 10px;
    color: ${({$isNew}) => $isNew ? '#FFC221' : '#fff'};
    margin-bottom: 4px;
    font-family: "Main Regular";
`
export const InputPlace = styled.div`
    width: 100%;
    position:relative;
`;

export const InputField = styled.input`
    width:100%;
    font-size: 16px;
    color: #fff;
    font-family: "Main Bold", sans-serif;
    background:transparent;
    border: none;
    outline: none;
    padding-bottom:4px;
    border-bottom: 1px solid #3A3A3A;
    &[readonly]{
        border-bottom: 1px solid transparent;
    }
`

export const InputFieldNew = styled(InputField)`
    position:absolute;
    top:0;
    left:0;
    color:#FFC221;
    background:#000;
    transition: 0.3s all;
    &:hover{
        opacity:0;
    }
`;
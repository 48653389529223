import  styledComponent from "styled-components";

interface IContainerProps {
    $ispopupopen: string;
    $view: 'note' | 'guideline' | undefined;
}

const Container = styledComponent.div<IContainerProps>`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,.7);
    z-index: 999;
    ${({ $ispopupopen }) => $ispopupopen === 'true' ? `display : flex;` : `display: none;`}
    ${({ $view }) => $view === 'note' && `
        .popup-container {
            min-width: 650px;
            max-height: 80vh;
            overflow-y: auto;
            @media (max-width: 768px) {
                min-width:calc(100vw - 40px);
            }
        }
    `};
    @media (max-width: 1275px) {
        height:100%;
    }
`;


const Content = styledComponent.div`
    background-color: #181818;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 1;
    padding: 0 28px 28px 28px;
    min-width: 400px;
    border-radius: 10px;
    max-height: 80vh;
    overflow: auto;
    @media (max-width: 1275px) {
        height:min-content;
    }
    @media (max-width: 768px) {
        padding: 0 15px 15px 15px;
        min-width:initial;
        width:calc(100vw - 40px);
    }
`;

export const StyledPopupHeader = styledComponent.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:28px 0;
    position:sticky;
    top:-1px;
    left:0;
    z-index:10;
    background-color: #181818;
    .close {
        width: 23px;
        height: 23px;
        background: url(${require("../../assets/images/close-icon.svg").default}) no-repeat center;
        background-size: 100%;
        cursor: pointer;
    }
    .popup-title {
        color: #fff;
        font-size: 18px;
        font-family: 'Main Bold', sans-serif;
    }
`

const PopupWrapper: React.FC<{isopen: boolean, children: React.ReactNode, view?: 'note' | 'guideline'}> = ({children, isopen, view}) => {
    return (
        <Container $ispopupopen={isopen.toString()} $view={view}>
            <Content className="popup-container">
                {children}
            </Content>
        </Container>
    )
};

export default PopupWrapper;
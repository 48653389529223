import { FC } from "react";

interface IRaCodesBlock {
    code:string,
    email?:string,
}

export const RaCodesBlock: FC<IRaCodesBlock> = ({code,email}) => {
    return(
        <div className="ra-codes__block">
            {code} { email && <span>/ {email}</span>}
        </div>
    )
}
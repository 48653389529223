import {  useState } from "react";
import { useSignIn } from "react-auth-kit";
import { FormikValues, useFormik } from "formik";
import { useNavigate, NavLink, NavigateFunction} from 'react-router-dom';
import axios, { AxiosError, AxiosResponse } from "axios";
import * as Yup from "yup";

import './LoginMember.scss';

import {Button} from '../../Components/Button/Button.component';
import Input from '../../Components/Input/Input.component';
import LoadingWrapper from '../../Views/Admin/Loading.wrapper';
import { ILogin } from "../../utils/interfaces/user.interface";
import { EUserStatus } from "../../utils/interfaces/user-status.enum";
import { PermissionsEnum } from "../../utils/interfaces/permissions.enum";
import { afterLoginRoute } from "../../utils/helpers/page-route";

const loginValidationSchema = Yup.object({
    email: Yup.string().email("Invalid email address").required("Email is required"),
    password: Yup.string().required("Password is required"),
});

const LoginMember: React.FC = () => {
    const [error, setError] = useState<string | null>(null);
    const signIn = useSignIn();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const navigate = useNavigate() as NavigateFunction;

    const onSubmit = async (values: any) => {
        setIsLoading(true);

        try {
            await axios.post("users/login",values)
                .then((response: AxiosResponse<ILogin>) => {
                    const { data }= response;
                    
                    signIn({
                        token: data.token,
                        expiresIn: 999999,
                        tokenType: "Bearer",
                        authState: data.user,
                    });
                    let userData = data?.user;

                    if(userData.role && userData.role?.name !== 'MEMBER') {
                        let permissions: PermissionsEnum[] = userData.role?.permissions;

                        navigate(afterLoginRoute(permissions));
                    }
                    else {
                        if(data.user.is_activated) {
                            if(data.user.status === EUserStatus.PENDING) {
                                return navigate('/verification-pending')
                            }
                            if(data.user.status === EUserStatus.REGISTERED) {
                                return navigate('/complete-registration')
                            }
                            else {
                                return navigate('/user/dashboard')
                            }
                        }
                        else {
                            return navigate('/activate-account')
                        }
                        
                        
                    }
                });
        } catch (err) {
            if (err && err instanceof AxiosError) {
                if(err.response?.data?.message === 'INVALID_CREDENTIALS') {
                    setError('Incorrect email or password.');
                }
                else {
                    setError(err.response?.data?.message);
                }
                
            }
            else if (err && err instanceof Error) {
                setError('Something went wrong. Please try again later.');
            }

            console.log("Error: ", err);
        }

        setIsLoading(false);
    };

    const formik: FormikValues = useFormik({
        initialValues: {
          email: "",
          password: "",
        },
        validationSchema: loginValidationSchema,
        onSubmit,
    });
   
    return (
        <div className="login loginMember">
            <div className="login__container ">
                <div className='login__column login__column--form'>
                    <div className='loginForm'>
                        <LoadingWrapper loading={isLoading}>
                            <div className='loginForm__container'>
                                <div className='loginForm__title'>Authentication</div>
                                <form onSubmit={formik.handleSubmit}>
                                    <Input 
                                        type='text'
                                        placeholder='Email: example@gmail.com'
                                        className='loginForm__input loginForm__input--noRounded'
                                        label='Email'
                                        name='email'
                                        autoComplete="username"
                                        error={formik.touched.email && formik.errors.email}
                                        value={formik.values.email}
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        showRequired
                                    />

                                    <Input 
                                        type='password'
                                        placeholder='Password'
                                        className='loginForm__input loginForm__input--noRounded'
                                        label='Password'
                                        name='password'
                                        autoComplete="current-password"
                                        error={formik.touched.password && formik.errors.password}
                                        value={formik.values.password}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        showRequired
                                    />

                                    {error  && <div className='error-message'>{error}</div>}

                                    <div className='loginForm__actions'>
                                        <NavLink to='/forgot-password' className='loginForm__link'>Forgot Password?</NavLink>
                                        <Button type={'submit'} variant='red' disabled={!(formik.isValid && formik.dirty)}>Login</Button>
                                    </div>
                                </form>
                            </div>
                        </LoadingWrapper>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LoginMember;
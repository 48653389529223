import { useContext, useEffect, useState } from "react"
import { EditHeader } from "../components/edit-header/edit-header"
import { ProfileStack, MainInputs, FormTitle, SocialInputs, ButtonGroup, Submit, Form, AddLink, SocialItem, RemoveIcon, ProfileWrapper } from "../components/info/info.styles"
import { Input } from "../components/input/input"
import { UpdateProfile } from "../components/update-profile/update-profile"
import { Show } from "../../../Components/Show/Show"
import { FormikValues, useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import { UserContext } from "../../../context/UserContext"
import { toastOptions } from "../../../utils/helpers/toast.options"
import { IUser } from "../../../utils/interfaces/user.interface"
import { useDropzone } from "react-dropzone"
import { UserWarning } from "../../../Components/user-warning/user-warning"
import { UserNote } from "../../../Components/user-warning/user-note"


const validationSchema = Yup.object({
    full_name: Yup.string().required('Full name is required'),
    dob: Yup.date().required('Birthdate is required'),
    social_links: Yup.array().min(1,'minimum social link must be 1').of(
        Yup.string().required('Link should be filled')
    ),
    phone: Yup.string().required('Phone number is required'),
    state: Yup.string().required('State is required'),
    profile_photo: Yup.mixed().required('Profile photo is required'),
    id_number: Yup.string().required('RA Usarname is required'),
    email: Yup.string().required('Email is required'),
});

export const PersonalInformation = () => {
    const [ editMode, setEditMode ] = useState(false);
    const [image, setImage] = useState<any>(null)
    const person = useContext(UserContext);

    const onSubmit = async (values: FormikValues) => {
        const keys = Object.keys(values);
        let user = person?.user;
        if (!user) return;
      
        if (user?.dob) {
          const dob = user.dob.toString().split('T')[0];
          user = { ...user, dob };
        }
      
        const finalize: { [key: string]: any } = {
          user_id: user.id,
        };
      
        keys.forEach((key) => {
          if (user?.[key as keyof IUser] !== values[key]) finalize[key] = values[key];
        });
      
        const valuesCopy = Object.fromEntries(
          Object.entries(finalize)
        );
      
        const formData = new FormData();
        for (const [key, value] of Object.entries(valuesCopy)) {
          if (Array.isArray(value)) {
            value.forEach((item) => {
                formData.append(`social_links[]`, item);
            });
          } else {
            formData.append(key, value);
          }
        }
        
        try {
            const promises = [
                axios.post(`/info-change`, formData)
            ];

            if(person?.infoChange?.status === "cancelled") promises.push(
                axios.post(`/info-change/alert-accepted/${person?.infoChange?.id}`)
            )

            await Promise.all(promises)
                .then(()=>{
                    toast.success('Your information is updated', toastOptions);
                    setEditMode(false);
                    isEditAvailable();
                    resetPassValues(person?.user as IUser);
                })
                .catch((err)=>{
                    toast.error((err as any)?.response?.data?.message ?? 'Something went wrong', toastOptions);
                });
                
                } catch (err) {
                    toast.error((err as any)?.response?.data?.message ?? 'Something went wrong', toastOptions);
                }
      };

    const {getRootProps} = useDropzone({
        accept: {
            "image/*": [".png", '.jpg'],
        },
        maxFiles: 1,
        onDrop: (acceptedFiles) => {
            const file: any = acceptedFiles[0];
            formik.setFieldValue('profile_photo', file);
            setImage(URL.createObjectURL(file));
        }
    });

    const removeSocialLink = (index: number) => {
        const newLinks = formik.values.social_links.filter((_, i) => i !== index);
        formik.setFieldValue("social_links", newLinks);
    };

    const formik = useFormik({
        initialValues: {
            full_name: "",
            phone: "",
            dob: '',
            state: '',
            profile_photo: "",
            id_number: '',
            email:'',
            social_links: [""],
        },
        validationSchema,
        onSubmit,
    });

    const isEditAvailable = async () => {
        await axios.get(`info-change/check-my-info-change`).then((data)=>{
            person?.setInfoChange(data?.data);
        })
    }

    const resetPassValues = (user: IUser) => {
        formik.setFieldValue('full_name', user.full_name ?? "-");
        formik.setFieldValue('phone', user.phone ?? "-"); 
        formik.setFieldValue('dob', user.dob?.toString().split('T')[0] ?? "-");
        formik.setFieldValue('state', user.state ?? "-");
        formik.setFieldValue('profile_photo', user.profile_photo ?? "-");
        formik.setFieldValue('id_number', user.id_number ?? "-");  
        formik.setFieldValue('email', user.email ?? "-");  
        formik.setFieldValue("social_links", user.social_links ?? [""]);
    }

    useEffect(()=>{
        isEditAvailable();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    

    useEffect(()=>{
        resetPassValues(person?.user as IUser);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[person?.user]);

    console.log(formik);
    
    return(
        <ProfileWrapper>
            <UserWarning />
            <UserNote />
            <ProfileStack>
                <EditHeader
                    title="Personal information"
                    desc="This will be displayed on your profile."
                    onClick={()=> person?.infoChange?.status !== "pending" && setEditMode(true)}
                    disabled={person?.infoChange?.status === "pending"}
                    editQuote={person?.infoChange?.status === "pending" ? "" : "You have a pending request"}
                />
                <Form onSubmit={formik.handleSubmit}>
                    <Show condition={editMode}>
                        <UpdateProfile {...getRootProps()} url={person?.user?.profile_photo} localUrl={image}/>
                    </Show>
                    <MainInputs>
                        <Input 
                            label="Full name"
                            name='full_name'
                            error={formik.touched.full_name && formik.errors.full_name}
                            value={formik.values.full_name}
                            onChange={formik.handleChange}
                            readOnly={!editMode}
                        />
                        <Input 
                            label="Phone"
                            name='phone'
                            error={formik.touched.phone && formik.errors.phone}
                            value={formik.values.phone}
                            onChange={formik.handleChange}
                            readOnly={!editMode}
                        />
                        <Input 
                            label="ID Number"
                            name='id_number'
                            error={formik.touched.id_number && formik.errors.id_number}
                            value={formik.values.id_number}
                            onChange={formik.handleChange}
                            readOnly={!editMode}
                        />
                        <Input 
                            label="Email"
                            name='email'
                            error={formik.touched.email && formik.errors.email}
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            disabled
                        />
                        <Input 
                            label="State"
                            name='state'
                            error={formik.touched.state && formik.errors.state}
                            value={formik.values.state}
                            onChange={formik.handleChange}
                            readOnly={!editMode}
                        />
                        <Input 
                            label="Birthday" 
                            type='date'
                            name='dob'
                            min="1900-01-01"
                            max="9999-12-12"
                            error={formik.touched.dob && formik.errors.dob}
                            value={formik.values.dob}
                            onChange={formik.handleChange}
                            readOnly={!editMode}
                        />
                    </MainInputs>

                    <FormTitle>
                        Social links
                        <Show condition={editMode}>
                            <AddLink onClick={()=> formik.setFieldValue('social_links', [...formik.values.social_links, ""])}>
                                Add link
                            </AddLink>
                        </Show>
                    </FormTitle>
                    <SocialInputs>
                        {
                            formik.values?.social_links?.map((_, index)=>{
                                return <SocialItem key={`socials_link${index}`}>
                                    <Input
                                        label="Social Link"
                                        readOnly={!editMode}
                                        name={`social_links[${index}]`}
                                        value={formik.values.social_links[index]}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={(formik.touched?.social_links as any)?.[index] && formik.errors?.social_links?.[index]}
                                    />
                                    <Show condition={editMode && index !== 0}>
                                        <RemoveIcon onClick={removeSocialLink.bind(this,index)}/>
                                    </Show>
                                </SocialItem> 
                            })
                        }
                    </SocialInputs>

                    <ButtonGroup>
                        <Show condition={editMode}>
                            <Submit type="submit" disabled={!formik.isValid}>Confirm</Submit>
                        </Show>
                    </ButtonGroup>
                </Form>

            </ProfileStack>
        </ProfileWrapper>
    )
}
import  styledComponent from "styled-components";
import SidebarView from "./Sidebar/Sidebar.view";
import { NavLink, useNavigate } from "react-router-dom";
import { useAuthUser, useSignOut } from "react-auth-kit";
import { ToastContainer } from "react-toastify";
import { MenuIcon } from "../../layout/MobileView/MenuIcon";
import { useEffect, useState } from "react";
import clsx from "clsx";

const Container = styledComponent.div`
    width: calc(100vw - 215px);
    .content {
        padding: 0 35px;
        padding-top: 35px;
        @media (max-width: 980px) {
            padding:0 20px;
            padding-top:20px;
        }
    }
    .header {
        display: flex;
        justify-content: space-between;
        height: 100px;
        border-bottom: 1px solid #1A1919;
        color: #fff;
        align-items: center;
        padding: 0 35px;
        @media (max-width: 980px) {
            padding:0 20px;
            height:60px;
        }
        &__logout {
            cursor: pointer;
            background: url(${require("../../assets/images/logout-white.svg").default}) no-repeat left center;
            padding-left: 25px;
            text-transform: capitalize;
            &-span{
                @media (max-width: 768px) {
                    display:none;
                }
            }
        }
        &__admin{
            &-side {
                display: flex;
                align-items: center;
                gap:20px;
            }
            &-name{
                display:inline;
                @media (max-width: 768px) {
                    display:none;
                }
            }
        }
        &__menu-icon{
            display:none;
            @media (max-width: 1275px) {
                display:block;
            }
        }
    }
    .overview {
        margin-top: 55px;
        padding-bottom: 100px;
    }
   
    @media (max-width: 1275px) {
        width:100%;
    }
   
    @media (max-width: 980px) {
        .header{
            &__logout{
                background-size:contain;
                min-height:18px;
                padding-left:18px;
            }
        }
        .go-to-profile{
            font-size:14px;
            display:flex;
            align-items:center;
        }
    }
`

const StyledLayout = styledComponent.div`
    display: flex;
    transition:0.3s all ease;
    @media (max-width: 1275px) {
        &.layout--open{
            transform: translateX(260px);
        }
    }
`

const Layout: React.FC<{children: React.ReactNode}> = ({children}) => {
    const navigate = useNavigate();
    const signOut = useSignOut();
    const auth = useAuthUser()
    const [ open,setOpen ] = useState(false);
    const logout = () => {
        signOut()
        navigate('/admin/login')
    }

    useEffect(() => {
        if(open) {
            document.body.style.overflow = 'hidden';
        }
        return () => {
            document.body.style.removeProperty('overflow');
        }
    }, [open])
    
    return (
        <StyledLayout className={clsx("layout",open && "layout--open")}>
            <SidebarView open={open}/>
            <ToastContainer/>
            <Container>
                <div className="header">
                    <div className="header__admin-side">
                        <div className="header__menu-icon" onClick={() => setOpen(!open)}>
                            <MenuIcon isOpen={open} />
                        </div>
                        <div className="header__admin-name">
                            Hello, <span className="bold">{auth()?.full_name}</span>
                        </div>
                    </div>
                    <div className="flex">
                        <NavLink className={'mr-10 color-white go-to-profile'} to="/user/dashboard">Go to profile</NavLink>
                        <div onClick={logout} className="header__logout">
                            <span className="header__logout-span">logout</span>
                        </div>
                    </div>
                </div>
                <div className="content">
                    {children}
                </div>
            </Container>
        </StyledLayout>
    )
};

export default Layout;
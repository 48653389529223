import  styledComponent from "styled-components";
import { NavLink, useSearchParams, useNavigate } from 'react-router-dom';
import axios, { AxiosResponse } from "axios";
import './ActivateAccount.page.scss';
import { IUser } from "../../utils/interfaces/user.interface";
import { EUserStatus } from "../../utils/interfaces/user-status.enum";
import LoadingWrapper from "../../Views/Admin/Loading.wrapper";
import { useContext, useEffect, useState } from "react";
import { LangsContext } from "../../context/LangsContext";

const ActivateAccountStyles = styledComponent.div`
    text-align: center;
    height: calc(100vh - 100px);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    .text:nth-child(2) {
        margin-top: 30px;
        margin-bottom: 60px;
    }
`

const ActivateAccount: React.FC = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isEmailSent, setIsEmailSent] = useState<boolean>(false);

    const content = useContext(LangsContext);
    
    const getMe = async () => {
        await axios.get(`users/me`)
            .then((res: AxiosResponse<IUser>) => {
                const {data} = res;

                if(data.is_activated) {
                    if(data.status === EUserStatus.REGISTERED) {
                        navigate('/complete-registration');
                    }
                    else {
                        navigate('/user/dashboard');
                    }
                }
                else {
                    setIsLoading(false);
                }
            }).catch(() => {
                setIsLoading(false);
            })
        
        setIsLoading(false);
    }

    const sendItAgain = async () => {
        await axios.post(`users/send-activation-email`)
            .then(() => {
                setIsEmailSent(true);
            }).catch(() => {
            })
    }

    useEffect(() => {
        getMe();
        // eslint-disable-next-line
    }, [])

    return (
        <ActivateAccountStyles>
            <LoadingWrapper loading={isLoading}>
                <div className="activate-account__title text text--lg text--center text--bold">
                    {content?.YOUR_EMAIL_ACTIVATED || 'Your email successfully activated.'}
                </div>                

                <div className="text text--lg text--center">
                    {content?.PLEASE_VISIT_YOUR_INBOX_TO_VERIFY || 'Please visit your inbox to verify your email and proceed with the application.'},
                </div>

                {
                    searchParams.get('view') === 'after-registration' && <NavLink to="/login" className='loginForm__link loginForm__link--login'>{content?.LOGIN || 'Login'}</NavLink>
                }
                
                {
                    !searchParams.get('view') && !isEmailSent && (
                            <div className="text text--sm text--center">
                            {content?.EMAIL_NOT_RECEIVED || 'Didn\'t receive the email?'}
                            <br />
                            <div onClick={sendItAgain} className="activate-account__button text--underline text--center text--sm text--scale">
                                {content?.SEND_IT_AGAIN || 'Send it again'}
                            </div>
                        </div>
                    )
                }
                
                {
                    isEmailSent && (
                        <div className="text text--sm text--center">
                            {content?.EMAIL_SENT || 'Email has been sent!'}
                        </div>
                    )
                }
            </LoadingWrapper>
        </ActivateAccountStyles>
    )
}

export default ActivateAccount;
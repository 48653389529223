import './RaCodes.scss';

import Layout from "../../../Views/Admin/Layout";
import LoadingWrapper from '../../../Views/Admin/Loading.wrapper';
import Title from '../../../Components/Title/Title.component';
import { StyledButton } from '../../../Components/Button/Button.styles';
import { RaCodesBlock } from './RaCodesBlock';
import { UploadModal } from './UploadModal';
import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import axios, { AxiosResponse } from 'axios';
import { useSearchParams } from 'react-router-dom';
import { Loader } from '../../../Components/Loader/Loader.component';
import EndMessage from '../../../Components/EndMessage/EndMessage.component';
import { PermissionGuard } from '../../../Components/PermissionGuard/PermissionGuard.component';
import { PermissionsEnum } from '../../../utils/interfaces/permissions.enum';

export enum ARStatusEnum {
    NOT_USED = "not_used",
    USED = "used"   
}

const RaCodes: React.FC = () => {
    const [open, setOpen] = useState(false);
    const [invites, setInvites] = useState<any[]>([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const [status, setStatus] = useState<string | null>(searchParams.get("status"))
    const [page, setPage] = useState<number>(1);
    const [hasMore, setHasMore] = useState<boolean>(true);

    const getInvites = async (action?: 'next' | null) => {
        let url: string = `ra-invites?limit=10&page=${page}`;
        
        status && (url += `&status=${status}`)
        
        await axios.get(url)
            .then((res: AxiosResponse) => {
                const { data } = res;
                
                data.length < 10 && setHasMore(false)
                action === 'next' ? setInvites([...invites, ...data]) : setInvites(data);

                setPage(page + 1)
            })
    }

    const changeState = (status: string) => (): void => {
        const currentStatus = searchParams.get("status")
        const isSameStatus = currentStatus === status;

        if(isSameStatus) return;

        setStatus(status)
        setPage(1)
        setInvites([])
        setHasMore(true)
        setSearchParams({status})
    }

    const exportCodes = () => {
        let url: string = `ra-invites/export`;

        axios.post(url, {}, {
            responseType: 'blob'
          })
            .then((res: AxiosResponse) => {
              const url = window.URL.createObjectURL(
                new Blob([res.data], { 
                  type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' 
                })
              );
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", "ra-codes.xlsx");
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
              window.URL.revokeObjectURL(url);
            })
            .catch((error) => {
              console.error('Download failed:', error);
            });
    }

    useEffect(() => {
        getInvites();
        // eslint-disable-next-line
    }, [ searchParams ])

    return (
        <Layout>
            <LoadingWrapper loading={false}>
                <Title title={'RA Codes'} />
                <div className="ra-codes">
                    <div className="ra-codes__nav">
                        <div className="ra-codes__nav-body">
                            <div className={`ra-codes__nav-item ${status === ARStatusEnum.NOT_USED && 'active'}`} onClick={changeState(ARStatusEnum.NOT_USED)}>Active Codes</div>
                            <div className={`ra-codes__nav-item ${status === ARStatusEnum.USED && 'active'}`} onClick={changeState(ARStatusEnum.USED)}>Used Codes</div>
                        </div>
                        <PermissionGuard role={PermissionsEnum.CAN_CREATE_RA_INVITES}><>
                            {
                                status === ARStatusEnum.USED ? 
                                    <StyledButton variant="red" onClick={exportCodes} className="sm ra-codes__nowrap">Export RA Codes</StyledButton> :
                                    <StyledButton variant="red" onClick={() => setOpen(true)} className="sm ra-codes__nowrap">Add RA Codes</StyledButton>
                            }
                        </></PermissionGuard>
                    </div>

                        <InfiniteScroll
                            dataLength={invites.length} 
                            next={() => getInvites('next')}
                            hasMore={hasMore}
                            loader={<Loader />}
                            endMessage={<EndMessage />}
                        >
                            <div className="ra-codes__body">
                                {
                                    invites.map((user: any) => (
                                        <RaCodesBlock
                                            key={user.id}
                                            email={user?.user?.email}
                                            code={user.ticketLink}
                                        />
                                    ))
                                }
                            </div>
                        </InfiniteScroll>
                    </div>
                <UploadModal
                    open={open}
                    onClose={() => setOpen(false)}
                />
            </LoadingWrapper>
        </Layout>
    )
}

export default RaCodes;
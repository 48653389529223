import { Dispatch, FC, ReactNode, SetStateAction, useEffect, useState } from "react";
import { IUser } from "../../../../../utils/interfaces/user.interface";
import { Actions, ActionsEdit, Container, MemberInfo, Score, SCORE_COLORS, ButtonGroup } from "./member-frame.styles";
import { MemberAvatar } from "../../components/member-avatar/member-avatar";
import { MemberForm } from "../member-form/member-form";
import { Button } from "../../../../../Components/Button/Button.component";
import { FormikValues, useFormik } from "formik";
import * as Yup from "yup";
import { MemberHeader } from "../../components/member-header/member-header";
import axios from "axios";
import { toast } from "react-toastify";
import { toastOptions } from "../../../../../utils/helpers/toast.options";
import { DeclineModal } from "../../components/decline-modal/decline-modal";
import { useDropzone } from "react-dropzone";
import { EUserStatus } from "../../../../../utils/interfaces/user-status.enum";

const validationSchema = Yup.object({
    full_name: Yup.string().optional(),
    dob: Yup.date().optional(),
});

type MemberFrameProps = {
    user: IUser;
    score: string;
    actions: ReactNode;
    editMode?: boolean;
    setEditMode: Dispatch<SetStateAction<boolean>>;
    reviewData: IUser | null;
    setReviewData: Dispatch<SetStateAction<IUser | null>>;
    setUser: Dispatch<SetStateAction<IUser | null>>;
}

export const MemberFrame:FC<MemberFrameProps> = ({
    user, 
    score, 
    actions, 
    editMode,
    setEditMode,
    reviewData,
    setReviewData,
    setUser
}) => {
    const [ declineModal, setDeclineModal ] = useState(false);
    const clearReviewData = () => {setReviewData(null)};
    const [image, setImage] = useState<any>(null)
    
    const sentAccept = async () => {
        if(!reviewData) return;
        if(reviewData.social_links){
            reviewData.social_links = [...(user.social_links??[]), ...reviewData.social_links]
        }
        axios.post(`/info-change/action/${reviewData.id}`, { 
            "status": "completed"
        }).then(()=>{
            setUser((prev) => {
                if (!prev) return prev;
                return {
                    ...prev,
                    profile_photo: reviewData.profile_photo ?? prev.profile_photo,
                };
            })
            clearReviewData();
            toast.success(`Member was updated 🦄`, toastOptions)
        }).catch(()=>{
            toast.error(`something went wrong`, toastOptions)
        })
    }
    

    const onSubmit = async (values:FormikValues) => {
        if(!editMode) return;
        if(reviewData) return;
        const keys = Object.keys(values);

        if (user?.dob) {
            const dob = user.dob.toString().split('T')[0];
            user = { ...user, dob };
        }

        const finalize: { [key: string]: any } = {
            user_id: user.id
        };
        
        keys.forEach((key) => {
            if(user[key as keyof IUser] !== values[key]) finalize[key] = values[key];  
        })

        const formData = new FormData();
        for (const [key, value] of Object.entries(finalize)) {
          if (Array.isArray(value)) {
            value.forEach((item) => {
                formData.append(`social_links[]`, item);
            });
          } else {
            formData.append(key, value);
          }
        }
       
        await axios.post(`/info-change`, formData).then(()=>{
            toast.success('Member information is updated',toastOptions);
            setEditMode(false);
            const { user_id, ...rest } = finalize;   
            setUser((prev) => {
                if (!prev) return prev;
                return {
                    ...prev,
                    ...rest,
                };
            })
        }).catch((err)=>{
            toast.error(err?.response?.data?.message ?? 'Something went wrong',toastOptions);
        })
    }

    const {getRootProps} = useDropzone({
        accept: {
            "image/*": [".png", '.jpg'],
        },
        maxFiles: 1,
        onDrop: (acceptedFiles) => {
            const file: any = acceptedFiles[0];
            formik.setFieldValue('profile_photo', file);
            setImage(URL.createObjectURL(file));
        }
    });

    const formik = useFormik({
        initialValues: {
            full_name: "",
            phone: "",
            dob: '',
            state: '',
            profile_photo: "",
            id_number: '',
            country: '',
            email:'',
        },
        validationSchema,
        onSubmit,
    });
    
    useEffect(() => {
        formik.setFieldValue('full_name', user.full_name ?? "-");
        formik.setFieldValue('phone', user.phone ?? "-"); 
        formik.setFieldValue('dob', user.dob?.toString().split('T')[0] ?? "-");
        formik.setFieldValue('state', user.state ?? "-");
        formik.setFieldValue('profile_photo', user.profile_photo ?? "-");
        formik.setFieldValue('id_number', user.id_number ?? "-");  
        formik.setFieldValue('email', user.email ?? "-");  
        formik.setFieldValue('social_links', user.social_links ?? []);  
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[user])
    
    return(
        <Container onSubmit={formik.handleSubmit}>
            <MemberHeader
                title="Member Profile"
                editMode={editMode}
                reviewData={reviewData}
                setEditMode={setEditMode}
                member_id={user.id}
                member={user}
                allowActions={user?.status !== EUserStatus.REGISTERED}
            />

            <MemberInfo>
                <MemberAvatar 
                    {...getRootProps()}
                    url={user.profile_photo} 
                    status={user.status}
                    reviewUrl={reviewData?.profile_photo}
                    isReview={!!reviewData}
                    localUrl={image}
                    editMode={editMode}
                />
                <MemberForm 
                    user={user}
                    editMode={editMode}
                    formik={formik as any}
                    reviewData={reviewData}
                />
            </MemberInfo>
            {
                reviewData ? (
                    <ActionsEdit>
                        <ButtonGroup>
                            <Button 
                                variant='verify'
                                onClick={sentAccept}
                            >
                                Approve
                            </Button>
                            <Button 
                                variant='disabled'
                                onClick={() => setDeclineModal(true)}
                            >
                                Decline
                            </Button>

                        </ButtonGroup>
                        <Score $score={parseInt(score) as keyof typeof SCORE_COLORS}>
                            {score} Score
                        </Score>
                    </ActionsEdit>
                ) : editMode ? (
                    <ActionsEdit>
                        <ButtonGroup>
                            <Button 
                                variant='verify'
                                type="submit"
                            >
                                Accept
                            </Button>
                            <Button 
                                variant='disabled'
                                onClick={() => setEditMode(false)}
                            >
                                Cancel
                            </Button>

                        </ButtonGroup>
                        <Score $score={parseInt(score) as keyof typeof SCORE_COLORS}>
                            {score} Score
                        </Score>
                    </ActionsEdit>
                ) : (
                    <Actions>
                        {actions}
                    </Actions>
                )
            }

            <DeclineModal 
                reviewData={reviewData}
                show={declineModal}
                setShow={setDeclineModal}
                clearReviewData={clearReviewData}
            />
            
        </Container>
    );
}

import { FC } from "react";
import { PublicAvatar } from "../../Components/PublicAvatar/PublicAvatar";
import { IUser } from "../../utils/interfaces/user.interface";

export type ProfileSideProps = {
    user?: IUser;
}

export const ProfileSide:FC<ProfileSideProps> = ({user}) => {
    return user ? 
        <PublicAvatar status={user.status} url={user.profile_photo}/> 
        : null;
}
import { StyledButton } from '../Button/Button.styles';
import { NavLink } from 'react-router-dom';
import './ResidentsBlock.scss';

export interface ResidentsBlockComponentProps {
  id: string;
  name: string;
  image: string;
  country: string;
}

const ResidentsBlockComponent: React.FC<ResidentsBlockComponentProps> = ({
  name,
  id,
  image,
  country
}) => {
  return (
    <div className="userInfo residents-block">
      <NavLink to={`/admin/residents/${id}`} className="userInfoContainer residents-block__info">
        <div className="userInfo__container residents-block__container">
          {
            image && 
            <div className="userInfo__avatar userInfo__avatar--lg residents-block__avatar">
              <img
                src={`${process.env.REACT_APP_HOST}/public/residents/${image}`}
                alt="avatar"
              />
            </div>
          }
          <div className="userInfo__text residents-block__title">
            <div className="userInfo__text-text">{name} &nbsp; / &nbsp; <b className='resident-country'>{country}</b></div>
          </div>
        </div>
        <div className="userInfo__actions">
          <StyledButton variant="disabled">
            Details
          </StyledButton>
        </div>
      </NavLink>
    </div>
  );
};

export default ResidentsBlockComponent;

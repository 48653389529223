import './Podcasts.scss';

import Title from "../../../Components/Title/Title.component";
import Layout from "../../../Views/Admin/Layout"
import { useEffect, useState } from 'react';
import axios, {AxiosResponse} from 'axios';
import { NavLink, useSearchParams } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Loader } from '../../../Components/Loader/Loader.component';
import { useDebounce } from 'use-debounce';
import EndMessage from '../../../Components/EndMessage/EndMessage.component';
import { StyledButton } from '../../../Components/Button/Button.styles';
import { toast } from 'react-toastify';
import { PermissionsEnum } from '../../../utils/interfaces/permissions.enum';
import { PermissionGuard } from '../../../Components/PermissionGuard/PermissionGuard.component';
import { usePermissionGuard } from '../../../hooks/usePermissionsGuard';
import { toastOptions } from '../../../utils/helpers/toast.options';
import PodcastBlock from '../../../Components/PodcastBlock/PodcastBlock.component';
import { IPodcast } from '../../../utils/interfaces/podcasts.interface';

const PodcastsPage: React.FC = () => {
    const [podcasts, setPodcasts] = useState<IPodcast[]>([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const [status, setStatus] = useState<string | null>(searchParams.get("status"))
    const [page, setPage] = useState<number>(1);
    const [searchText, setSearchText] = useState<string | null>(null);
    const [hasMore, setHasMore] = useState<boolean>(true);
    const [value] = useDebounce(searchText, 300);
    const canSeeDetails = usePermissionGuard(PermissionsEnum.CAN_READ_PODCAST)

    const getPodcasts = async (action?: 'next' | null) => {
        let url: string = `podcasts?limit=10&page=${page}`;

        value && (url += `&search=${value}`)
        
        status && status !== 'ALL' && (url += `&status=${status}`)

        await axios.get(url)
            .then((res: AxiosResponse) => {
                const { data } = res;
                
                data.data.length < 10 && setHasMore(false)
                action === 'next' ? setPodcasts([...podcasts, ...data?.data]) : setPodcasts(data?.data);

                setPage(page + 1)
            })
    }

    const changeState = (status: string) => (): void => {
        const currentStatus = searchParams.get("status")
        const isSameStatus = currentStatus === status;

        if(isSameStatus) return;

        setStatus(status)
        setPage(1)
        setPodcasts([])
        setHasMore(true)
        setSearchParams({status})
    }

    const searching = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setPage(1)
        setSearchText(e.target.value)
    };

    useEffect(() => {
        getPodcasts();

        if(searchParams.get('action') === 'delete') {
            toast.error(`Podcast deleted 🥹`, toastOptions)

            setSearchParams({action: ''})
        }
        
        // eslint-disable-next-line
    }, [value, searchParams])
    
    return (
        <Layout>
            <PermissionGuard role={PermissionsEnum.CAN_READ_PODCASTS}>
                <>
                    <Title title={'Podcasts'}>
                        <PermissionGuard role={PermissionsEnum.CAN_CREATE_PODCAST}>
                            <NavLink to='/admin/podcasts/create'>
                                <StyledButton variant="red" className="sm">Add podcast</StyledButton>
                            </NavLink>
                        </PermissionGuard>
                    </Title>

                    <div className="navigation">
                        <div className='navigation__container'>
                            <div onClick={changeState('ALL')} className={`navigation__item ${status === 'ALL' && 'active'}`}>All Podcasts</div>
                            <div onClick={changeState('published')} className={`navigation__item ${status === 'published' && 'active'}`}>Public</div>
                            <div onClick={changeState('draft')} className={`navigation__item ${status === 'draft' && 'active'}`}>Draft</div>
                        </div>
                        <input type='text' onChange={searching} value={searchText ? searchText : ''} placeholder='Search podcasts...' className="navigation__search" />
                    </div>

                    <InfiniteScroll
                        dataLength={podcasts?.length} 
                        next={() => getPodcasts('next')}
                        hasMore={hasMore}
                        loader={<Loader />}
                        endMessage={<EndMessage />}
                    >
                        {
                            podcasts?.map((podcast: IPodcast) => (
                                <PodcastBlock
                                    key={podcast.id}
                                    id={podcast.id}
                                    title={podcast?.title || ''}
                                    image={podcast.image}
                                    canNavigate={canSeeDetails}
                                />
                            ))
                        }
                </InfiniteScroll>
                </>
            </PermissionGuard>
        </Layout>
    )
}

export default PodcastsPage;

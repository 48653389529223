import styled from "styled-components";
import BookIcon from '../../../../assets/images/book.svg';

export const Stack = styled.div`
    width:100%;
    height:100%;
    background:#0C0C0C;
    padding:30px 35px;
    @media (max-width: 1000px){
        padding: 24px 16px;
    }
`;

export const Title = styled.div`
    font-family: EuroExt;
    font-size: 21px;
    color: #fff;
    line-height: 38px;
    margin-bottom: 18px;
`;

export const Section = styled.div`
    width:100%;
    margin-top:30px;
`;

export const SectionTitle = styled.div`
    width:100%;
    font-family:Main Regular;
    font-weight:600;
    font-size: 18px;
    color:#7B7A7A;
    margin-bottom:16px;
`;

export const SectionBody = styled.div`
    width:100%;
`;

export const InviteFriendText = styled.div`
    width:100%;
    height:64px;
    display:flex;
    justify-content:center;
    align-items:center;
    text-align:center;
    font-size:14px;
    font-family: Main Regular;
    color: #fff;
`;
export const InviteFriendBox = styled.form`
    width:100%;
    display:flex;
    justify-content:center;
    align-items:flex-end;
    flex-direction:column;
    gap:15px;
`;

export const GuideLineButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    padding-left: 25px;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    background: url(${BookIcon}) no-repeat left center;
    @media (max-width: 1000px){
        font-size:12px;
        padding-left: 20px;
        color: #fff;
        background-size: 13px;
    }
`;

export const FriendsHead = styled.div`
    width:100%;
    display:flex;
    justify-content:space-between;
    align-items:center;
    font-size:26px;
    color:#fff;
    font-family: Main Regular;
    @media (max-width: 1000px){
        font-size:16px;
    }
`;

export const Line = styled.div`
    width:100%;
    height:1px;
    background: rgba(255,255,255,0.07);
    margin-top:24px;
`;

import { useState } from "react";
import { MenuIcon } from "./MenuIcon";
import { motion } from 'framer-motion';
import './MobileView.layout.scss';

const menuVariant = {
    open:{
        opacity:1,
        width:'100vw',
        height:'100vh',
        transition: {
            duration:0.3,
            when: "beforeChildren"
        }
    },
    hidden: {
        opacity:0,
        width:'0vw',
        height:'100vh',
        transition:{
            duration:0.3,
            when: "afterChildren"
        }
        
    },
}
const contentVariant = {
    open:{
        opacity: 1,
        x:0,
        transition:{
            duration:0.3,
        }
    },
    hidden:{
        opacity: 0,
        x:'-20%',
        transition:{
            duration:0.3,
        }
    }
}

type TMobileView = {
    children?: React.ReactNode,
    isMobile?: boolean;
}

export const MobileView = ({children,isMobile}:TMobileView) => {
    const [ isOpen, setIsOpen ] = useState(false);
    const modalToggle = () => {
        setIsOpen(!isOpen);
        if(!isOpen){
            window.scrollTo(0, 0)
            document.body.style.overflow = 'hidden';
        }else{
            document.body.style.removeProperty('overflow');
        }
    }
    return <>
        {isMobile && <div className="mobile-view__icon" onClick={modalToggle}>
            <MenuIcon isOpen={isOpen}/>
        </div>}

        {isMobile ? 
            <motion.ul className="mobile-view__content"
                onClick={modalToggle}
                animate={isOpen? 'open' : 'hidden'}
                initial="hidden"
                variants={menuVariant}
            >
                <motion.li className="mobile-view__inner"
                    variants={contentVariant}
                >
                    {children}
                </motion.li>
            </motion.ul>
            : <div className="mobile-view__nav">{children}</div>
            
        }
    </>
}
import { FC } from "react";
import { ISentInvites, IUser } from "../../../../utils/interfaces/user.interface";
import { Stack } from "./friend-block.styles";
import { FriendItem } from "./friend-item";

export type FriendListProps = {
    list?:ISentInvites[];
    user?:IUser;
}

export const FriendList:FC<FriendListProps> = ({list,user}) => {
    return(
        <Stack>
            {Array.isArray(list) && list.map((invite) => {
               return <FriendItem
                    key={invite.id}
                    invite={invite}
                    user={user}
               />; 
            })}
        </Stack>
    );
}
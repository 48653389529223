import { FC } from "react";
import { EUserStatus } from "../../utils/interfaces/user-status.enum"
import { Avatar } from "./PublicAvatar.styles"

export interface PublicAvatarProps {
  status:EUserStatus;
  url: string;
}


export const PublicAvatar:FC<PublicAvatarProps> = ({ status, url }) => {
    const path = process.env.REACT_APP_HOST + '/public/users/';

    return(
        <Avatar $status={status} data-status={status}>
            {
                url ? <img src={path + url} alt="profile" /> : null
            }
        </Avatar>
    )
}
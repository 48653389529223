import { IUser } from "../../utils/interfaces/user.interface";
import axios, { AxiosResponse } from "axios";
import { useContext, useEffect, useState } from "react";
import { EUserStatus } from "../../utils/interfaces/user-status.enum";
import { Outlet, useNavigate } from "react-router-dom";
import LoadingWrapper from "../../Views/Admin/Loading.wrapper";
import { SplitProfile } from "../../Components/SplitProfile/SplitProfile";
import { Sidebar } from "./view/sidebar";
import { UserContext } from "../../context/UserContext";
import { ToastContainer } from "react-toastify";
import './Profile.page.scss';

const Profile: React.FC = () => {
    const profile = useContext(UserContext);
    const [loading, setIsLoading] = useState<boolean>(true);
    const navigate = useNavigate()

    const getMe = async () => {
        await axios.get(`users/me`)
            .then((res: AxiosResponse<IUser>) => {
                const {data} = res;

                if(data.status === EUserStatus.PENDING) {
                    navigate('/verification-pending')
                }else {
                    profile?.setUser(data);
                    setIsLoading(false);
                }
            })
    }

    useEffect(() => {
        getMe();
        // eslint-disable-next-line
    }, [])

    return (
        <LoadingWrapper loading={loading} onFull>
            <ToastContainer />
            <SplitProfile
                sidebar={<Sidebar user={profile?.user}/>}
                content={<Outlet/>}
            />
            {/* <ProfileData user={user as IUser} /> */}
            {/* <ProfileInvites user={profile?.user as IUser} /> */}
        </LoadingWrapper>
    )
}

export default Profile;
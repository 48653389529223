import { useContext, useEffect, useState } from "react";
import { FriendsHead, GuideLineButton, InviteFriendBox, InviteFriendText, Line, Section, SectionBody, SectionTitle, Stack } from "../components/info/dashboard.styles"
import { UserContext } from "../../../context/UserContext";
import { FriendList } from "../components/friend-block/friend-list";
import { Button } from "../../../Components/Button/Button.component";
import { LangsContext } from "../../../context/LangsContext";
import { EUserStatus } from "../../../utils/interfaces/user-status.enum";
import { ISentInvites } from "../../../utils/interfaces/user.interface";
import LoadingWrapper from "../../../Views/Admin/Loading.wrapper";
import axios, { AxiosResponse } from "axios";
import { FormikValues, useFormik } from "formik";
import { EInvitesStatus } from "../../../utils/interfaces/invite-status.enum";
import { InviteInput } from "../components/input/input";
import { toast } from "react-toastify";
import { toastOptions } from "../../../utils/helpers/toast.options";
import PopupWrapper, { StyledPopupHeader } from "../../../Views/Admin/Popup.wrapper";
import * as Yup from 'yup';

const loginValidationSchema = Yup.object({
    email: Yup.string().email("Invalid email address").required("Email is required"),
});

export const Friends = () => {
    const profile = useContext(UserContext); 
    const [sentInvites, setSentInvites] = useState<ISentInvites[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [canInvite, setCanInvite] = useState<boolean>(false)
    const [isGuideLineOpen, setIsGuideLine] = useState<boolean>(false)

    const content = useContext(LangsContext);
    const onSubmit = async (values: any) => {
        setLoading(true)
        
        await axios.post(`invites`, {
            email: values.email
        })
        .then((res: AxiosResponse<{invite: ISentInvites}>) => {
            setSentInvites([...sentInvites, {
                email: values.email,
                status: EInvitesStatus.INVITED,
                id: res.data.invite.id,
                need_letter: false,
                letter: '',
                invite_by: profile?.user!
            }])
            profile?.user?.sent_invites.push({
                email: values.email,
                status: EInvitesStatus.INVITED,
                id: res.data.invite.id,
                need_letter: false,
                letter: '',
                invite_by: profile?.user
            })
            toast.success('Invite was sent',toastOptions);
        })
        .catch((err) => {
            toast.error(err.response.data.message ?? 'Something went wrong',toastOptions);
        })
        setLoading(false)

    }
    
    const formik: FormikValues = useFormik({
        initialValues: {
          email: "",
        },
        validationSchema: loginValidationSchema,
        onSubmit,
    });

    useEffect(() => {
        setSentInvites(profile?.user?.sent_invites?.filter((invite: ISentInvites) => invite.status === EInvitesStatus.INVITED) || [])
        
        if(!profile?.user) return;
        const canSendInvite = profile?.user?.sent_invites?.filter((invite: ISentInvites) => invite.status === EInvitesStatus.INVITED || invite.status === EInvitesStatus.REGISTERED).length < 3 && profile?.user?.can_invitation
        
        setCanInvite(canSendInvite ?? false)
        // eslint-disable-next-line
    }, [])

    return(
        <Stack>
            <FriendsHead>
                Invite your friend

                <GuideLineButton 
                    onClick={() => setIsGuideLine(true)}
                >
                    {content?.GUIDELINE_POPUP_TITLE ? content?.GUIDELINE_POPUP_TITLE : 'Guideline'}
                </GuideLineButton>
            </FriendsHead>
            
            <Section>
                <SectionBody>
                {
                    profile?.user?.can_invitation && sentInvites.length < 3 && canInvite && profile?.user?.status === EUserStatus.VERIFIED && (
                        <LoadingWrapper loading={loading}>
                            <InviteFriendBox onSubmit={formik.handleSubmit}>
                                <InviteInput 
                                    type='text'
                                    placeholder='example@gmail.com'
                                    label='Email'
                                    name='email'
                                    error={formik.touched.email && formik.errors.email}
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    isLight
                                />
                                <Button type={'submit'} variant='red'>
                                    {content?.INVITE || 'Invite'}
                                </Button>
                            </InviteFriendBox>
                        </LoadingWrapper>
                    )
                }

                {
                    (sentInvites.length >= 3 || !canInvite) && profile?.user?.can_invitation && (
                        <InviteFriendText>
                            {content?.INVITE_AGAIN_INFO || 'You can invite again after previous invitations have been reviewed!'}
                        </InviteFriendText>
                    )
                }

                {
                    (!profile?.user?.can_invitation || profile?.user?.status !== EUserStatus.VERIFIED) && (
                        <InviteFriendText>
                            {content?.NO_PERMISSION_TO_INVITE || 'You don’t have permission to invite friends'}
                        </InviteFriendText>
                    )
                }

                </SectionBody>
            </Section>
            <Line />
            <Section>
                <SectionTitle>Friends</SectionTitle>
                <SectionBody>
                    <FriendList
                        list={profile?.user?.sent_invites}
                        user={profile?.user}
                    />
                </SectionBody>
            </Section>

            <PopupWrapper isopen={isGuideLineOpen} view='guideline'>
                <div className="archive">
                    <StyledPopupHeader>
                        <div className="popup-title">{content?.GUIDELINE_POPUP_TITLE ? content?.GUIDELINE_POPUP_TITLE : 'Guideline'}</div>
                        <button className="close" onClick={() => setIsGuideLine(!isGuideLineOpen)}></button>
                    </StyledPopupHeader>

                    <div className="text text--readable text--white" dangerouslySetInnerHTML={{
                        __html: content?.['USER_GUIDELINE'] ? content['USER_GUIDELINE'].replaceAll('\r','</br>').replaceAll('\n', '<br />') : ''
                    }}></div>
                </div>
            </PopupWrapper>
        </Stack>
    )
}
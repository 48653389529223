import * as React from "react";

const ProfileIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="25"
    viewBox="0 0 24 25"
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M3 20.548c2.336-2.478 5.507-4 9-4s6.664 1.522 9 4m-4.5-12.5a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0"
    ></path>
  </svg>
);

export default ProfileIcon;

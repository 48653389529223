import './MembersProfile.scss';
import "react-toggle/style.css" 
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios, { AxiosResponse } from 'axios';
import { INote, IUser } from '../../../utils/interfaces/user.interface';
import { countScoreAverage } from '../../../utils/helpers/score-avarage.helper';
import RequestComponent from '../../../Components/RequestComponent/RequestComponent';
import { EUserStatus } from '../../../utils/interfaces/user-status.enum';
import { StyledButton } from '../../../Components/Button/Button.styles';
import { Button } from '../../../Components/Button/Button.component';
import LoadingWrapper from '../../../Views/Admin/Loading.wrapper';
import Layout from '../../../Views/Admin/Layout';
import ConfirmPopup from '../../../Components/ConfirmPopup/ConfirmPopup';
import NotesView from '../../../Views/Admin/Notes/Notes.view';
import LetterPopup from '../../../Components/LetterPopup/LetterPopup';
import { PermissionGuard } from '../../../Components/PermissionGuard/PermissionGuard.component';
import { PermissionsEnum } from '../../../utils/interfaces/permissions.enum';
import { usePermissionGuard } from '../../../hooks/usePermissionsGuard';
import { RaButton } from './RaCodeButton';
import { toast } from 'react-toastify';
import { toastOptions } from '../../../utils/helpers/toast.options';
import { MemberFrame } from './view/member-frame/member-frame';

const MembersProfilePage: React.FC = () => {
    const [ editMode, setEditMode ] = useState(false);

    const {member_id} = useParams<{member_id: string}>();
    const [user, setUser] = useState<IUser | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [raLoading, setRaLoading] = useState<boolean>(false);
    const [score, setScore] = useState<string>('5');
    const hasDetailViewPermission = usePermissionGuard(PermissionsEnum.SEE_MEMBER_DETAILS);
    const [reviewData, setReviewData] = useState<IUser | null>(null);

    const [popupText, setPopupText] = useState<string>('');
    const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
    const [popupAction, setPopupAction] = useState<EUserStatus | null>();

    const [isLetterPopupOpen, setIsLetterPopupOpen] = useState<boolean>(false)

    const getUserInfo = async (): Promise<void> => {
        await axios.get(`users/${member_id}`)
            .then((res: AxiosResponse) => {
                const {data} = res;
                
                setUser(data);
                setScore(countScoreAverage(data.notes));
                setIsLoading(false)
            })
    }

    const getReviewData = async () => {
        await axios.get(`info-change/is-available-on-change/${member_id}`).then((data)=>{
            setReviewData(data.data);
        })
    }
    
    const handleNoteAdded = (note: INote): void => {
        if(user) {
            user?.notes.push(note)
            setUser(user)
            setScore(countScoreAverage(user.notes))
        }
    };

    const handleStatusChange =  async (status: EUserStatus): Promise<void> => {
        if(user) {
            await axios.put(`users/${user.id}`, {
                status
            }).then(() => {
                getUserInfo();
            })
        }
    }

    const onPopupAction = (action: boolean): void => {
        if(action) {
            handleStatusChange(popupAction as EUserStatus)
        }
        setIsPopupOpen(false)
    }

    const askForLetter = async (): Promise<void> => {
        if(user) {
            await axios.post(`invites/ask-letter/${user?.invite?.id}`)
                .then(() => {
                    getUserInfo()
                })
        }
    }
    
    let ProfileActions: JSX.Element | null = null;
    
    useEffect(()=>{
        getReviewData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    if(user) {
        ProfileActions = {
            [EUserStatus.PENDING]: (
                <>
                    <PermissionGuard role={PermissionsEnum.CAN_VERIFY_MEMBER}>
                        <Button 
                            variant='verify'
                            onClick={() => {
                                setPopupText('Are you sure you want to verify this user?')
                                setPopupAction(EUserStatus.VERIFIED)
                                setIsPopupOpen(true)
                            }}
                        >
                            Verify
                        </Button>
                    </PermissionGuard>
                            
                    <PermissionGuard role={PermissionsEnum.CAN_DISABLE_MEMBER}>
                        <Button 
                            variant='disabled'
                            onClick={() => {
                                setPopupText('Are you sure you want to disable this user?')
                                setPopupAction(EUserStatus.DISABLED)
                                setIsPopupOpen(true)
                            }}
                        >
                            Disable
                        </Button>
                    </PermissionGuard>

                    <PermissionGuard role={PermissionsEnum.CAN_BAN_MEMBER}>
                        <Button 
                            variant='unban'
                            onClick={() => {
                                setPopupText('Are you sure you want to ban this user?')
                                setPopupAction(EUserStatus.BLOCKED)
                                setIsPopupOpen(true)
                            }}
                        >
                            Ban
                        </Button>
                    </PermissionGuard>
                </>
            ),
            [EUserStatus.VERIFIED]: (
                <>
                    <PermissionGuard role={PermissionsEnum.CAN_UNVERIFY_MEMBER}>
                        <Button 
                            variant='verified'
                            onClick={() => {
                                setPopupText('Are you sure you want to unverify this user?')
                                setPopupAction(EUserStatus.UNVERIFIED)
                                setIsPopupOpen(true)
                            }}
                        >
                            Unverify
                        </Button>
                    </PermissionGuard>

                    <PermissionGuard role={PermissionsEnum.CAN_DISABLE_MEMBER}>
                        <Button 
                            variant='disabled'
                            onClick={() => {
                                setPopupText('Are you sure you want to disable this user?')
                                setPopupAction(EUserStatus.DISABLED)
                                setIsPopupOpen(true)
                            }}
                        >
                            Disable
                        </Button>
                    </PermissionGuard>

                    <PermissionGuard role={PermissionsEnum.CAN_BAN_MEMBER}>
                        <Button 
                            variant='unban'
                            onClick={() => {
                                setPopupText('Are you sure you want to ban this user?')
                                setPopupAction(EUserStatus.BLOCKED)
                                setIsPopupOpen(true)
                            }}
                        >
                            Ban
                        </Button>
                    </PermissionGuard>
                </>
            ),
            [EUserStatus.UNVERIFIED]: (
                <>
                    <PermissionGuard role={PermissionsEnum.CAN_VERIFY_MEMBER}>
                        <Button 
                            variant='verify'
                            onClick={() => {
                                setPopupText('Are you sure you want to verify this user?')
                                setPopupAction(EUserStatus.VERIFIED)
                                setIsPopupOpen(true)
                            }}
                        >
                            Verify
                        </Button>
                    </PermissionGuard>

                    <PermissionGuard role={PermissionsEnum.CAN_DISABLE_MEMBER}>
                        <Button 
                            variant='disabled'
                            onClick={() => {
                                setPopupText('Are you sure you want to disable this user?')
                                setPopupAction(EUserStatus.DISABLED)
                                setIsPopupOpen(true)
                            }}
                        >
                            Disable
                        </Button>
                    </PermissionGuard>

                    <PermissionGuard role={PermissionsEnum.CAN_BAN_MEMBER}>
                        <Button 
                            variant='unban'
                            onClick={() => {
                                setPopupText('Are you sure you want to ban this user?')
                                setPopupAction(EUserStatus.BLOCKED)
                                setIsPopupOpen(true)
                            }}
                        >
                            Ban
                        </Button>
                    </PermissionGuard>
                </>
            ),
            [EUserStatus.DISABLED]: (
                <>
                    <PermissionGuard role={PermissionsEnum.CAN_DISABLE_MEMBER}>
                        <Button 
                            variant='disabled'
                            onClick={() => {
                                setPopupText('Are you sure you want to enable this user?')
                                setPopupAction(EUserStatus.PENDING)
                                setIsPopupOpen(true)
                            }}
                        >
                            Enable
                        </Button>
                    </PermissionGuard>

                    <PermissionGuard role={PermissionsEnum.CAN_BAN_MEMBER}>
                        <Button 
                            variant='unban'
                            onClick={() => {
                                setPopupText('Are you sure you want to ban this user?')
                                setPopupAction(EUserStatus.BLOCKED)
                                setIsPopupOpen(true)
                            }}
                        >
                            Ban
                        </Button>
                    </PermissionGuard>
                </>
            ),
            [EUserStatus.BLOCKED]: (
                <>
                    <PermissionGuard role={PermissionsEnum.CAN_UNBAN_MEMBER}>
                        <Button 
                            variant='unban'
                            onClick={() => {
                                setPopupText('Are you sure you want to unban this user?')
                                setPopupAction(EUserStatus.UNVERIFIED)
                                setIsPopupOpen(true)
                            }}
                        >
                            Unban
                        </Button>
                    </PermissionGuard>
                </>
            ),
            [EUserStatus.REGISTERED]: (<></>)
        }[user.status];
    }
    
    useEffect(() => {
        hasDetailViewPermission && getUserInfo();
        // eslint-disable-next-line
    }, [member_id])

    const sendRaInvite = async (e: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
        e.preventDefault();
        if(user) {
            setRaLoading(true);
            await axios.post(`ra-invites/assign`,{
                userId: user.id
            })
                .then(() => {
                    toast.success('RA invite sent',toastOptions);
                    getUserInfo();
                })
                .catch(() => {

                    toast.error('Error while sending RA invite',toastOptions)
                })
                
                setTimeout(()=> setRaLoading(false),800);
        }
    }
    
    return (
        <Layout>
            <ConfirmPopup 
                isopen={isPopupOpen} 
                onAction={onPopupAction}
                text={popupText} 
                label='Status change'
            />
            <LoadingWrapper loading={isLoading}>
                <div className="membersProfile">
                    {
                        editMode || reviewData ? <div className="membersProfile__overlay"></div> : null
                    }
                    <div className="membersProfile-info">
                        {user && 
                            <MemberFrame 
                                user={user} 
                                setUser={setUser} 
                                score={score} 
                                editMode={editMode}
                                setEditMode={setEditMode}
                                reviewData={reviewData}
                                setReviewData={setReviewData}
                                actions={<>
                                    <div className='membersProfile__actions-btns'>
                                        <PermissionGuard role={PermissionsEnum.MEMBER_STATUS_CHANGE}>
                                            <>{ProfileActions}</>
                                        </PermissionGuard>
                                    </div>


                                    <PermissionGuard role={PermissionsEnum.SHOW_SCORE}> 
                                        <div className={`membersProfile-score membersProfile-score--${parseInt(score)}`}>{score} Score</div>
                                    </PermissionGuard>

                                    <PermissionGuard role={PermissionsEnum.CAN_ASSIGN_RA_TICKET}>
                                        <RaButton onClick={sendRaInvite} disabled={!!user?.ra_invite} loading={raLoading}>
                                            {user?.ra_invite ? 'RA invite sent' : 'Send RA invite'}
                                        </RaButton>
                                    </PermissionGuard>
                                </>}
                            />}
                            
                        {/* {user && <MemberInfo user={user} />} */}

                        {/* <div className='membersProfile__actions'>
                            <div className='membersProfile__actions-btns'>
                                <PermissionGuard role={PermissionsEnum.MEMBER_STATUS_CHANGE}>
                                    <>{ProfileActions}</>
                                </PermissionGuard>
                            </div>


                            <PermissionGuard role={PermissionsEnum.SHOW_SCORE}> 
                                <div className={`membersProfile-score membersProfile-score--${parseInt(score)}`}>{score} Score</div>
                            </PermissionGuard>

                            <PermissionGuard role={PermissionsEnum.CAN_ASSIGN_RA_TICKET}>
                                <RaButton onClick={sendRaInvite} disabled={!!user?.ra_invite} loading={raLoading}>
                                    {user?.ra_invite ? 'RA invite sent' : 'Send RA invite'}
                                </RaButton>
                            </PermissionGuard>
                        </div> */}

                        <div className='membersProfile__actions'>
                            <div className='membersProfile__actions-btns membersProfile__actions-requested'>
                                {user && user.invited_by && <RequestComponent text={user.invited_by.full_name} image={`${process.env.REACT_APP_HOST}/public/users/${user.invited_by.profile_photo}`}/>}
                                {user && <RequestComponent text={user.created_at}/>}
                            </div>
                            
                            {user?.invite && user.status !== EUserStatus.REGISTERED && (
                                <>
                                    {user.invite.need_letter && user.invite.letter && (
                                        <StyledButton onClick={() => setIsLetterPopupOpen(true)} variant='purple'>
                                            SHOW LETTER
                                        </StyledButton>
                                    )}

                                    {user.invite.need_letter && !user.invite.letter && (
                                        <StyledButton variant='stroked'>
                                            Request sent
                                        </StyledButton>
                                    )}

                                    {!user.invite.need_letter && (
                                        <PermissionGuard role={PermissionsEnum.ASK_FOR_LETTER}>
                                            <div className='ask-for-letter' onClick={askForLetter}>
                                                Ask for letter
                                            </div>
                                        </PermissionGuard>
                                    )}
                                </>
                            )}

                            {
                                user?.invite && (
                                    <LetterPopup
                                        isopen={isLetterPopupOpen}
                                        text={user?.invite.letter}
                                        user={user}
                                        onClose={() => setIsLetterPopupOpen(!isLetterPopupOpen)}
                                    />
                                )
                            }
                            
                        </div>
                    </div>

                    {user && <NotesView onNoteAdded={handleNoteAdded} user={user} />}
                </div>
            </LoadingWrapper>
        </Layout>
    )
}

export default MembersProfilePage;
import { FC, useEffect, useState } from "react";
import { IUser } from "../../../../../utils/interfaces/user.interface";
import { MemberInput, MemberInputCustom } from "../../components/member-input/member-input";
import { Form } from "./member-form.style";
import { EUserStatus } from "../../../../../utils/interfaces/user-status.enum";
import { PermissionGuard } from "../../../../../Components/PermissionGuard/PermissionGuard.component";
import { PermissionsEnum } from "../../../../../utils/interfaces/permissions.enum";
import Toggle from "react-toggle";
import axios from "axios";
import { toast } from "react-toastify";
import { toastOptions } from "../../../../../utils/helpers/toast.options";
import { usePermissionGuard } from "../../../../../hooks/usePermissionsGuard";
import { InputContainer } from "../../../../../Components/Input/Input.styles";
import ConfirmPopup from "../../../../../Components/ConfirmPopup/ConfirmPopup";
import { IUserRoles } from "../../../UserRoles/UserRoles.page";
import Select from "react-select";
import { FormikProps, FormikValues } from "formik";
import { SocialList } from "../../../../../Components/SocialList/SocialList";

export type MemberFormProps = {
  user: IUser;
  formik: FormikProps<FormikValues>;
  editMode?: boolean;
  reviewData: IUser | null;
}

export const MemberForm:FC<MemberFormProps> = ({user, formik, editMode,reviewData}) => {
    const [isToggleDisabled, setIsToggleDisabled] = useState<boolean>(false);
    const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false)
    const [roles, setRoles] = useState<{value: string, label: string}[]>([])
    const [isIdentityVerifiedToggleDisabled, setIsIdentityVerifiedToggleDisabled] = useState<boolean>(user?.is_identity_verified || false);
    const hasRoleViewPermission = usePermissionGuard(PermissionsEnum.SHOW_ALL_ROLES);
    const [role, setRole] = useState<{value: string, label: string}>({value: '', label: ''})
    const hasShowMemberRolePermission = usePermissionGuard(PermissionsEnum.SHOW_MEMBER_ROLE);
    const [selectValue, setSelectValue] = useState<{value: string, label: string}>(user.role ? {value: user.role.id, label: user.role.name} : {value: '', label: ''})

    const handleInvitationToggle = async (): Promise<void> => {
      setIsToggleDisabled(true)
  
      user.can_invitation = !user.can_invitation
  
      await axios.put(`users/${user.id}`, { 
          can_invitation: String(user.can_invitation)
        })
        .then(() => {
          setIsToggleDisabled(false)
          if(user.can_invitation) {
            toast.success(`Friend invitation enabled 🦄`, toastOptions)
          }
          else {
            toast.warning(`Friend invitation disabled 🦄`, toastOptions)
          }
        })
    }

    const handleInvitationOnHold = async (): Promise<void> => {
      setIsToggleDisabled(true)

      user.on_hold = !user.on_hold

      await axios.put(`users/${user.id}`, { 
          on_hold: String(user.on_hold)
        })
        .then(() => {
          setIsToggleDisabled(false)
          if(user.on_hold) {
            toast.success(`On hold enabled 🦄`, toastOptions)
          }
          else {
            toast.warning(`On hold disabled 🦄`, toastOptions)
          }
        })
    }

    const handleIsIdentityVerified = async (): Promise<void> => {
        await axios.post(`users/verify-identity`, { 
          user_id: user.id
        })
        .then(() => {
          setIsIdentityVerifiedToggleDisabled(true)
          toast.success(`Identity verified 🦄`, toastOptions)
        })
    }

    const onPopupAction = async (action: boolean) => {
        if(action) {
          setIsPopupOpen(false)
          
          await axios.put(`users/assign-role/${user.id}`, { 
            role: role.value
          })
    
          setSelectValue(role)
    
          toast.success(`Role changed🕺`, toastOptions)
        }
        else {
          setIsPopupOpen(false)
        }
    }
    
    const getRoles = () => {
        axios.get('roles?page=1&limit=100')
          .then((response) => {
            let data = response.data.map((role: IUserRoles) => {
              return {
                value: role.id,
                label: role.name
              }
            });
    
          setRoles(data)
        })
      }

    useEffect(() => {
        if(hasRoleViewPermission) getRoles()
    }, [hasRoleViewPermission])

    return <Form>
        <MemberInput 
            label="Full name" 
            name='full_name'
            error={formik.touched.full_name && formik.errors.full_name}
            value={formik.values.full_name}
            onChange={formik.handleChange}
            readOnly={!editMode}
            newValue={reviewData?.full_name}
        />
        <MemberInput 
            label="Email" 
            name='email'
            error={formik.touched.email && formik.errors.email}
            value={formik.values.email}
            onChange={formik.handleChange}
            readOnly={!editMode}
            newValue={reviewData?.email}
            disabled
        />
        <MemberInput 
            label="State" 
            name='state'
            error={formik.touched.state && formik.errors.state}
            value={formik.values.state}
            onChange={formik.handleChange}
            readOnly={!editMode}
            newValue={reviewData?.state}
        />
        <MemberInput 
            label="ID Number" 
            name='id_number'
            error={formik.touched.id_number && formik.errors.id_number}
            value={formik.values.id_number}
            onChange={formik.handleChange}
            readOnly={!editMode}
            newValue={reviewData?.id_number}
        />
        <MemberInput 
            label="Phone" 
            name='phone'
            error={formik.touched.phone && formik.errors.phone}
            value={formik.values.phone}
            onChange={formik.handleChange}
            readOnly={!editMode}
            newValue={reviewData?.phone}
        />
        <MemberInput 
            label="Birthday" 
            type='date'
            name='dob'
            min="1900-01-01"
            max="9999-12-12"
            error={formik.touched.dob && formik.errors.dob}
            value={formik.values.dob}
            onChange={formik.handleChange}
            readOnly={!editMode}
            newValue={reviewData?.dob?.toString().split('T')[0]}
        />

        <MemberInputCustom 
          label="Social Links"
          newValue={reviewData?.social_links?.join('')}
        >
          <SocialList 
            list={formik?.values?.social_links || []}
            reviewList={reviewData?.social_links || []}
            editMode={editMode}
            formik={formik}
          />
        </MemberInputCustom>

        {
            user.status === EUserStatus.VERIFIED && (
              <PermissionGuard role={PermissionsEnum.FRIEND_INVITATION}>
                <MemberInputCustom label="Friend Invitation">
                    <Toggle
                        defaultChecked={user.can_invitation ? true : false}
                        onChange={handleInvitationToggle} 
                        disabled={isToggleDisabled}
                      />
                </MemberInputCustom>
              </PermissionGuard>
            )
        }

        {
            user.status === EUserStatus.VERIFIED && (
                <PermissionGuard role={PermissionsEnum.CAN_VERIFY_IDENTITY}>
                    <MemberInputCustom label="Is identity verified">
                        <Toggle
                            defaultChecked={isIdentityVerifiedToggleDisabled}
                            onChange={handleIsIdentityVerified} 
                            disabled={isIdentityVerifiedToggleDisabled}
                        />
                    </MemberInputCustom>
                </PermissionGuard>
            )
        }


        {
          user.status === EUserStatus.PENDING && (
            <PermissionGuard role={PermissionsEnum.MEMBER_STATUS_CHANGE}>
              <MemberInputCustom label="On Hold">
                  <Toggle
                      defaultChecked={user.on_hold ? true : false}
                      onChange={handleInvitationOnHold} 
                      disabled={isToggleDisabled}
                    />
              </MemberInputCustom>
            </PermissionGuard>
          )
        }

        <InputContainer>
            {
                (hasShowMemberRolePermission || hasRoleViewPermission) && <label className='member-role-select' htmlFor='member-role-select'>Role</label>
            }
            {
                (hasShowMemberRolePermission && !hasRoleViewPermission) && <div style={{ color: '#fff' }}>{user?.role?.name}</div>
            }
            
            <ConfirmPopup 
                isopen={isPopupOpen} 
                onAction={onPopupAction}
                text={'Are you sure you want to change the role of this user?'} 
                label='Role change'
            />
            {
                hasRoleViewPermission && 
                    <Select 
                        options={roles} 
                        onChange={(data) => {
                            setIsPopupOpen(true)

                            if(data) {
                            setRole(data)
                            }
                        }}
                        value={selectValue}

                        id="member-role-select"
                        styles={{
                            control: (baseStyles) => ({
                                ...baseStyles,
                                backgroundColor: 'transparent',
                                height: '40px',
                                borderColor: '#272727',
                                borderRadius: '6px',
                                color: '#FFF',
                            }),
                            singleValue: (baseStyles) => ({
                                ...baseStyles,
                                color: '#FFF',
                                outline: 'none'
                            }),
                            input: (baseStyles) => ({
                                ...baseStyles,
                                color: '#FFF'
                            }),
                    }}
                />
            }
            
        </InputContainer>
    </Form>;
}
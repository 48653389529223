import styled from "styled-components";

export const SCORE_COLORS = {
    1: '#ff5555',
    2: '#ff7b51',
    3: '#ffc42e',
    4: '#b1ff32',
    5: '#19fe14',
}
export const Container = styled.form`
    width: 100%;
    position:relative;
    z-index:101;
`;

export const MemberInfo = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-start;
    gap: 30px;
    @media (max-width: 768px) {
        flex-direction:column;
    }
`;

export const Actions = styled.div`
    width:100%;
    display: flex;
    margin: 25px 0;
    padding-top: 25px;
    justify-content: space-between;
    border-top: 1px solid #292929;
    @media (max-width: 768px) {
        flex-wrap: wrap;
        gap: 20px;
    }   
`;

export const ActionsEdit = styled(Actions)`
    @media (max-width: 768px) {
        width:100%;
        flex-direction:column;
    } 
`;

export const ButtonGroup = styled.div`
    display:flex;
    align-items:center;
    gap: 16px;
    @media (max-width: 768px) {
        width:100%;
        justify-content: space-between;
    } 
`;

export const Score = styled.div<{$score: keyof typeof SCORE_COLORS}>`
    border: 1px solid ${({$score}) => SCORE_COLORS[$score]};
    color: ${({$score}) => SCORE_COLORS[$score]};
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    border-radius: 40px;
    padding: 0 20px;
    font-family: "Main Bold", sans-serif;
    font-size: 16px;
    text-transform: uppercase;
    flex-shrink: 0;
    white-space: nowrap;
`;

import { ReactComponent as ProfileIcon } from '../../assets/images/profile-icon.svg'
import { NavLink, useLocation } from 'react-router-dom';
import { useAuthUser, useIsAuthenticated } from 'react-auth-kit';
import { EUserStatus } from '../../utils/interfaces/user-status.enum';
import './HeaderNew.scss';
// import { Avatar } from '../../Components/Avatar/Avatar';
import { useBreakpoint } from '../../hooks/useBreakpoint';
import { MobileView } from '../../layout/MobileView/MobileView.layout';
import { ProfileIconComponent } from '../../Components/ProfileIcon/ProfileIcon.component';
import { afterLoginRoute } from '../../utils/helpers/page-route';


const HeaderNew = () => {
    const {width} = useBreakpoint();
    const { pathname } = useLocation();
    const isUpcomingPage = pathname.endsWith('/upcoming') || pathname === '/';
    const isAuthenticated: () => boolean  = useIsAuthenticated();
    const auth = useAuthUser();
    
    const renderAuthenticatedNavLinks = (): React.ReactNode => {
        const user = auth();
        const isActivated = user?.is_activated;
        const userStatus = user?.status;
        const userRole = user?.role
        
        if (isActivated && (userRole.name === 'MEMBER')) {
            if (userStatus !== EUserStatus.PENDING && userStatus !== EUserStatus.REGISTERED) {
                return (
                    <div className="public-header__profile">
                        <NavLink to={'/user/dashboard'} className='public-header__nav-link public-header__nav-link--nospace'>
                            {/* <Avatar url={user?.profile_photo} name={user?.full_name}/> */}
                            <ProfileIconComponent name={user?.full_name || ''} />
                        </NavLink>
                    </div>
                );
            }
            if (userStatus === EUserStatus.PENDING) {
                return (
                    <div className="public-header__profile">
                        <NavLink to={'/verification-pending'} className='public-header__nav-link public-header__nav-link--nospace'>
                            {/* <Avatar url={user?.profile_photo} name={user?.full_name}/> */}
                            <ProfileIconComponent name={user?.full_name || ''} />
                        </NavLink>
                    </div>
                );
            }
            if (userStatus === EUserStatus.REGISTERED) {
                return (
                    <div className="public-header__profile">
                        <NavLink to={'/complete-registration'} className='public-header__nav-link public-header__nav-link--nospace'>
                            {/* <Avatar url={user?.profile_photo} name={user?.full_name}/> */}
                            <ProfileIconComponent name={user?.full_name || ''} />
                        </NavLink>
                    </div>
                );
            }
        }

        if (isAuthenticated() && !isActivated  && userRole.name === 'MEMBER') {
            return (
                <div className="public-header__profile">
                    <NavLink to={'/activate-account'} className='public-header__nav-link public-header__nav-link--nospace'>
                        {/* <Avatar url={user?.profile_photo} name={user?.full_name}/> */}
                        <ProfileIconComponent name={user?.full_name || ''} />
                    </NavLink>
                </div>
            );
        }

        if(isAuthenticated() && userRole !== 'MEMBER') {
            return (
                <div className="public-header__profile">
                    <NavLink to={afterLoginRoute(userRole.permissions)} className='public-header__nav-link public-header__nav-link--nospace'>
                        {/* <Avatar url={user?.profile_photo} name={user?.full_name}/> */}
                        <ProfileIconComponent name={user?.full_name || ''} />
                    </NavLink>
                </div>
            );
        }

        return null;
    };

    const renderAuthLinks = () => {
        if (!isAuthenticated()) {
            if (pathname.includes('/login')) {
                return (
                    <NavLink 
                        to="/login" 
                        className='public-header__nav-link'>
                            <ProfileIcon />
                            {/* Sign up */}
                    </NavLink>
                );
            }
            return(
                <NavLink 
                    to="/login" 
                    className='public-header__nav-link'>
                        <ProfileIcon />
                        {/* LOGIN */}
                </NavLink>
            )
        }

        return null;
    };
    
    return <header className="public-header">
        <NavLink to="/" className="public-header__logo">
            <svg xmlns="http://www.w3.org/2000/svg" width="182" height="37" viewBox="0 0 182 37" fill="none">
                <path d="M1.66357 19.7654H13.1639V26.2795H27.7606V23.58L40.0359 33.2903V36.3872H13.0542L1.66435 26.2801L1.66357 19.7654Z" fill="white" stroke="white" strokeMiterlimit="10"/>
                <path d="M13.1639 11.1741L13.0533 13.6069L1 4.0969V1H28.0923L39.2605 11.1733V17.587H27.871L27.7355 11.1907" fill="white"/>
                <path d="M13.1639 11.1741L13.0533 13.6069L1 4.0969V1H28.0923L39.2605 11.1733V17.587H27.871L27.7355 11.1907" stroke="white" strokeMiterlimit="10"/>
                <path d="M170.998 13.6056L171.001 29.3843L175.46 29.3608L175.456 13.5821H180.996L181 11.1611L163.907 11.1739L168.873 13.5934" fill="white"/>
                <path d="M170.998 13.6056L171.001 29.3843L175.46 29.3608L175.456 13.5821H180.996L181 11.1611L163.907 11.1739L168.873 13.5934" stroke="white" strokeMiterlimit="10"/>
                <path d="M151.562 11.1862L160.002 11.1726L164.424 13.3451L164.664 29.3671H160.431L160.421 13.5822L155.919 13.6031L155.93 29.388L151.562 29.3775V11.1862Z" fill="white" stroke="white" strokeMiterlimit="10"/>
                <path d="M149.921 11.1765H137.143V29.3679H149.92V27.1302H141.922V21.5945H149.921V19.2312H141.922V13.5933H149.921V11.1765Z" fill="white" stroke="white" strokeMiterlimit="10"/>
                <path d="M114.518 11.0967V29.3833L118.316 29.3907V13.6352H122.064V29.1483L125.93 29.1398L126.131 13.6359H129.925L129.723 29.3829L133.819 29.3854V11.0967H114.518Z" fill="white" stroke="white" strokeMiterlimit="10"/>
                <path d="M112.133 11.2183H99.3557V29.321L112.133 29.3257V27.172H104.134V21.6362H112.133V19.2729H104.134V13.635H112.133L112.133 11.2183Z" fill="white" stroke="white" strokeMiterlimit="10"/>
                <path d="M97.5005 11.2183H84.6846V21.636H93.1931V27.1718H84.6846V29.3255H97.5005V19.2729H89.1669V13.635H97.5007L97.5005 11.2183Z" fill="white" stroke="white" strokeMiterlimit="10"/>
                <path d="M69.9478 11.2183V29.3257H74.3415V21.6359H78.6504V29.3257H82.8297V11.2183H69.9478ZM78.6504 19.2729H74.3415L74.2327 13.635H78.542L78.6504 19.2729Z" fill="white" stroke="white" strokeMiterlimit="10"/>
                <path d="M52.0869 11.1892V29.292H67.6537V19.3542H65.6223V11.1892H52.0869ZM56.643 13.6056H61.4576V19.3542H56.643V13.6056ZM64.0562 27.253H56.5836V21.522H64.0562V27.253Z" fill="white" stroke="white" strokeMiterlimit="10"/>
            </svg>
        </NavLink>
        <MobileView
            isMobile={width <= 1100}
        >
            {!isUpcomingPage ?
                <NavLink 
                    to="/" 
                    className='public-header__nav-link'>
                        UPCOMING
                </NavLink> :
                <NavLink 
                    to="/past" 
                    className='public-header__nav-link'>
                        PAST
                </NavLink>
            }
            <NavLink 
                to="/residents"
                className='public-header__nav-link'>
                    RESIDENTS
            </NavLink>
            <NavLink 
                to="/podcasts"
                className='public-header__nav-link'>
                PODCASTS
            </NavLink>
            <NavLink 
                to="/house-rules" 
                className='public-header__nav-link'>
                    HOUSE RULES
            </NavLink>
            {renderAuthenticatedNavLinks()}
            {renderAuthLinks()}
        </MobileView>
    </header>
}

export default HeaderNew;
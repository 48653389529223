import InfiniteScroll from "react-infinite-scroll-component"
import Title from "../../../Components/Title/Title.component"
import Layout from "../../../Views/Admin/Layout"
import { useEffect, useState } from "react"
import EndMessage from "../../../Components/EndMessage/EndMessage.component"
import { Loader } from "../../../Components/Loader/Loader.component"
import axios, { AxiosResponse } from "axios"
import { NavLink } from "react-router-dom"
import { StyledButton } from "../../../Components/Button/Button.styles"
import './UserRoles.scss';
import { usePermissionGuard } from "../../../hooks/usePermissionsGuard"
import { PermissionsEnum } from "../../../utils/interfaces/permissions.enum"
import { PermissionGuard } from "../../../Components/PermissionGuard/PermissionGuard.component"

export interface IUserRoles{
    id: string,
    name: string,
    createdAt: string
}

export const UserRoles = () => {
    const [ roles, setRoles ] = useState<IUserRoles[]>([]);
    const [page, setPage] = useState<number>(1);
    const [hasMore, setHasMore] = useState(true);
    const canSeeRoles = usePermissionGuard(PermissionsEnum.SHOW_ALL_ROLES);

    const getRoles = async (action?: 'next' | null) => {
        let url: string = `/roles?limit=10&page=${page}`;

        await axios.get(url)
            .then((res: AxiosResponse) => {
                const { data } = res;

                data.length < 10 && setHasMore(false)
                action === 'next' ? setRoles([...roles, ...data]) : setRoles(data);

                setPage(page + 1)
            }).catch((e)=>{console.log(e)})
    }

    useEffect(() => {
        getRoles();
        // eslint-disable-next-line
    }, [])
    
    return(
        <Layout>
            {
                canSeeRoles && <>
                    <Title title={'Roles'}>
                        <NavLink to='/admin/user-roles/create'>
                            <PermissionGuard role={PermissionsEnum.ADDING_ADDITIONAL_ROLES}>
                                <StyledButton variant="red" className="sm">add role</StyledButton>
                            </PermissionGuard>
                        </NavLink>
                    </Title>

                    {/* <div className="navigation">
                        <div className='navigation__container'></div>
                        <input type='text' onChange={searching} value={searchText ? searchText : ''} placeholder='Search roles...' className="navigation__search" />
                    </div> */}
                    {
                        <InfiniteScroll
                            dataLength={roles.length}
                            next={() => getRoles('next')}
                            hasMore={hasMore}
                            loader={<Loader />}
                            endMessage={<EndMessage />}
                        >
                            {
                                roles.map((role: IUserRoles) => (
                                    <div className="userInfo"  key={role.id}>
                                        <NavLink to={`/admin/user-roles/${role.id}`} className="userInfoContainer">
                                            <div className="userInfo__container">
                                                <p className="user-roles__title">{role.name}</p>
                                            </div>
                                            <div className="userInfo__actions user-roles__detail-btn">
                                            <StyledButton variant="disabled">
                                                Details
                                            </StyledButton>
                                            </div>
                                        </NavLink>
                                    </div>
                                ))
                            }
                        </InfiniteScroll>
                    }
                </>
            }
        </Layout>
    )
}
import styled, { css } from "styled-components";
import EditIcon from '../../../../assets/images/profile-nav/edit.svg';

export const Header = styled.div<{$mini?:boolean}>`
    width:100%;
    height:100px;
    display:flex;
    justify-content:space-between;
    align-items:center;
    ${({$mini}) => !$mini && css`
        border-bottom:1px solid #242424;
        margin-bottom: 24px;
    `}
`;

export const Stack = styled.div``;

export const Title = styled.div`
    font-family: Inter Semi;
    font-size:16px;
    color:#fff;
    margin-bottom: 8px;
    @media (max-width: 1000px){
        font-size:12px;
    }
`;

export const Desc = styled.div`
    font-family: Inter;
    font-size:14px;
    color:#B6B6B6;
    @media (max-width: 1000px){
        font-size:12px;
    }
`;

export const HeaderEdit = styled.div<{$disabled?:boolean}>`
    width: 32px;
    height: 32px;
    border-radius: 6px;
    background: url(${EditIcon}) no-repeat center;
    background-size: 16px;
    display:flex;
    align-items:center;
    justify-content:center;
    border:4px solid #1B1B1B;
    cursor:pointer;
    ${({$disabled}) => $disabled && css`
        opacity:0.5;
        cursor:not-allowed;
    `}
`;
import styled from "styled-components";
import { EUserStatus } from "../../utils/interfaces/user-status.enum";
import { profileStatus } from "../../constants/profile-statuses";


export const Avatar = styled.div<{$status: EUserStatus}>`
    position:relative;
    width:110px;
    height:110px;
    border-radius:55px;
    border:2.5px solid ${({$status}) => profileStatus[$status].color};
    
    &:before{
        content:'';
        width:100%;
        height:100%;
        background: linear-gradient(180deg, transparent 45%, ${({$status}) => profileStatus[$status].color} 140%);
        border-radius:50%;
        display:block;
    }
    &:after{
        content:attr(data-status);
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
        font-family: 'Main Bold';
        font-size:12px;
        color: ${({$status}) => profileStatus[$status].color};
        display: flex;
        justify-content: center;
        align-items: flex-end;
        padding-bottom: 12px;
        box-sizing:border-box;
        background: url(${({$status}) => profileStatus[$status].icon}) no-repeat;
        background-position: center 60px;
        background-size: 18.5px 18.5px;
    }
    img{
        position:absolute;
        top:0;
        left:0;
        border-radius:55px;
        width:100%;
        height:100%;
        object-fit:cover;
    }
`;

import styled from "styled-components";

export const Container = styled.div<{$isLight?: boolean}>`
    width:100%;
    background:${({$isLight}) => $isLight ? "#181818" : "#0C0C0C"};
    border-radius:8px;
    height:64px;
    padding:10px 16px;
    display:flex;
    flex-direction:column;
    justify-content:space-between;
    cursor: text;
    @media (max-width: 1000px){
        height:77px;
        padding: 12px 20px;
    }
`;

export const Label = styled.label`
    width:100%;
    font-size:14px;
    font-family: Inter Medium;
    color: #606060;
    @media (max-width: 1000px){
        font-size:12px;
    }
`;

export const InputField = styled.input`
    width:100%;
    background:none;
    outline:none;
    border:none;

    font-size:16px;
    font-family: Inter;
    color: #DFDFDF;
    @media (max-width: 1000px){
        font-size:15px;
    }
`;

export const ErrorMsg = styled.div`
    margin-top: 4px;
    color: #FF4747;
    font-size: 9px;
`;

export const InviteContainer = styled.div<{$isLight?: boolean}>`
    width:100%;
    display:flex;
    flex-direction:column;
    justify-content:space-between;
    
    @media (max-width: 1000px){
    }
`;

export const InviteLabel = styled.label`
    width:100%;
    font-size:14px;
    font-family: Main Bold;
    color: #fff;
    margin-bottom: 12px;
    @media (max-width: 1000px){
        /* font-size:12px; */
    }
`;

export const InviteInputField = styled.input`
    width:100%;
    background:none;
    outline:none;
    border-radius:6px;
    border:1px solid #272727;
    font-size:14px;
    font-family: Inter;
    color: #DFDFDF;
    height: 68px;
    width: 100%;
    padding:0 23px;
    @media (max-width: 1000px){
        font-size:15px;
    }
`;
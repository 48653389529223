import styled from "styled-components";

export const Form = styled.div`
    display:grid;
    grid-template-columns: repeat(2, 1fr);
    gap:20px;
    width:100%;
    @media (max-width: 468px) {
        grid-template-columns: 1fr;
    }
`
import { FC, ButtonHTMLAttributes, ReactNode } from "react"
import styled from "styled-components";

interface IRaButton {
    props?: ButtonHTMLAttributes<HTMLButtonElement>;
    children?: ReactNode;
    loading?: boolean;
    disabled?: boolean;
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const StyledButton = styled.button`
    position:relative;
    height:40px;
    border-radius:20px;
    border:1px solid #5834A7;
    padding:0 17px;
    font-size:16px;
    font-family: "Main Bold";
    color:#5834A7;
    cursor:pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    overflow:hidden;
    &[disabled]{
        color:#777777;
        border:1px solid #777777;
        cursor:not-allowed;
        svg{
            fill:transparent;
            path{
                stroke:#777777;
            }
        }
    }
`;

const StyledLoader = styled.div`
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-size:16px;
    font-family: "Main Bold";
    color:#fff;
    background:#5834A7;
    border:1px solid #5834A7;
    svg{
        animation: spin 1.5s linear infinite;
    }
    @keyframes spin{
        0%{
            transform: rotate(0deg);
        }
        100%{
            transform: rotate(360deg);
        }
    }
`;

export const RaButton: FC<IRaButton> = ({props,children,loading,disabled,onClick}) => {
    return(
        <StyledButton {...props} disabled={disabled} onClick={onClick}>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_3982_5331)">
                <path d="M11.875 19.375L8.125 11.875L0.625 8.125L19.375 0.625L11.875 19.375Z" stroke="#5834A7" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M8.125 11.875L15.625 4.375" stroke="#5834A7" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
                </g>
                <defs>
                <clipPath id="clip0_3982_5331">
                <rect width="20" height="20" fill="white"/>
                </clipPath>
                </defs>
            </svg>

            {children}
            {loading ? 
                <StyledLoader>
                    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.5 3V6M12.5 21V18M4.70577 16.5L7.30385 15M21.5 12H18.5M17 19.7942L15.5 17.1961M3.5 12H6.5M8 4.20577L9.5 6.80385M8 19.7942L9.5 17.1961M20.2942 16.5L17.6962 15M4.70577 7.5L7.30385 9" stroke="white" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>

                    Sending...
                </StyledLoader>
            : null}
        </StyledButton>
    )
}
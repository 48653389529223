import { FC, ReactNode } from "react";
import { InputField, InputFieldNew, InputForm, InputLabel, InputPlace } from "./member-input.styles";
import { Show } from "../../../../../Components/Show/Show";

interface MemberInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
    readOnly?: boolean;
    label: string;
    newValue?: string | null;
    error?: any
}

export const MemberInput:FC<MemberInputProps> = ({
    readOnly,
    label,
    newValue,
    error,
    ...rest
}) => {
    return (
        <InputForm>
            <InputLabel $isNew={Boolean(newValue || newValue === "")}>{label}</InputLabel>
            <InputPlace>
                <InputField {...rest} readOnly={readOnly}/>
                <Show condition={Boolean(newValue || newValue === "")}>
                    <InputFieldNew value={(newValue === "" ? "-" : newValue) ?? ""} readOnly/>
                </Show>
            </InputPlace>
            {error && <div className='input-error'>{error}</div>}
        </InputForm>
    )
}

interface MemberInputCustomProps extends MemberInputProps{
    children?: ReactNode;
}

export const MemberInputCustom:FC<MemberInputCustomProps> = ({
    label,
    newValue,
    children,
    error
}) => {
    return (
        <InputForm>
            <InputLabel $isNew={Boolean(newValue)}>{label}</InputLabel>
            <InputPlace>{children}</InputPlace>
            {error && <div className='input-error'>{error}</div>}
        </InputForm>
    )
}
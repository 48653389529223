import { Avatar, UploadButton, Uploader, Wrapper } from "./update-profile.styles";
import {ReactComponent as UploadIcon} from '../../../../assets/images/profile-nav/upload-icon.svg';
import { FC } from "react";


export const UpdateProfile:FC<any> = ({localUrl,url,...rest}) => {
    const imagePath = `${process.env.REACT_APP_HOST}/public/users/${url}`;
    const checkedImage = localUrl ? localUrl : imagePath;
    
    return(
        <Uploader>
            <Avatar 
                src={`${checkedImage}`}
                // src="https://basementny.mtebi.com/public/users/38b6bf30-c806-4f40-96db-3f949ccdc127-334148289_612351427028167_4049865678478589831_n.png" 
                alt="profile edit image"
            />
            <Wrapper>
                <UploadButton {...rest}>
                    <UploadIcon />
                    Upload photo
                </UploadButton>
                SVG, PNG, JPG  (max. 800x400px)
            </Wrapper>
        </Uploader>
    );
}
import { useState } from "react"
import { Container, Main } from "../components/info/info.styles"
import { Tab, TabGroup } from "../components/tab/tab.styles"
import { Show } from "../../../Components/Show/Show"
import { PersonalInformation } from "../view/personal-information"
import { ResetPassView } from "../view/reset-passowrd"


const tabData = [
    'Personal information',
    'Reset password',
];

export const ProfileInfo = () => {
    const [activeTab, setActiveTab] = useState(0);
       
    return(
       <Container>
            <TabGroup>
                {
                    tabData.map((tab, index) => 
                        <Tab
                            key={`profile_tab_${index}`}
                            $isActive={activeTab === index}
                            onClick={() => index === 0 && setActiveTab(index)}
                            style={index === 0 ? {} : {opacity:0.5,cursor:'not-allowed'}}
                        >
                            {tab}
                        </Tab>
                    )
                }
            </TabGroup>
            <Main>
                <Show condition={activeTab === 0}>
                    <PersonalInformation />
                </Show>

                <Show condition={activeTab === 1}>
                    <ResetPassView/>
                </Show>
            </Main>
       </Container>
    )
}
import styled, { css } from "styled-components";
import AddIcon from '../../../../assets/images/profile-nav/add-icon.svg'
import RemovedIcon from '../../../../assets/images/profile-nav/profile-delete.svg'

export const Container = styled.div`
    width:100%;
    height:100%;
`;

export const Main = styled.div`
    width:100%;
    height:calc(100% - 72px);
    background:#0C0C0C;
    padding:16px 12px;
`;

export const ProfileWrapper = styled.div`
    display:flex;
    flex-direction:column;
    width:100%;
    min-height:100%;
    gap:16px;
`;

export const ProfileStack = styled.div<{$mini?:boolean}>`
    width:100%;
    background:#000;
    border-radius:12px;
    padding:0 20px 20px 20px;
    ${({$mini}) => $mini && css`
        padding:0 20px;
    `}
    @media (max-width: 1000px){
        padding:0 16px 16px 16px;
        ${({$mini}) => $mini && css`
            padding:0 16px;
        `}
    }
`;

export const Form = styled.form`
    width:100%;
    max-width: 570px;
    margin: 0 auto;
`;

export const PassowrdForm = styled(Form)`
    max-width:420px;
`;

export const MainInputs = styled.div`
    width:100%;
    display:grid;
    grid-template-columns: repeat(2, 1fr);
    gap:16px;
    @media (max-width: 1000px){
        gap:6px;
        grid-template-columns: 1fr;
    }
`;

export const PassowrdInputs = styled.div`
    width:100%;
    display:grid;
    grid-template-columns: 1fr;
    gap:16px; 
`;

export const FormTitle = styled.div`
    display:flex;
    justify-content:space-between;
    align-items:center;
    font-size:18px;
    font-family: Inter Medium;
    color:#fff;
    margin: 24px 0;
    @media (max-width: 1000px){
        font-size:12px;
        margin: 16px 0;
    }
`;

export const SocialInputs = styled.div`
    width:100%;
    display:flex;
    flex-direction:column;
    gap:12px;
`;

export const ButtonGroup = styled.div`
    margin: 24px 0;
    width: 100%;
    height:52px;
`;

export const Submit = styled.button`
    height:52px;
    padding: 0 46px;
    border-radius:6px;
    background:#FF3131;
    border:none;
    outline:none;
    cursor: pointer;
    font-size:16px;
    font-family: Main Regular;
    color:#fff;
    text-transform:uppercase;
    &[disabled]{
        opacity:0.5;
    }
`;

export const PasswordReset = styled.div`
    width:100%;
`;


export const AddLink = styled.div`
    font-size:14px;
    font-family: Main Bold;
    color:#fff;
    background:url(${AddIcon})no-repeat;
    background-position: left center;
    padding-left:18px;
    cursor:pointer;
    @media (max-width: 1000px){
        font-size:12px;
    }
`;


export const SocialItem = styled.div`
    position:relative;
    width:100%;
`;

export const RemoveIcon = styled.div`
    position:absolute;
    right:10px;
    top:calc(50% - 25px/2);
    cursor:pointer;
    width:25px;
    height:25px;
    background:url(${RemovedIcon})no-repeat;
    background-size:contain;
`;
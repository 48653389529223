import { StyledButton } from '../Button/Button.styles';
import { NavLink, useLocation } from 'react-router-dom';
import './PodcastBlock.scss';

export interface PodcastBlockProps {
  id: string;
  title: string;
  image: string;
  canNavigate:boolean;
}

const PodcastBlock: React.FC<PodcastBlockProps> = ({
  id,
  title,
  image,
  canNavigate
}) => {
  const location = useLocation();
  const currentLocation = location.pathname + location.search;
  
  return (
    <div className="userInfo podcasts-block">
      <NavLink to={canNavigate? `/admin/podcasts/${id}` : `${currentLocation}`} className="userInfoContainer podcasts-block__info">
        <div className="userInfo__container podcasts-block__container">
          {
            image && 
            <div className="userInfo__avatar userInfo__avatar--lg podcasts-block__avatar">
              <img
                src={`${process.env.REACT_APP_HOST}/public/podcasts/${image}`}
                alt={title}
              />
            </div>
          }
          <div className="userInfo__text userInfo__text--fluid podcasts-block__title">
            <div className="userInfo__text-text">
              {title} 
              {/* <span className='userInfo__reg'> / {country}</span> */}
            </div>
          </div>
        </div>
        <div className="userInfo__actions">
          {canNavigate &&<StyledButton variant="disabled">
            Details
          </StyledButton>}
        </div>
      </NavLink>
    </div>
  );
};

export default PodcastBlock;

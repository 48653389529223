import { useState } from "react";
import { EditHeader } from "../components/edit-header/edit-header"
import { ButtonGroup, PassowrdForm, PassowrdInputs, PasswordReset, ProfileStack, Submit } from "../components/info/info.styles"
import { Input } from "../components/input/input";
import { Show } from "../../../Components/Show/Show";

export const ResetPassView = () => {
    const [ show, setShow ] = useState(false);
    

    return(
        <PasswordReset>
            <ProfileStack $mini={!show}>
                <EditHeader
                    mini={!show}
                    title="Reset password"
                    desc="This will be displayed on your profile."
                    onContainerClick={() => setShow(!show)}
                />
                <Show condition={show}>
                    <PassowrdForm>
                        <PassowrdInputs>
                            <Input 
                                label="Current"    
                                type="password"
                            />
                            <Input 
                                label="New Password"
                                type="password"
                            />
                            <Input 
                                label="Repeat Password"
                                type="password"
                            />
                        </PassowrdInputs>
                        <ButtonGroup>
                            <Submit>Confirm</Submit>
                        </ButtonGroup>
                    </PassowrdForm>
                </Show>
            </ProfileStack>
        </PasswordReset>
    )
}
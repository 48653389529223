import { useState } from "react";
import TooltipComponent from "../Tooltip/Tooltip.component";
import StyledInput, { IInput, InputContainer, StyledShowPassword } from "./Input.styles";
import EyeOffIcon from '../../assets/images/eye-off.svg';
import EyeIcon from '../../assets/images/eye.svg';

const Input: React.FC<IInput> = ({ variant, label, id, error, icon, tooltip, showRequired, showHideToggle, ...rest }) => {

    const [showPassword, setShowPassword] = useState(false);
    
    return (
        <InputContainer>
            {
                label && <label htmlFor={id}>
                    {icon}
                    {label}
                    {showRequired && <span className="required-field"> *</span>}
                    {
                        tooltip && <TooltipComponent title={tooltip} />
                    }
                </label>
            }

            <div className="pos-r">
                <StyledInput error={error} variant={variant} id={id} {...rest} type={showPassword ? 'text' : rest.type} />

                {
                    showHideToggle && <StyledShowPassword className='' onClick={() => setShowPassword(!!!showPassword)}>
                        <img src={showPassword ? EyeOffIcon : EyeIcon} alt='show-hide' />
                    </StyledShowPassword>
                }
            </div>
            
            {error && <div className='input-error'>{error}</div>}
        </InputContainer>
    );
};

export default Input;
// import { useContext, useEffect, useState } from "react";
import './HouseRules.scss';
// import { HouseRulesType, RulesContext } from "../../context/RulesContext";

interface HomeRulesProps {
    size?: 'sm';
}

const HomeRules: React.FC<HomeRulesProps> = ({size}) => {
    // const [rulesList, setRulesList] = useState<string[]>([]);
    // const rules:HouseRulesType = useContext(RulesContext);
    
    // useEffect(()=>{
    //     if(rules) {
    //         const rulesArrayOfKeys:string[] = Object.keys(rules).sort();
    //         const rulesData:string[] = rulesArrayOfKeys.filter((item:string) => item.startsWith('house_rules'));
           
    //         setRulesList(rulesData);
    //     }
    // },[rules])

    return <div className={`house-rules house-rules--${size}`}>
            <div className={`house-rules__title house-rules__title--${size}`}>HOUSE RULES</div>
            <div className={`house-rules__item house-rules__item--${size}`}>
                <div className={`house-rules__subtitle house-rules__subtitle--${size}`}>
                    We hope you come for the music, to support the artists we present, and
                    to enjoy a liberated environment for everyone. To achieve a space
                    focused on music and dancing, everyone who enters agrees to the
                    following basic ground rules:
                </div>

                <div className="house-rules__list">Admittance will be determined at the door to create a highly engaged dance floor. We’re proud to present these artists and encourage you to explore them in advance</div>
                <div className="house-rules__list">Consent is mandatory</div>
                <div className="house-rules__list">Zero tolerance for racism, homophobia, transphobia, sexism, ableism or any form of discrimination</div>
                <div className="house-rules__list">The dance floor is for dancing, no talking on the dance floor</div>
                <div className="house-rules__list">No photos/videos are allowed</div>
                <div className="house-rules__list">Removing the sticker from your phone, will result in your removal</div>
                <div className="house-rules__list">No phone use on the dancefloor</div>
                <div className="house-rules__list">No glowing/flashing clothes or items</div>
                <div className="house-rules__list">Respect other people's space, no shuffling</div>
                <div className="house-rules__list">Balaclava masks are not allowed</div>
                <div className="house-rules__list">Take care of each other, alert our staff if somebody isn't feeling well</div>
                <div className="house-rules__list">Violating the house rules will result in removal</div>
            </div>

            <div className={`house-rules__title house-rules__title--${size}`}>House Policies</div>
            <div className={`house-rules__item house-rules__item--${size}`}>
                <div className="house-rules__list">For the security of all guests, we need an ID Scan for every visitor. We can scan Passports, or US State Issued ID's. If you do not want to bring your Passport, and don't have a US State ID, we can try to scan your passport from a copy or a photo - but only if you do have your original National ID so we can verify the passport copy/photo is you. If the copy or photo isn't clear and cannot be scanned, we cannot admit you.</div>
                <div className="house-rules__list">Limited amounts of tickets are always available at the door, every night</div>
                <div className="house-rules__list">BASEMENT is a 21+ venue. Valid identification is required</div>
                <div className="house-rules__list">Ticket purchase does not guarantee entrance.</div>
                <div className="house-rules__list">Tickets purchased from third-party resellers may be denied entry</div>
                <div className="house-rules__list">Please be considerate of our neighbors when leaving.</div>
                <div className="house-rules__list">We accept all major Credit Cards, and Cash. We do have an ATM.</div>
                <div className="house-rules__list">Our bathrooms are unisex, with private stalls</div>
                <div className="house-rules__list">BASEMENT is fully accessible. Reach out to mail@basementny.net for specific details or questions</div>
                <div className="house-rules__list">Coat check will be available year round. For the safety of your personal items, all coats and bags bigger than a purse must be checked</div>
                <div className="house-rules__list">There is no dress code, dressing comfortably is encouraged. High heels and formal wear are not allowed. Shoes are required.</div>
                <div className="house-rules__list">We do not sell or reserve tables. We do not sell bottles in BASEMENT</div>
                <div className="house-rules__list">
                Medication: No more than four pills, no more than two kinds of pill. Pills that are obviously Advil, Aleve, or Tylenol are allowed. Patrons should not bring containers filled with multiple, different medications.
                </div>
                <div className="house-rules__list">
                Anyone with a condition or situation requiring special consideration should contact <a style={{color: 'white', textDecoration: 'underline'}} href="mailto:mail@basementny.net">mail@basementny.net</a> more than 24 hours prior to ensure admission.
                </div>
            </div>
            
            {/* <div className={`house-rules__title house-rules__title--${size}`}>{rules?.house_title}</div>
            <div className={`house-rules__item house-rules__item--${size}`}>
                <div className={`house-rules__subtitle house-rules__subtitle--${size}`}>{rules?.house_subtitle}</div>
                <div className="house-rules__text">
                    {
                        rulesList.map((item,index) => 
                            rules && <div className="house-rules__list" key={index}>{rules[item]}</div>
                        )
                    }
                </div>
            </div> */}
        </div>
}

export default HomeRules;
import { createContext, useContext, useState, ReactNode } from "react";
import { IInfoChange, IUser } from "../utils/interfaces/user.interface";

interface UserContextType {
  user?: IUser;
  setUser: (user: IUser) => void;
  infoChange?: IInfoChange | null;
  setInfoChange: (info: IInfoChange) => void;
}

export const UserContext = createContext<UserContextType | undefined>(undefined);

export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
};

export const UserProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<IUser>();
  const [infoChange, setInfoChange] = useState<IInfoChange | null>(null);

  return (
    <UserContext.Provider value={{ user, setUser, infoChange, setInfoChange }}>
      {children}
    </UserContext.Provider>
  );
};
import { FC, useState } from "react";
import { Actions, Block, BlockCol, BlockLabel, BlockStatus, BlockTitle, LetterButton } from "./friend-block.styles";
import { ISentInvites, IUser } from "../../../../utils/interfaces/user.interface";
import LetterPopupForm from "../../../../Components/LetterPopupForm/LetterPopupForm";

type FriendItemProps = {
    invite: ISentInvites;
    user?: IUser;
}
export const FriendItem:FC<FriendItemProps> = ({invite,user}) => {
    const [openPopupId, setOpenPopupId] = useState<string | null>(null);

    return <Block  data-status={invite.status} $status={invite.status}>
        <BlockCol title={invite.email}>
            <BlockLabel>Email</BlockLabel>
            <BlockTitle>{invite.email}</BlockTitle>
        </BlockCol>
        <Actions>
            {
                invite.need_letter ? (
                    <LetterButton
                        onClick={() => setOpenPopupId(invite.id)}
                    >
                        {invite.letter ? "view letter" : "add letter"}
                    </LetterButton>
                ): null
            }
            <BlockStatus $status={invite.status}>
                <span>{invite.status}</span>
            </BlockStatus>
        </Actions>

        {
            user ? (
                <LetterPopupForm
                    request_by={user}
                    user={user}
                    invite={invite}
                    isopen={openPopupId === invite.id}
                    onClose={() => setOpenPopupId(null)}
                />
            ) : null
        }
        
    </Block>;
}
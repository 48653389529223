import moment from 'moment';
import './EventBlock.scss';
import { StyledButton } from '../Button/Button.styles';
import { NavLink, useLocation } from 'react-router-dom';

export interface EventBlockComponentProps {
  id: string;
  name: string;
  date: string;
  cover: string;
  canNavigate:boolean;
}

const EventBlockComponent: React.FC<EventBlockComponentProps> = ({
  name,
  id,
  date,
  cover,
  canNavigate
}) => {
  const location = useLocation();
  const currentLocation = location.pathname + location.search;
  
  return (
    <div className="userInfo event-block">
      <NavLink to={canNavigate? `/admin/events/${id}` : `${currentLocation}`} className="userInfoContainer event-block__info">
        <div className="userInfo__container event-block__container">
          <div className="userInfo__text event-block__date">
            <div className="userInfo__text-title">Start date</div>
            <div className="userInfo__text-text">{moment(date).format('YYYY-MM-DD HH:mm')}</div>
          </div>
          {
            cover && 
            <div className="userInfo__avatar userInfo__avatar--lg event-block__avatar">
              <img
                src={`${process.env.REACT_APP_HOST}/public/events/${cover}`}
                alt="avatar"
              />
            </div>
          }
          <div className="userInfo__text event-block__title">
            <div className="userInfo__text-title">Event name</div>
            <div className="userInfo__text-text">{name ? name : '-'}</div>
          </div>
        </div>
        <div className="userInfo__actions">
          {canNavigate &&<StyledButton variant="disabled">
            Details
          </StyledButton>}
        </div>
      </NavLink>
    </div>
  );
};

export default EventBlockComponent;

import { StyledTitle } from "./Title.styles";

interface ITitle {
    title: string;
    children?: React.ReactNode;
    className?: string;
}

const Title: React.FC<ITitle> = ({ title, children, className}) => {
    return (
        <StyledTitle className={className}>
            {title}
            {children}
        </StyledTitle>
    )
}

export default Title;
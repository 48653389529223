import './PodcastsDetail.scss';

import Title from "../../../Components/Title/Title.component";
import { useNavigate, useParams } from 'react-router-dom';
import { ChangeEvent, useEffect, useState } from 'react';
import axios, { AxiosError, AxiosResponse } from 'axios';
import LoadingWrapper from '../../../Views/Admin/Loading.wrapper';
import Input from '../../../Components/Input/Input.component';
import "react-datepicker/dist/react-datepicker.css";
import { InputContainer } from '../../../Components/Input/Input.styles';
import { useDropzone } from 'react-dropzone';
import MDEditor from '@uiw/react-md-editor';
import { FormikValues, useFormik } from 'formik';
import { Button } from '../../../Components/Button/Button.component';
import { toast } from 'react-toastify';
import ConfirmPopup from '../../../Components/ConfirmPopup/ConfirmPopup';
import Layout from '../../../Views/Admin/Layout';
import { StyledButton } from '../../../Components/Button/Button.styles';
import { usePermissionGuard } from '../../../hooks/usePermissionsGuard';
import { PermissionsEnum } from '../../../utils/interfaces/permissions.enum';
import { PermissionGuard } from '../../../Components/PermissionGuard/PermissionGuard.component';
import { toastOptions } from '../../../utils/helpers/toast.options';
import { AlbumModal } from '../../../Components/AlbumModal/AlbumModal';
import { IPodcast } from '../../../utils/interfaces/podcasts.interface';
import { AlbumCard } from '../../../Components/AlbumCard/AlbumCard';
import { ReactComponent as InstagramIcon } from '../../../assets/images/instagram-icon-white.svg';
import { ReactComponent as RaIcon } from '../../../assets/images/ra-icon-white.svg';
import { ReactComponent as SoundcloudIcon } from '../../../assets/images/soundcloud-icon-white.svg';
import Select from "react-select";
import { AlbumModalUpdate } from '../../../Components/AlbumModalUpdate/AlbumModalUpdate';
        
const PodcastsUpdate: React.FC = () => {
    const {podcast_id} = useParams<{podcast_id: string}>();
    const [ albumId, setAlbumId ] = useState('');
    const [ openModal, setOpenModal ] = useState(false);
    const [status, setStatus] = useState<any>(null)
    const [podcasts, setPodcasts] = useState<IPodcast | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
    const canSeeDetails = usePermissionGuard(PermissionsEnum.CAN_READ_PODCAST)
    const canUpdateEvent = usePermissionGuard(PermissionsEnum.CAN_UPDATE_PODCAST)
    const navigate = useNavigate()

    const getPodcastInfo = async (): Promise<void> => {
        await axios.get(`/podcasts/${podcast_id}`)
            .then((res: AxiosResponse) => {
                const {data} = res;

                formik.setFieldValue('title', data.title);
                formik.setFieldValue('description', data.description);
                formik.setFieldValue('image', data.image);
                formik.setFieldValue('url', data.url);
                formik.setFieldValue('status', data.status);
                
                formik.setFieldValue('soundcloudLink', data.soundcloudLink ?? "");
                formik.setFieldValue('instagramLink', data.instagramLink ?? "");
                formik.setFieldValue('raLink', data.raLink ?? "");

                if(data.status === 'draft') setStatus({value: data.status, label: 'draft'});
                else if(data.status === 'published') setStatus({value: data.status, label: 'published'});

                setImage(`${process.env.REACT_APP_HOST}/public/podcasts/${data.image}`);
                
                setPodcasts(data);
                setIsLoading(false)
            })
    }
    
    const [image, setImage] = useState<any>(null)

    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        accept: {
            "image/*": [".png", '.jpg'],
        },
        disabled: canUpdateEvent ? false : true,
        maxFiles: 1,
        onDrop: (acceptedFiles) => {
            const file: any = acceptedFiles[0];
            formik.setFieldValue('image', file);

            setImage(URL.createObjectURL(file));
        }
    });

    const onSubmit = async (values: any) => {
        const valuesCopy = {...values};
        const formData = new FormData();
        

        for (const [key, value] of Object.entries(valuesCopy)) {
            formData.append(key, value as Blob);
        }
        setIsLoading(true);
        
        try {
            await axios.put(`podcasts/${podcast_id}`, formData)
                .then(() => {
                    toast.success(`Podcast updated 🦄`, toastOptions)
                })
                .catch((err) => {
                    if(err.response?.data?.message?.length > 0) {
                        setError(err.response?.data?.message.join(' '));
                    }
                    else {
                        setError(err.response?.data?.message);
                    }
                });;
        } catch (err) {
            if (err && err instanceof AxiosError) {
                if(err.response?.data?.message === 'INVALID_CREDENTIALS') {
                    setError('Incorrect email or password.');
                }
                else {
                    setError(err.response?.data?.message);
                }
            }
            else if (err && err instanceof Error) {
                setError('Something went wrong. Please try again later.');
            }

            console.log("Error: ", err);
        }

        setIsLoading(false);
    };

    const formik: FormikValues = useFormik({
        initialValues: {
            title: '',
            description: '',
            image: '',
            url: '',
            status: '',
            soundcloudLink: '',
            instagramLink: '',
            raLink: '',
        },
        onSubmit,
    });

    const onPopupAction = async (trueOrFalse: boolean): Promise<void> => {
        setIsPopupOpen(false)
        
        if(trueOrFalse) {
            await axios.delete(`podcasts/${podcast_id}`)
                .then(() => {
                    navigate('/admin/podcasts?action=delete&status=ALL')
                })
        }
    }

    const onAlbumDelete = async (id: string) => {
        await axios.delete(`podcasts/album/${id}`)
            .then(() => {
                getPodcastInfo()
                toast.success('Podcast album deleted')
            })
    }

    useEffect(() => {
        if(canSeeDetails) getPodcastInfo();
        // eslint-disable-next-line
    }, [podcast_id])
    
    const handleOpenAlbum = (e:any) => {
        e.preventDefault();
        setOpenModal(true);
    }

    const options: {value: string, label: string}[] = [
        { value: 'draft', label: 'draft' },
        { value: 'published', label: 'publish' },
    ]
    console.log(albumId)
    return (
        <Layout>
            {canSeeDetails ? <LoadingWrapper loading={isLoading}>
                <div className="membersProfile">
                    <div className="membersProfile-info">
                        <Title title={'Podcast'} />
                        <form onSubmit={formik.handleSubmit}>
                            <InputContainer>
                                <label htmlFor='country'>Podcast Cover <span className="required-field"> *</span></label>
                                
                                <div className="dropPresent dropPresent--event" {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    {
                                        isDragActive ?
                                        <p>Drop the file here ...</p> :
                                        <p>Drag 'n' drop some file here, or click to select files</p>
                                    }
                                    {
                                        image &&  <img src={image} alt="preview" className="dropPresent__image dropPresent__image--event" />
                                    }
                                </div>
                                    
                                    {/* {formik.touched.country || formik.errors.country && <div className='input-error'>{formik.errors.country}</div>} */}
                            </InputContainer>
                            
                            <Input 
                                placeholder='Type podcast name'
                                className='loginForm__input loginForm__input--noRounded'
                                label='Podcast Name'
                                name='title'
                                required
                                showRequired={true}
                                disabled={canUpdateEvent ? false : true}
                                error={formik.touched.title && formik.errors.title}
                                value={formik.values.title}
                                onChange={formik.handleChange}
                            />

                            <Input 
                                type='text'
                                placeholder='Paste the link here'
                                className='loginForm__input loginForm__input--noRounded'
                                label='Podcast URL'
                                name='url'
                                disabled={canUpdateEvent ? false : true}
                                error={formik.touched.url && formik.errors.url}
                                value={formik.values.url}
                                onChange={formik.handleChange}
                                showRequired
                            />

                            <InputContainer>
                                <label htmlFor={"end_date"}>Podcast Description <span className="required-field"> *</span></label>
                                <div data-color-mode="dark">
                                    <MDEditor 
                                        height={350} 
                                        style={{
                                            border: '0px solid #272727',
                                            borderRadius: '6px',
                                            backgroundColor: 'transparent',
                                        }}
                                        textareaProps={{
                                            disabled: canUpdateEvent ? false : true
                                        }}
                                        aria-disabled={canUpdateEvent ? false : true}
                                        value={formik.values.description}
                                        onChange={(value): ChangeEvent<HTMLTextAreaElement> => formik.setFieldValue('description', value)}
                                    />
                                </div>
                            </InputContainer>
                            <div className="pdcst-detail__socials">
                                <Input 
                                    placeholder='Type link here'
                                    className='loginForm__input loginForm__input--noRounded'
                                    label='Soundcloud link'
                                    icon={<SoundcloudIcon/>}
                                    name='soundcloudLink'
                                    error={formik.touched.soundcloudLink && formik.errors.soundcloudLink}
                                    value={formik.values.soundcloudLink}
                                    onChange={formik.handleChange}
                                />

                                <Input 
                                    placeholder='Type link here'
                                    className='loginForm__input loginForm__input--noRounded'
                                    label='Instagram link'
                                    icon={<InstagramIcon/>}
                                    name='instagramLink'
                                    error={formik.touched.instagramLink && formik.errors.instagramLink}
                                    value={formik.values.instagramLink}
                                    onChange={formik.handleChange}
                                />

                                <Input 
                                    placeholder='Type link here'
                                    className='loginForm__input loginForm__input--noRounded'
                                    label='Ra link'
                                    icon={<RaIcon/>}
                                    name='raLink'
                                    error={formik.touched.raLink && formik.errors.raLink}
                                    value={formik.values.raLink}
                                    onChange={formik.handleChange}
                                />
                            </div>

                            <InputContainer>
                                <label htmlFor='status'>Status <span className="required-field"> *</span></label>
                                <Select 
                                    options={options} 
                                    defaultValue={formik.values.status}
                                    isDisabled={canUpdateEvent ? false : true}
                                    onChange={(value: any) => {
                                        setStatus(value)
                                        formik.setFieldValue('status', value.value);
                                    }}
                                    value={status}
                                    id="status"
                                    name="status"
                                    styles={{
                                        control: (baseStyles) => ({
                                            ...baseStyles,
                                            backgroundColor: 'transparent',
                                            height: window.innerWidth <= 980 ? '48px' : '64px',
                                            borderColor: '#272727',
                                            borderRadius: '6px',
                                            color: '#FFF'
                                        }),
                                        singleValue: (baseStyles) => ({
                                            ...baseStyles,
                                            color: '#FFF'
                                        }),
                                        input: (baseStyles) => ({
                                            ...baseStyles,
                                            color: '#FFF'
                                        }),
                                    }}
                                    className='country-selector'
                                />
                                {formik.touched.country && formik.errors.country && <div className='input-error'>{formik.errors.country}</div>}
                            </InputContainer>

                            <div className="album-cards">
                                <div className="album-cards__title">
                                    Add album gallery
                                </div>
                                {
                                    podcasts?.albums.map((album)=>{
                                        return <AlbumCard
                                            id={album.id}
                                            key={album.id}
                                            title={album.title}
                                            artistName={album.artistName}
                                            url={album.url}
                                            image={album.image}
                                            onClick={() => {
                                                setAlbumId(album.id)
                                            }}
                                            onDelete={(id: string) => {
                                                onAlbumDelete(id)
                                            }}
                                        />
                                    })
                                }

                                { podcasts?.albums.length === 0 && <div className="album-cards__empty">you don’t have any album added yet</div> }
                                    
                           
                                    <PermissionGuard role={PermissionsEnum.CAN_UPDATE_PODCAST}>
                                        <div className="album-cards__button">
                                            <StyledButton 
                                                variant="add-secondary" 
                                                onClick={handleOpenAlbum as any}
                                            >
                                                Add album
                                            </StyledButton>
                                        </div>
                                    </PermissionGuard>
                            </div>

                            {error && <div className='error-message'>{error}</div>}

                            <ConfirmPopup
                                label={'Are you sure you want to delete the podcast?'}
                                text={'Podcast will be deleted permanently.'}
                                onAction={onPopupAction}
                                isopen={isPopupOpen}
                            />

                            <div className='flex flex-jcsb'>
                                <PermissionGuard role={PermissionsEnum.CAN_DELETE_PODCAST}>
                                    <Button type={'button'} onClick={() => setIsPopupOpen(true)} variant='stroked-red' className='sm'>delete podcast</Button>
                                </PermissionGuard>
                                <span></span>
                                <PermissionGuard role={PermissionsEnum.CAN_UPDATE_PODCAST}>
                                    <StyledButton type={'submit'} variant='red' className='sm'>update podcast</StyledButton>
                                </PermissionGuard>
                            </div>
                            <br />
                            <br />
                        </form>
                    </div>
                </div>
                <AlbumModal
                    isOpen={openModal}
                    onClose={() => setOpenModal(false)}
                    refetch={getPodcastInfo}
                />
                <AlbumModalUpdate
                    isOpen={!!albumId}
                    onClose={() => setAlbumId('')}
                    refetch={getPodcastInfo}
                    id={albumId}
                />
            </LoadingWrapper> : null}
        </Layout>
    )
}

export default PodcastsUpdate;
import './PodcastsCreate.scss';

import Layout from "../../../Views/Admin/Layout";
import { useNavigate } from 'react-router-dom';
import {  ChangeEvent, useState } from 'react';
import axios, { AxiosError } from 'axios';
import LoadingWrapper from '../../../Views/Admin/Loading.wrapper';
import Input from '../../../Components/Input/Input.component';
import "react-datepicker/dist/react-datepicker.css";
import { InputContainer } from '../../../Components/Input/Input.styles';
import { useDropzone } from 'react-dropzone';
import MDEditor from '@uiw/react-md-editor';
import { FormikValues, useFormik } from 'formik';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { StyledButton } from '../../../Components/Button/Button.styles';
import Title from '../../../Components/Title/Title.component';
import { toastOptions } from '../../../utils/helpers/toast.options';
import { ReactComponent as InstagramIcon } from '../../../assets/images/instagram-icon-white.svg';
import { ReactComponent as RaIcon } from '../../../assets/images/ra-icon-white.svg';
import { ReactComponent as SoundcloudIcon } from '../../../assets/images/soundcloud-icon-white.svg';

const podcastSchema = Yup.object({
    title: Yup.string().required('Name is required'),
    description: Yup.string().required('Description is required'),
    url: Yup.string().required('Link is required'),
    image: Yup.mixed().required('Cover photo is required'),
});
        
const PodcastsCreate: React.FC = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate()
    
    const [image, setImage] = useState<any>(null)

    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        accept: {
            "image/*": [".png", '.jpg'],
        },
        maxFiles: 1,
        onDrop: (acceptedFiles) => {
            const file: any = acceptedFiles[0];
            formik.setFieldValue('image', file);

            console.log(URL.createObjectURL(file));
            
            setImage(URL.createObjectURL(file));
        }
    });

    const onSubmit = async (values: any) => {
        const valuesCopy = {...values};
        // valuesCopy.status = 'draft';

        const formData = new FormData();

        for (const [key, value] of Object.entries(valuesCopy)) {
            formData.append(key, value as Blob);
        }
        
        setIsLoading(true);
        try {
            await axios.post(`podcasts`, formData)
                .then(() => {
                    toast.success(`Podcast added 🦄`, toastOptions)
                    navigate('/admin/podcasts?status=draft')
                })
                .catch((err) => {
                    if(err.response?.data?.message?.length > 0) {
                        setError(err.response?.data?.message.join(' '));
                    }
                    else {
                        setError(err.response?.data?.message);
                    }
                });
        } catch (err) {            
            if (err && err instanceof AxiosError) {
                if(err.response?.data?.message === 'INVALID_CREDENTIALS') {
                    setError('Incorrect email or password.');
                }
                else {
                    setError(err.response?.data?.message);
                }
            }
            else if (err && err instanceof Error) {
                setError('Something went wrong. Please try again later.');
            }

            console.log("Error: ", err);
        }

        setIsLoading(false);
    };

    const formik: FormikValues = useFormik({
        initialValues: {
            title: "",
            description: "",
            url: "",
            image: "",
            soundcloudLink: "",
            instagramLink: "",
            raLink: "",
        },
        validationSchema: podcastSchema,
        onSubmit,
    });
    
    return (
        <Layout>
            <LoadingWrapper loading={isLoading}>
                <div className="membersProfile">
                    <div className="membersProfile-info">
                        <Title title={'Podcast'} />
                        <form onSubmit={formik.handleSubmit}>
                            <InputContainer>
                                <label htmlFor='country'>Podcast Cover <span className="required-field"> *</span></label>
                                
                                <div className="dropPresent dropPresent--event" {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    {
                                        isDragActive ?
                                        <p>Drop the file here ...</p> :
                                        <p>Drag 'n' drop some file here, or click to select files</p>
                                    }
                                    {
                                        image &&  <img src={image} alt="preview" className="dropPresent__image dropPresent__image--event" />
                                    }
                                </div>
                                    
                                    {/* {formik.touched.country || formik.errors.country && <div className='input-error'>{formik.errors.country}</div>} */}
                            </InputContainer>

                            <Input 
                                placeholder='Type podcast name'
                                className='loginForm__input loginForm__input--noRounded'
                                label='Podcast Name'
                                name='title'
                                required
                                showRequired={true}
                                error={formik.touched.title && formik.errors.title}
                                value={formik.values.title}
                                onChange={formik.handleChange}
                            />
                            
                            <Input 
                                type='text'
                                placeholder='Paste the link here'
                                className='loginForm__input loginForm__input--noRounded'
                                label='Podcast URL'
                                name='url'
                                error={formik.touched.url && formik.errors.url}
                                value={formik.values.url}
                                onChange={formik.handleChange}
                                showRequired
                            />

                            <InputContainer>
                                <label htmlFor={"end_date"}>Podcast Description <span className="required-field"> *</span></label>
                                <div data-color-mode="dark">
                                    <MDEditor 
                                        height={350} 
                                        style={{
                                            border: '0px solid #272727',
                                            borderRadius: '6px',
                                            backgroundColor: 'transparent',
                                        }}
                                        value={formik.values.description}
                                        onChange={(value): ChangeEvent<HTMLTextAreaElement> => formik.setFieldValue('description', value)}
                                    />
                                </div>
                            </InputContainer>
                            <div className="pdcst-create__socials">
                                <Input 
                                    placeholder='Type link here'
                                    className='loginForm__input loginForm__input--noRounded'
                                    label='Soundcloud link'
                                    name='soundcloudLink'
                                    icon={<SoundcloudIcon/>}
                                    required
                                    error={formik.touched.soundcloudLink && formik.errors.soundcloudLink}
                                    value={formik.values.soundcloudLink}
                                    onChange={formik.handleChange}
                                />

                                <Input 
                                    placeholder='Type link here'
                                    className='loginForm__input loginForm__input--noRounded'
                                    label='Instagram link'
                                    name='instagramLink'
                                    icon={<InstagramIcon/>}
                                    error={formik.touched.instagramLink && formik.errors.instagramLink}
                                    value={formik.values.instagramLink}
                                    onChange={formik.handleChange}
                                />

                                <Input 
                                    placeholder='Type link here'
                                    className='loginForm__input loginForm__input--noRounded'
                                    label='Ra link'
                                    icon={<RaIcon/>}
                                    name='raLink'
                                    error={formik.touched.raLink && formik.errors.raLink}
                                    value={formik.values.raLink}
                                    onChange={formik.handleChange}
                                />
                            </div>

                            { error && <div className='error-message'>{error}</div> }

                            <StyledButton type={'submit'} variant='red' className='sm' disabled={!(formik.isValid && formik.dirty)}>add podcast</StyledButton>

                            <br />
                            <br />
                        </form>
                    </div>
                </div>
            </LoadingWrapper>
        </Layout>
    )
}

export default PodcastsCreate;
import styled from "styled-components";
import { logStatus } from "../../../../../constants/profile-statuses";

type LogStatusType = keyof typeof logStatus;


export const Header = styled.div`
    width:100%;
    font-size: 24px;
    font-family: 'Main Bold', sans-serif;
    margin-bottom: 35px;
    min-height: 39px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const HiddenOnMD = styled.span`
    @media (max-width: 768px) {
        display:none;
    }
`

export const Actions = styled.div`
    display:flex;
    align-items:center;
    gap: 16px;
`

export const ActionItem = styled.div`
    display:flex;
    align-items:center;
    gap: 8px;
    font-size: 16px;
    font-family: Main Regular;
    color: #fff;
    cursor: pointer;
    white-space: nowrap;
    @media (max-width: 768px) {
        svg{
            width:25px;
            height:25px;
        }
    }
`

export const LogView = styled.div`
    width:560px;
    margin-bottom: 15px;
    @media (max-width: 768px) {
        width:100%;
        padding-bottom: 15px;
        &:not(:last-child){
            border-bottom: 1px solid rgba(255,255,255,0.1);
        }
    }
`

export const LogItem = styled.div`
    width:100%;
    gap:20px;
    font-size:16px;
    line-height:20px; 
    font-family: Main Regular;
    color:#fff;
    display:flex;

    @media(max-width: 768px){
        flex-wrap:wrap;
        justify-content:space-between;
    }
`

export const LogIcon = styled.div<{$status: LogStatusType}>`
    width:34px;
    height:34px;
    background:url(${({$status}) => logStatus[$status].icon})no-repeat;
    background-size:30px 30px;
    flex-shrink:0;
`
export const LogInfo = styled.div`
    width:100%;
    @media(max-width: 768px){
        order:3;
    }
`

export const LogTime = styled.div`
    margin-bottom: 12px;
`

export const LogStatus = styled.div<{$status: LogStatusType}>`
    color: ${({$status}) => logStatus[$status].color ?? "#fff"};
    flex-shrink:0;
    @media(max-width: 768px){
        order:2;
    }
`

export const LogP = styled.div`
    width:100%;
    margin-bottom: 10px;
`;

export const LogBold = styled.span`
    font-family: Main Bold;
`;

export const LogActionBy = styled.div`
    font-size:16px;
    font-family: Main Regular;
    color: #fff;
    margin: 40px 0 20px 0;
`;

export const WriteButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width:100%;
    height: 44px;
    gap: 8px;
    border-radius: 10px;
    margin-bottom: 30px;
    cursor: pointer;
    font-family: Inter Bold;
    color:#A7A7A7;
    border:1px solid #A7A7A7;
    font-size:16px;
`;


export const Form = styled.form`
    width:610px;
    display:flex;
    justify-content:flex-start;
    align-items:flex-end;
    flex-direction:column;
    &>div{
        width:100%;
    }
    @media (max-width:700px){
        width:100%;
    }
`;
import styled from "styled-components";

export const Nav = styled.nav`
    width:100%;
    padding:16px 24px;
    display:grid;
    grid-template-columns: 1fr;
    gap:16px;
    @media(max-width: 1000px){
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-left:0;
        padding-right:0;
        overflow-x: auto;
        max-width:100%;
        margin-bottom: 12px;
    }
`;

export const NavItem = styled.div<{$isActive:boolean}>`
    color: ${({$isActive}) => $isActive ? '#fff' : '#7B7A7A'};
    font-size:16px;
    font-family: 'Main Regular';
    display:flex;
    align-items:center;
    gap:12px;
    height:37px;
    width:100%;
    cursor:pointer;
    
    @media(max-width: 1000px){
        background:#0C0C0C;
        background: ${({$isActive}) => $isActive ? '#0C0C0C' : 'transparent'};
        height:54px;
        border-radius:12px;
        padding: 16px;
    }
`;
import { NavLink, useLocation, useNavigate } from "react-router-dom"
import { Nav, NavItem } from "./navigation.styles"
import { navData } from "../../constants/nav-data"
import { useSignOut } from "react-auth-kit";

export const Navigation = () => {
    const { pathname } = useLocation();
    const signOut: () => void = useSignOut();
    const navigate = useNavigate();

    const logOut = (): void => {
        signOut();
        navigate('/login');
    };

    return(
        <Nav>
            {navData.map((item, index) => {
                return item?.path === 'logout' ? (
                    <NavItem $isActive={item.path === pathname} key={`sidebar#_${index}`} onClick={logOut}>
                        {item.icon()}
                        {item.title}
                    </NavItem>
                ):(
                    <NavLink to={item.path} key={`sidebar#_${index}`} style={{flexShrink: 0}}>
                        <NavItem $isActive={item.path === pathname}>
                            {item.icon()}
                            {item.title}
                        </NavItem>
                    </NavLink>
                )
            })}
        </Nav>
    )
}
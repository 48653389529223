import styled from "styled-components";

export const Form = styled.form`
    width:610px;
    display:flex;
    justify-content:flex-start;
    align-items:flex-end;
    flex-direction:column;
    &>div{
        width:100%;
    }
    @media (max-width:700px){
        width:100%;
    }
`;
import './Content.scss';

import Title from "../../../Components/Title/Title.component";
import Layout from "../../../Views/Admin/Layout"
import { useEffect, useState } from 'react';
import axios, {AxiosResponse} from 'axios';
import { useDebounce } from 'use-debounce';
import ContentBlockComponent from '../../../Components/ContentBlock/ContentBlock.component';
import { IContent } from '../../../utils/interfaces/content.interface';
import { usePermissionGuard } from '../../../hooks/usePermissionsGuard';
import { PermissionsEnum } from '../../../utils/interfaces/permissions.enum';

const ContentPage: React.FC = () => {
    const [contents, setContents] = useState<IContent[]>([]);
    const [searchText, setSearchText] = useState<string | null>(null);
    const [value] = useDebounce(searchText, 300);
    const canSeeContent = usePermissionGuard(PermissionsEnum.CONTENT_PAGE_VIEW)

    const getContent = async () => {
        let url: string = `content`;

        value && (url += `?search=${value}`)
        
        await axios.get(url)
            .then((res: AxiosResponse) => {
                const { data } = res;

                setContents(data)
            })
    }

    const searching = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setSearchText(e.target.value)
    };

    useEffect(() => {
        if(canSeeContent) getContent();
        // eslint-disable-next-line
    }, [value])

    return (
        <Layout>
            {
                canSeeContent && <>
                    <Title title={'Text management'} className="content__title">
                        <input type='text' onChange={searching} value={searchText ? searchText : ''} placeholder='Search text...' className="navigation__search" />
                    </Title>

                    {
                        contents.map((content: IContent) => (
                            <ContentBlockComponent
                                key={content.key}
                                id={content.id}
                                value={content.value}
                                key_code={content.key}
                            />
                        ))
                    }
                </>
            }
        </Layout>
    )
}

export default ContentPage;
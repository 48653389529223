import styled from "styled-components";

export const Uploader = styled.div`
    width:100%;
    display:flex;
    gap:24px;
    margin-bottom: 24px;
    
`;

export const Avatar = styled.img`
    width:85px;
    height:85px;
    border-radius:50px;
    border:2px solid #575757;
    object-fit:cover;
    flex-shrink:0;
`;

export const Wrapper = styled.div`
    width:100%;
    display:flex;
    flex-direction:column;
    justify-content:space-between;
    font-family: Inter;
    color:#848484;
    font-size:12px;
    padding:4px 0;
`;

export const UploadButton = styled.div`
    width: 155px;
    height: 47px;
    border-radius: 12px;
    border:1px solid #313131;
    font-family: Main Regular;
    color:#909090;
    font-size:14px;
    white-space:nowrap;
    display:flex;
    justify-content:center;
    align-items:center;
    gap:14px;
    cursor: pointer;
`;
import { FC, InputHTMLAttributes, useRef } from "react"
import { Container, Label, InputField, InviteContainer, InviteInputField, InviteLabel, ErrorMsg } from "./input.styles"

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    label: string;
    error?: any;
    isLight?: boolean;
}

export const Input:FC<InputProps> = ({label, isLight, error, ...rest}) => {
    const inputRef = useRef<HTMLInputElement>(null);

    const focusInput = () => inputRef.current?.focus();

    return(
        <Container onClick={focusInput} $isLight={isLight}>
            <Label>{label}</Label>
            <InputField 
                ref={inputRef}
                {...rest}
            />
            {error && <ErrorMsg className='input-error'>{error}</ErrorMsg>}
        </Container>
    )
}

export const InviteInput:FC<InputProps> = ({label, isLight, error, ...rest}) => {
    const inputRef = useRef<HTMLInputElement>(null);

    const focusInput = () => inputRef.current?.focus();

    return(
        <InviteContainer onClick={focusInput} $isLight={isLight}>
            <InviteLabel>{label}</InviteLabel>
            <InviteInputField 
                ref={inputRef}
                {...rest}
            />
            {error && <ErrorMsg className='input-error'>{error}</ErrorMsg>}
        </InviteContainer>
    )
}
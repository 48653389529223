import { FC, ReactNode } from "react"
import { Split, SplitContent, SplitSide } from "./SplitProfile.styles";

export type SplitProfileProps = {
    sidebar: ReactNode;
    content: ReactNode;
}

export const SplitProfile:FC<SplitProfileProps> = ({
    sidebar,
    content,
}) => {
    return(
        <Split>
            <SplitSide>
                {sidebar}
            </SplitSide>
            <SplitContent>
                {content}
            </SplitContent>
        </Split>
    )
}
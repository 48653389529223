import { FC, useState } from "react";
import './AlbumCard.scss';
import { Link } from "react-router-dom";
import { StyledButton } from "../Button/Button.styles";
import ConfirmPopup from "../ConfirmPopup/ConfirmPopup";
import { PermissionGuard } from "../PermissionGuard/PermissionGuard.component";
import { PermissionsEnum } from "../../utils/interfaces/permissions.enum";

interface IAlbumCard{
    title: string;
    url: string;
    image: string;
    id: string;
    artistName:string;
    onDelete: (id: string) => void
    onClick: () => void
}
export const AlbumCard:FC<IAlbumCard> = ({
    id,
    title,
    artistName,
    url,
    image,
    onDelete,
    onClick
}) => {
    const [isPopupOpen, setPopupOpen] = useState<boolean>(false)
    
    const onPopupAction = (popupAction: boolean) => {
        setPopupOpen(false)
        
        if(popupAction) {
            onDelete(id)
        }
    }
    
    return (
        <div className="album-card">
             <div className="album-card__image">
                <img src={`https://basementny.mtebi.com/public/podcasts/${image}`} alt={title}/>
            </div>
            <div className="album-card__info">
                <div className="album-card__title">{title}</div>
                <div className="album-card__artist">{artistName}</div>
                <div className="album-card__link">
                    <Link to={url} target="_blank" rel="noreferrer">
                        Link
                    </Link>
                </div>
                <div className="album-card__delete">
                    <PermissionGuard role={PermissionsEnum.CAN_UPDATE_PODCAST}>
                        <>
                            <StyledButton variant="edit" type="button" onClick={onClick}>
                                Edit
                            </StyledButton>
                            <StyledButton variant="delete" type="button" onClick={() => setPopupOpen(true)}>
                                Delete
                            </StyledButton>
                        </>
                    </PermissionGuard>
                </div>
            </div>
            
            <ConfirmPopup
                label={'Are you sure you want to delete the podcast album?'}
                text={'Podcast album will be deleted permanently.'}
                onAction={onPopupAction}
                isopen={isPopupOpen}
            />
        </div>
    )
}
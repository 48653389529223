const SocialIconsComponent: React.FC<{socialLinks: string[] | null, reviewLinks?: string[]}> = ({socialLinks,reviewLinks}) => {
    const unify = [...(socialLinks??[]), ...(reviewLinks??[])];
    const openPopup = (e: React.MouseEvent<HTMLDivElement>, url: string) => {
        e.preventDefault()
        e.stopPropagation()
        
        window.open(url, 'googleWindow', 'width=960; height=680; left=200;');
    };
    
    return (
        <>
            {
                socialLinks &&
                unify.map((social: string, index: number) => {
                    const isActive = reviewLinks?.length;
                    const isActiveClass = isActive ? 'userInfo-social--active' : ''; 
                    let socialIcon: string = `default`;

                    (social.includes('facebook') || social.includes('fb')) && (socialIcon = 'facebook');
                    
                    (social.includes('instagram') || social.includes('insta')) && (socialIcon = 'instagram');

                    return (
                        <div
                            onClick={(e: React.MouseEvent<HTMLDivElement>) => openPopup(e, social)}
                            rel="noreferrer"
                            aria-label={social}
                            key={index}
                            className={`userInfo-social userInfo-social--${socialIcon} ${isActiveClass}` }
                        >
                            <span className="sr-only">{social}</span>
                        </div>
                    );
                })
            }
            
            {!socialLinks && <div>-</div>}
        </>
    )
}

export default SocialIconsComponent;

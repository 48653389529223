import { Action, Content, Desc, AnimatedIcon, Info, Title, Warning } from "./user-warning.styles"
import { ReactComponent as WarningIcon } from "../../assets/images/profile-nav/warning-icon.svg";
import { NavLink, useLocation } from "react-router-dom";
import { useContext } from "react";
import { UserContext } from "../../context/UserContext";
import { Show } from "../Show/Show";

export enum ProfileLink {
    PROFILE = "/user/profile",
} 
export const UserWarning = () => {
    const person = useContext(UserContext);
    const {pathname} = useLocation();
    
    if(person?.infoChange?.status !== "cancelled") return null;
    return(
        <Warning>
            <AnimatedIcon>
                <WarningIcon />
            </AnimatedIcon>
            <Content>
                <Info>
                    <Title>Complete your profile details to receive the invitation.</Title>
                    <Desc>{person?.infoChange?.admin_comment}</Desc>
                </Info>
                <Action>
                    <Show condition={pathname !== ProfileLink.PROFILE}>
                        <NavLink to="/user/profile">
                            Edit info
                        </NavLink>
                    </Show>
                </Action>
            </Content>
        </Warning>
    )
}
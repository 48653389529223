import { useNavigate, useParams } from "react-router-dom";
import axios, { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { ImageCard } from "../../../Components/ImageCard/ImageCard";
import './PodcastsDetail.scss';
import { IPodcast } from "../../../utils/interfaces/podcasts.interface";
import { ReactComponent as PlayIcon } from "../../../assets/images/play-icon.svg";

const PodcastsDetailPage = () => {
    const { podcast_id } = useParams();
    const navigate = useNavigate();
    const [ podcast, setPodcast ] = useState<IPodcast | null>(null);
    const [ lastPodcast, setLastPodcast ] = useState<IPodcast[]>([]);
    
    useEffect(()=>{
        setPodcast(null);
        getLastResident();
        getResident();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[podcast_id]);
    
    const getResident = () => {
        axios.get(`/podcasts/public/${podcast_id}`).then((res:AxiosResponse) => {
            setPodcast(res.data);
        })
    }

    const getLastResident = () => {
        axios.get(`/podcasts/public/?limit=3&page=1&skip=${podcast_id}`).then((res:AxiosResponse) => {
            setLastPodcast(res.data?.data);
        })
    }

    return <>
        {
            !podcast ? '' : 
            <>
                <div className="podcasts-detail">
                    <div className="podcasts-detail__cover">
                        <div className="podcasts-detail__image">
                            <ImageCard 
                                url={`${process.env.REACT_APP_HOST}/public/podcasts/${podcast.image}`}
                                alt={podcast.title}                        
                            />
                        </div>
                        <div className="podcasts-detail__tags">
                            {
                                podcast.soundcloudLink && 
                                    <a 
                                        href={podcast.soundcloudLink} 
                                        target="_blank"
                                        rel="noreferrer"
                                        className="podcasts-detail__tag podcasts-detail__social podcasts-detail__social--soundcloud"
                                    >&nbsp;</a>
                            }
                            {
                                podcast.instagramLink && 
                                    <a 
                                        href={podcast.instagramLink} 
                                        target="_blank"
                                        rel="noreferrer"
                                        className="podcasts-detail__tag podcasts-detail__social podcasts-detail__social--instagram"
                                    >&nbsp;
                                    </a>
                            }
                            {
                                podcast.raLink && 
                                    <a 
                                        href={podcast.raLink} 
                                        target="_blank"
                                        rel="noreferrer"
                                        className="podcasts-detail__tag podcasts-detail__social podcasts-detail__social--ra"
                                    >&nbsp;
                                    </a>
                            }
                        </div>
                    </div>
                    <div className="podcasts-detail__info">
                        <p>
                            <h1 className="podcasts-detail__name podcasts-detail__title">{podcast.title}</h1>
                            <p className="podcasts-detail__bio"  dangerouslySetInnerHTML={{__html: podcast.description.replaceAll('\r\n','</br>')}}/>
                        </p>
                        <iframe
                            key="framer"
                            title="SoundCloud Player"
                            width="100%" 
                            height="165" 
                            allow="autoplay" 
                            sandbox="allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
                            src={podcast.url}>
                        </iframe>
                    </div>
                </div>
                <div className="podcasts-detail__list">
                    {
                        podcast?.albums.length > 0 ? <h3 className="podcasts-detail__list-title">We asked the artist to select albums which have influenced them the most</h3> : null
                    }
                    
                    <div className="podcasts-detail__list-body">
                        {
                            podcast?.albums.map((album:IPodcast) => {
                                return(
                                    <div className="podcasts-detail__artist" key={album.id}>
                                        <a className="podcasts-detail__artist-cover" href={album.url} target="_blank" rel="noreferrer">
                                            <div className="podcasts-detail__artist-hover">
                                                <PlayIcon/>
                                            </div>
                                            <ImageCard 
                                                url={`${process.env.REACT_APP_HOST}/public/podcasts/${album.image}`}
                                                alt={album.title}                        
                                            />
                                        </a>
                                        <div className="podcasts-detail__artist-info">
                                            <div>
                                                <div className="podcasts-detail__artist-title">{album.title}</div>
                                                <div className="podcasts-detail__artist-name">{album.artistName}</div>
                                                <p className="podcasts-detail__artist-desc" dangerouslySetInnerHTML={{__html: album.description.replaceAll('\r\n','</br>')}}/>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                {
                    lastPodcast?.length >  0 ? (
                        <div className="podcasts-detail__others">
                            <div className="podcasts-detail__others-title">
                                {'OTHER PODCASTS'}
                            </div>
                            <div className="podcasts-detail__others-content">
                                {lastPodcast?.map((podcast:IPodcast) => 
                                    <div className="podcasts-detail__others-card" key={podcast.id}>
                                        <div className="podcasts-detail__others-cover">
                                            <ImageCard
                                                onClick={()=>navigate(`/podcasts/${podcast.id}`)}
                                                url={`${process.env.REACT_APP_HOST}/public/podcasts/${podcast.image}`}
                                                alt={podcast.title}
                                            />
                                        </div>
                                        <div className="podcasts-detail__others-name">{podcast.title}</div>
                                    </div>
                                )}
                            </div>
                        </div>
                    ) : null
                }
            </>
        }   
    </>
}

export default PodcastsDetailPage;
import { AuthProvider } from "react-auth-kit";
import { RouterProvider } from 'react-router-dom';
import { router } from "./router";
import { LangsProvider } from "./context/LangsContext";
import { UserProvider } from "./context/UserContext";

const App: React.FC = () => {
  
  return (
    <AuthProvider authType="localstorage" authName="_auth">
      <LangsProvider>
        <UserProvider>
          <RouterProvider router={router} />
        </UserProvider>
      </LangsProvider>
    </AuthProvider>
  );
};

export default App;
